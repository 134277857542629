import type { ReactNode } from "react";
import { Col, Form } from "react-bootstrap";

type FormGroupProps = {
  children?: ReactNode;
  label?: string;
  isError?: boolean;
};

const FormGroupWrapper = ({ children, label, isError }: FormGroupProps) => {
  if (isError) {
    return (
      <Form.Control.Feedback type="invalid">{children}</Form.Control.Feedback>
    );
  }
  return (
    <Col>
      <Form.Group>
        <Form.Label>{label}</Form.Label>
        {children}
      </Form.Group>
    </Col>
  );
};

export default FormGroupWrapper;
