import type { CustomColumnExtended } from "@/components/Datatable/Types";
import { useTableCase } from "@/hooks/UseTableCase";
import { useGetClientListData } from "@/hooks/useGetClientsData";
import type { ClientList, ClientsList200Response } from "@/services/swagger";
import { RoutePath } from "@/shared/constants/RoutesNavigator";
import {
  tableArrayFormatterNewLine,
  tableDateFormatter,
} from "@/shared/utils/TableUtils";
import type { AxiosResponse } from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import type { Row, TableInstance } from "react-table";

interface Return {
  data: {
    columns: CustomColumnExtended[];
    data: AxiosResponse<ClientsList200Response>;
    tableInstance: TableInstance<any>;
    isLoading: boolean;
    totalData: number;
  };
  method: {
    handleClientFormPage(): void;
    handleClickAbleClient(row): void;
    setTableState: React.Dispatch<
      React.SetStateAction<{
        pageIndex: number;
        pageSize: number;
      }>
    >;
  };
}

export default function useViewModel(): Return {
  const history = useHistory();

  const [tableState, setTableState] = useState({
    pageIndex: 0,
    pageSize: 25,
  });

  const columns: CustomColumnExtended[] = useMemo(
    () => [
      {
        accessor: "name",
        Header: "Name",
      },
      {
        id: "web_urls",
        Header: "URLs",
        disableSortBy: true,
        accessor: (row: ClientList) => tableArrayFormatterNewLine(row.web_urls),
      },
      {
        id: "wedding_date",
        Header: "Wedding Date",
        disableSortBy: false,
        accessor: (row: ClientList) => tableDateFormatter(row.wedding_date),
      },
      {
        id: "created_at",
        Header: "Created At",
        disableSortBy: false,
        accessor: (row: ClientList) => tableDateFormatter(row.created_at),
      },
      {
        id: "default_password",
        Header: "Password",
        disableSortBy: true,
        accessor: (row: ClientList) => row.default_password,
      },
    ],
    []
  );

  const { data, isLoading } = useGetClientListData(
    tableState.pageSize,
    tableState.pageIndex,
    {
      staleTime: Infinity,
      keepPreviousData: true,
    }
  );

  const clientsData = useMemo(() => {
    return data ? data.data.data : [];
  }, [data]);

  const tableInstance = useTableCase({
    columns: columns,
    data: clientsData,
    initialState: {
      pageSize: tableState?.pageSize,
      pageIndex: 0,
    },
  });

  const {
    state: { pageIndex, pageSize },
  } = tableInstance;

  useEffect(() => {
    setTableState({ pageIndex, pageSize });
  }, [pageIndex, pageSize]);

  const handleClickAbleClient = useCallback((row: Row<ClientList>) => {
    history.push(RoutePath.CLIENT_DETAIL(row.original.id.toString()));
  }, []);

  const handleClientFormPage = useCallback(() => {
    history.push(RoutePath.CLIENT_ADD);
  }, []);

  return {
    data: {
      columns,
      data,
      isLoading,
      tableInstance,
      totalData: data?.data.total,
    },
    method: {
      handleClickAbleClient,
      handleClientFormPage,
      setTableState,
    },
  };
}
