import { showAlert } from "@components/AdminLayout/ClientForms/components/customFields/AlertComponents";
import OptionForm from "@/components/AdminLayout/ClientForms/components/OptionForm/optionForm";
import PageContent from "@components/AdminLayout/PageContent";
import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import { FormProvider } from "react-hook-form";
import { CustomHeaders } from "../ClientWebDetail/styles";
import { isEmpty } from "lodash";
import useViewModel from "./ViewModel";

const ClientCreateWebDetail = () => {
  const {
    data: { error, forms, isDisabled },
    method: { onSubmit },
  } = useViewModel();

  return (
    <PageContent>
      <Row>
        <Col className="col-12">
          <Card>
            <Card.Header>
              <CustomHeaders>Client Detail Option Form</CustomHeaders>
            </Card.Header>
            <Card.Body>
              {!isEmpty(error) && showAlert(error)}
              <FormProvider {...forms}>
                <Form onSubmit={forms.handleSubmit(onSubmit)}>
                  <OptionForm />
                  <Button
                    variant="primary"
                    type="submit"
                    className="mb-3"
                    disabled={isDisabled}
                  >
                    <span className="pr-2">Submit</span>
                    {isDisabled && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                  </Button>
                </Form>
              </FormProvider>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </PageContent>
  );
};

export default ClientCreateWebDetail;
