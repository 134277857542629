import type { ReactNode } from "react";
import { Fragment } from "react";

interface Props {
  children: ReactNode;
  location: Record<string, unknown>;
}
const NonAuthLayout = (props: Props) => {
  return <Fragment>{props.children}</Fragment>;
};
export default NonAuthLayout;
