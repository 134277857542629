import { ApiInstance } from "@/services/api";
import type { Bank } from "@/services/swagger";
import { REACT_QUERY_CACHE_KEYS } from "@/shared/constants/CacheKeys";
import type { AxiosError, AxiosResponse } from "axios";
import type { UseQueryOptions } from "react-query";
import { useQuery } from "react-query";

const fetchApi = () => ApiInstance().banksApi().banksList();

export const useGetBanksData = (
  options?: UseQueryOptions<
    AxiosResponse<Array<Bank>>,
    AxiosError,
    AxiosResponse<Array<Bank>>,
    never
  >
) => {
  return useQuery([REACT_QUERY_CACHE_KEYS.getWish], async () => fetchApi(), {
    retry: (failureCount, error) => {
      return (
        error.response?.status !== 401 &&
        error.response?.status !== 403 &&
        failureCount < 3
      );
    },
    ...options,
  });
};
