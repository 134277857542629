import "@shared/styles/datatables.scss";
import FileSaver from "file-saver";
import XLSX from "xlsx";
import type { ExportButtonProps } from "../Types";
import { isArray, isUndefined } from "@/utils/common";

const ExportButton = (props: ExportButtonProps) => {
  const {
    columns,
    fileName,
    tableInstance,
    fileType = "xlsx",
    onExport,
  } = props;
  const { rows } = tableInstance;

  const exportClientSide = () => {
    //   set the data
    const exportColumns = [...columns];

    const visibleColumns = exportColumns.filter(
      (col) => col.csvExport !== false
    );
    const exportData = rows.map((row) =>
      visibleColumns.map((col, colIndex) => {
        const data = row.cells[colIndex]?.value;
        return isArray(data) ? data.join(", ") : data;
      })
    );
    const header = visibleColumns.map((col) => col.csvTitle || col.Header);

    // create the workbook
    const ws = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(ws, [header]);
    XLSX.utils.sheet_add_json(ws, exportData, {
      origin: "A2",
      skipHeader: true,
    });

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, {
      bookType: fileType,
      Props: { CreatedDate: new Date() },
      type: "array",
      cellStyles: true,
    });
    const finalData = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });

    FileSaver.saveAs(finalData, `${fileName}.${fileType}`);
  };

  return (
    <div>
      <button
        className="btn btn-koendang btn-primary-koendang d-flex"
        onClick={() =>
          !isUndefined(onExport) ? onExport() : exportClientSide()
        }
      >
        <span className="button-icon">
          <i className="bx bxs-download"></i>
        </span>
        Export to&nbsp;
        <span style={{ textTransform: "uppercase" }}>
          {fileType === "xlsx" ? "Excel" : fileType}
        </span>
      </button>
    </div>
  );
};

export default ExportButton;
