import ClientForms from "@components/AdminLayout/ClientForms";

import PageContent from "@components/AdminLayout/PageContent";
import { Card } from "react-bootstrap";
import useViewModel from "./ViewModel";

const ClientFormPage = () => {
  const {
    data: { client, error, isDisabled },
    method: { onSubmit },
  } = useViewModel();

  const renderContent = () => {
    return (
      <>
        <Card>
          <ClientForms
            data={client}
            onSubmit={onSubmit}
            isDisabled={isDisabled}
            error={error}
          />
        </Card>
      </>
    );
  };

  return <PageContent>{renderContent()}</PageContent>;
};

export default ClientFormPage;
