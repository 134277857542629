import type { CSSProperties } from "react";
import styled from "styled-components";

type ImageWrapperProps = {
  additionalCss?: CSSProperties;
};

export const Asterisk = styled.span`
  color: red;
`;

export const ImageWrapper = styled.div<ImageWrapperProps>`
  img {
    height: 240px;
    max-width: 100%;
    object-fit: cover;

    ${(props: ImageWrapperProps) =>
      props.additionalCss ? { ...props.additionalCss } : ""}
  }
`;

export const PaymentImageWrapper = styled.div<ImageWrapperProps>`
  width: 50%;
  img {
    max-width: 100%;
    object-fit: cover;

    ${(props: ImageWrapperProps) =>
      props.additionalCss ? { ...props.additionalCss } : ""}
  }
`;
