import { setAuthUser, signIn } from "@/services/AuthUtils";
import { RoutePath } from "@/shared/constants/RoutesNavigator";
import type { Login as LoginForm } from "@services/swagger";
import { useState } from "react";
import type { UseFormHandleSubmit, UseFormRegister } from "react-hook-form";
import { useForm } from "react-hook-form";
import type { RouteComponentProps } from "react-router-dom";

type Props = {
  history: RouteComponentProps["history"];
};

interface Return {
  data: {
    register: UseFormRegister<LoginForm>;
    handleSubmit: UseFormHandleSubmit<LoginForm>;
    isLoading: boolean;
    error: string;
  };
  method: {
    logInClicked(data: LoginForm): void;
  };
}

export default function useViewModel(props: Props): Return {
  const { register, handleSubmit } = useForm<LoginForm>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const logInClicked = async (data: LoginForm) => {
    setIsLoading(true);
    try {
      await signIn(data);
      await setAuthUser();

      setIsLoading(false);

      props.history.push(RoutePath.HOME);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setError(error.response.data.message);
    }
  };

  return {
    data: {
      error,
      handleSubmit,
      isLoading,
      register,
    },
    method: {
      logInClicked,
    },
  };
}
