import ClientForms from "@/components/AdminLayout/ClientForms";
import LoadingIndicator from "@/shared/components/LoadingIndicator";
import { Card } from "react-bootstrap";
import type { Props } from "./Types";

export const renderContent = ({ ...props }: Props) => {
  if (!props.isLoading && props.webContent === null) {
    return <div>Web content not found</div>;
  }
  return (
    <>
      <Card>
        {props.isLoading ? (
          <div className="loading-indicator-container">
            <LoadingIndicator />
          </div>
        ) : (
          <ClientForms
            data={props.webContent}
            onSubmit={props.onSubmit}
            isDisabled={props.isDisabled}
            error={props.error}
          />
        )}
      </Card>
    </>
  );
};
