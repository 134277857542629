import PageContent from "@components/AdminLayout/PageContent";
import {
  OrderCircle,
  DownloadLink,
  LinksWrapper,
  DownloadSection,
  UploadSection,
  TextContent,
  FileUploadContainer,
  UploadContent,
  DownloadContent,
} from "./styles";
import { getLinksTemplate } from "@services/api/getLinksTemplateService";
import { getLinksResult } from "@services/api/getLinksResultService";
import { downloadLinksFile } from "@shared/utils/linksUtils";
import { Button } from "react-bootstrap";
import useViewModel from "./ViewModel";
import { renderUploadStatus } from "./RenderUploadStatus";

const Links = () => {
  const {
    data: { newLinkMutation },
    method: { handleSubmitFile, handleUpload: _handleUpload },
  } = useViewModel();

  return (
    <PageContent>
      <LinksWrapper>
        <DownloadSection>
          <OrderCircle>1</OrderCircle>
          <TextContent>
            Please{" "}
            <DownloadLink onClick={() => downloadLinksFile(getLinksTemplate())}>
              click here
            </DownloadLink>{" "}
            To download the guest link excel template.{" "}
            <b>Fill the file with your guests data </b>by following the format
            written in the excel.
          </TextContent>
        </DownloadSection>
        <UploadSection>
          <OrderCircle>2</OrderCircle>
          <UploadContent>
            <TextContent>
              Then, upload the file by clicking the upload button. Format file
              needs to be <b>.xlsx </b>with <b>max size of 100KB</b>.
            </TextContent>
            <FileUploadContainer
              type="file"
              accept=".xlsx"
              onChange={handleSubmitFile}
              //Set event value to null before uploading a new file, because re-upload file with same name wont trigger onChange.
              onClick={(e) => ((e.target as HTMLInputElement).value = null)}
              disabled={newLinkMutation.isLoading}
            />
            {renderUploadStatus(newLinkMutation)}
          </UploadContent>
        </UploadSection>
        <DownloadSection>
          <OrderCircle>3</OrderCircle>
          <DownloadContent>
            <div>
              You can download the result after the{" "}
              <b>download button is clickable.</b>
            </div>
            <Button
              disabled={!newLinkMutation.isSuccess}
              onClick={() => downloadLinksFile(getLinksResult())}
            >
              Download Generated File
            </Button>
          </DownloadContent>
        </DownloadSection>
      </LinksWrapper>
    </PageContent>
  );
};

export default Links;
