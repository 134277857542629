/* tslint:disable */
/* eslint-disable */
/**
 * Koendang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: adityaaputraaa98@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from "./configuration";
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "./base";

/**
 *
 * @export
 * @interface Aggregate
 */
export interface Aggregate {
  /**
   *
   * @type {number}
   * @memberof Aggregate
   */
  total_guest?: number;
  /**
   *
   * @type {AggregateCountAttendance}
   * @memberof Aggregate
   */
  count_attendance?: AggregateCountAttendance;
}
/**
 *
 * @export
 * @interface AggregateCountAttendance
 */
export interface AggregateCountAttendance {
  /**
   *
   * @type {number}
   * @memberof AggregateCountAttendance
   */
  yes?: number;
  /**
   *
   * @type {number}
   * @memberof AggregateCountAttendance
   */
  no?: number;
}
/**
 *
 * @export
 * @interface Bank
 */
export interface Bank {
  /**
   *
   * @type {number}
   * @memberof Bank
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Bank
   */
  bank_name?: string;
  /**
   *
   * @type {string}
   * @memberof Bank
   */
  bank_logo_url?: string;
}
/**
 *
 * @export
 * @interface Booking
 */
export interface Booking {
  /**
   *
   * @type {number}
   * @memberof Booking
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Booking
   */
  name?: string;
  /**
   *
   * @type {boolean}
   * @memberof Booking
   */
  is_attending?: boolean;
  /**
   *
   * @type {number}
   * @memberof Booking
   */
  guest_total?: number;
  /**
   *
   * @type {string}
   * @memberof Booking
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof Booking
   */
  additional_fields?: string;
}
/**
 *
 * @export
 * @interface BookingCreateUpdate
 */
export interface BookingCreateUpdate {
  /**
   *
   * @type {string}
   * @memberof BookingCreateUpdate
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof BookingCreateUpdate
   */
  message?: string;
  /**
   *
   * @type {boolean}
   * @memberof BookingCreateUpdate
   */
  is_attending?: boolean;
  /**
   *
   * @type {number}
   * @memberof BookingCreateUpdate
   */
  guest_total?: number;
  /**
   *
   * @type {string}
   * @memberof BookingCreateUpdate
   */
  additional_fields?: string;
}
/**
 *
 * @export
 * @interface BooksList200Response
 */
export interface BooksList200Response {
  /**
   *
   * @type {number}
   * @memberof BooksList200Response
   */
  total: number;
  /**
   *
   * @type {string}
   * @memberof BooksList200Response
   */
  last_page?: string;
  /**
   *
   * @type {string}
   * @memberof BooksList200Response
   */
  current_page?: string;
  /**
   *
   * @type {string}
   * @memberof BooksList200Response
   */
  next_page?: string;
  /**
   *
   * @type {string}
   * @memberof BooksList200Response
   */
  previous_page?: string;
  /**
   *
   * @type {string}
   * @memberof BooksList200Response
   */
  next?: string;
  /**
   *
   * @type {string}
   * @memberof BooksList200Response
   */
  previous?: string;
  /**
   *
   * @type {Array<Booking>}
   * @memberof BooksList200Response
   */
  data: Array<Booking>;
}
/**
 *
 * @export
 * @interface BooksListV2200Response
 */
export interface BooksListV2200Response {
  /**
   *
   * @type {Metadata}
   * @memberof BooksListV2200Response
   */
  metadata: Metadata;
  /**
   *
   * @type {Array<Booking>}
   * @memberof BooksListV2200Response
   */
  data: Array<Booking>;
  /**
   *
   * @type {Array<string>}
   * @memberof BooksListV2200Response
   */
  header: Array<string>;
  /**
   *
   * @type {Aggregate}
   * @memberof BooksListV2200Response
   */
  aggregate?: Aggregate;
}
/**
 *
 * @export
 * @interface Bride
 */
export interface Bride {
  /**
   *
   * @type {string}
   * @memberof Bride
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Bride
   */
  full_name?: string;
  /**
   *
   * @type {string}
   * @memberof Bride
   */
  prefix_name?: string;
  /**
   *
   * @type {string}
   * @memberof Bride
   */
  suffix_name?: string;
  /**
   *
   * @type {string}
   * @memberof Bride
   */
  photo_url?: string;
  /**
   *
   * @type {string}
   * @memberof Bride
   */
  child_order?: string;
  /**
   *
   * @type {string}
   * @memberof Bride
   */
  daughter_order?: string;
  /**
   *
   * @type {string}
   * @memberof Bride
   */
  father_name?: string;
  /**
   *
   * @type {string}
   * @memberof Bride
   */
  mother_name?: string;
  /**
   *
   * @type {string}
   * @memberof Bride
   */
  facebook?: string;
  /**
   *
   * @type {string}
   * @memberof Bride
   */
  instagram?: string;
  /**
   *
   * @type {string}
   * @memberof Bride
   */
  twitter?: string;
  /**
   *
   * @type {string}
   * @memberof Bride
   */
  tiktok?: string;
  /**
   *
   * @type {string}
   * @memberof Bride
   */
  image_css?: string;
}
/**
 *
 * @export
 * @interface Client
 */
export interface Client {
  /**
   *
   * @type {number}
   * @memberof Client
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Client
   */
  name?: string;
  /**
   *
   * @type {Content}
   * @memberof Client
   */
  content?: Content;
  /**
   *
   * @type {Option}
   * @memberof Client
   */
  option?: Option;
}
/**
 *
 * @export
 * @interface ClientDashboard
 */
export interface ClientDashboard {
  /**
   *
   * @type {number}
   * @memberof ClientDashboard
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ClientDashboard
   */
  name?: string;
  /**
   *
   * @type {ContentDashboard}
   * @memberof ClientDashboard
   */
  content?: ContentDashboard;
  /**
   *
   * @type {Option}
   * @memberof ClientDashboard
   */
  option?: Option;
}
/**
 *
 * @export
 * @interface ClientDetail
 */
export interface ClientDetail {
  /**
   *
   * @type {number}
   * @memberof ClientDetail
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ClientDetail
   */
  name?: string;
  /**
   *
   * @type {Array<OptionList>}
   * @memberof ClientDetail
   */
  options?: Array<OptionList>;
  /**
   *
   * @type {string}
   * @memberof ClientDetail
   */
  wedding_date?: string;
  /**
   *
   * @type {string}
   * @memberof ClientDetail
   */
  created_at?: string;
}
/**
 *
 * @export
 * @interface ClientList
 */
export interface ClientList {
  /**
   *
   * @type {number}
   * @memberof ClientList
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ClientList
   */
  name?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ClientList
   */
  web_urls?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ClientList
   */
  wedding_date?: string;
  /**
   *
   * @type {string}
   * @memberof ClientList
   */
  default_password?: string;
  /**
   *
   * @type {string}
   * @memberof ClientList
   */
  created_at?: string;
}
/**
 *
 * @export
 * @interface ClientOption
 */
export interface ClientOption {
  /**
   *
   * @type {Option}
   * @memberof ClientOption
   */
  option?: Option;
}
/**
 *
 * @export
 * @interface ClientsList200Response
 */
export interface ClientsList200Response {
  /**
   *
   * @type {number}
   * @memberof ClientsList200Response
   */
  total: number;
  /**
   *
   * @type {string}
   * @memberof ClientsList200Response
   */
  last_page?: string;
  /**
   *
   * @type {string}
   * @memberof ClientsList200Response
   */
  current_page?: string;
  /**
   *
   * @type {string}
   * @memberof ClientsList200Response
   */
  next_page?: string;
  /**
   *
   * @type {string}
   * @memberof ClientsList200Response
   */
  previous_page?: string;
  /**
   *
   * @type {string}
   * @memberof ClientsList200Response
   */
  next?: string;
  /**
   *
   * @type {string}
   * @memberof ClientsList200Response
   */
  previous?: string;
  /**
   *
   * @type {Array<ClientList>}
   * @memberof ClientsList200Response
   */
  data: Array<ClientList>;
}
/**
 *
 * @export
 * @interface Content
 */
export interface Content {
  /**
   *
   * @type {number}
   * @memberof Content
   */
  id?: number;
  /**
   *
   * @type {Array<Event>}
   * @memberof Content
   */
  events?: Array<Event>;
  /**
   *
   * @type {Array<Payment>}
   * @memberof Content
   */
  payments?: Array<Payment>;
  /**
   *
   * @type {Array<Story>}
   * @memberof Content
   */
  stories?: Array<Story>;
  /**
   *
   * @type {Array<HeroBackground>}
   * @memberof Content
   */
  hero_backgrounds?: Array<HeroBackground>;
  /**
   *
   * @type {Array<Gallery>}
   * @memberof Content
   */
  galleries?: Array<Gallery>;
  /**
   *
   * @type {Bride}
   * @memberof Content
   */
  bride?: Bride;
  /**
   *
   * @type {Groom}
   * @memberof Content
   */
  groom?: Groom;
  /**
   *
   * @type {string}
   * @memberof Content
   */
  wedding_date?: string;
  /**
   *
   * @type {string}
   * @memberof Content
   */
  greeting?: string;
  /**
   *
   * @type {string}
   * @memberof Content
   */
  quote?: string;
  /**
   *
   * @type {string}
   * @memberof Content
   */
  quote_source?: string;
  /**
   *
   * @type {string}
   * @memberof Content
   */
  hashtag_word?: string;
  /**
   *
   * @type {string}
   * @memberof Content
   */
  reminder_url?: string;
  /**
   *
   * @type {string}
   * @memberof Content
   */
  video_prewed_url?: string;
  /**
   *
   * @type {string}
   * @memberof Content
   */
  opening_modal_image?: string;
  /**
   *
   * @type {string}
   * @memberof Content
   */
  opening_modal_image_css?: string;
  /**
   *
   * @type {string}
   * @memberof Content
   */
  opening_modal_image_type?: ContentOpeningModalImageTypeEnum;
  /**
   *
   * @type {string}
   * @memberof Content
   */
  galleries_css?: string;
  /**
   *
   * @type {string}
   * @memberof Content
   */
  galleries_mobile_css?: string;
}

export const ContentOpeningModalImageTypeEnum = {
  Photo: "PHOTO",
  Logo: "LOGO",
  Landscape: "LANDSCAPE",
  Portrait: "PORTRAIT",
} as const;

export type ContentOpeningModalImageTypeEnum =
  (typeof ContentOpeningModalImageTypeEnum)[keyof typeof ContentOpeningModalImageTypeEnum];

/**
 *
 * @export
 * @interface ContentDashboard
 */
export interface ContentDashboard {
  /**
   *
   * @type {number}
   * @memberof ContentDashboard
   */
  id?: number;
  /**
   *
   * @type {Array<Event>}
   * @memberof ContentDashboard
   */
  events?: Array<Event>;
  /**
   *
   * @type {Array<PaymentDashboard>}
   * @memberof ContentDashboard
   */
  payments?: Array<PaymentDashboard>;
  /**
   *
   * @type {Array<Story>}
   * @memberof ContentDashboard
   */
  stories?: Array<Story>;
  /**
   *
   * @type {Array<HeroBackground>}
   * @memberof ContentDashboard
   */
  hero_backgrounds?: Array<HeroBackground>;
  /**
   *
   * @type {Array<Gallery>}
   * @memberof ContentDashboard
   */
  galleries?: Array<Gallery>;
  /**
   *
   * @type {Bride}
   * @memberof ContentDashboard
   */
  bride?: Bride;
  /**
   *
   * @type {Groom}
   * @memberof ContentDashboard
   */
  groom?: Groom;
  /**
   *
   * @type {string}
   * @memberof ContentDashboard
   */
  wedding_date?: string;
  /**
   *
   * @type {string}
   * @memberof ContentDashboard
   */
  greeting?: string;
  /**
   *
   * @type {string}
   * @memberof ContentDashboard
   */
  quote?: string;
  /**
   *
   * @type {string}
   * @memberof ContentDashboard
   */
  quote_source?: string;
  /**
   *
   * @type {string}
   * @memberof ContentDashboard
   */
  hashtag_word?: string;
  /**
   *
   * @type {string}
   * @memberof ContentDashboard
   */
  reminder_url?: string;
  /**
   *
   * @type {string}
   * @memberof ContentDashboard
   */
  video_prewed_url?: string;
  /**
   *
   * @type {string}
   * @memberof ContentDashboard
   */
  opening_modal_image?: string;
  /**
   *
   * @type {string}
   * @memberof ContentDashboard
   */
  opening_modal_image_css?: string;
  /**
   *
   * @type {string}
   * @memberof ContentDashboard
   */
  opening_modal_image_type?: ContentDashboardOpeningModalImageTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ContentDashboard
   */
  galleries_css?: string;
  /**
   *
   * @type {string}
   * @memberof ContentDashboard
   */
  galleries_mobile_css?: string;
}

export const ContentDashboardOpeningModalImageTypeEnum = {
  Photo: "PHOTO",
  Logo: "LOGO",
  Landscape: "LANDSCAPE",
  Portrait: "PORTRAIT",
} as const;

export type ContentDashboardOpeningModalImageTypeEnum =
  (typeof ContentDashboardOpeningModalImageTypeEnum)[keyof typeof ContentDashboardOpeningModalImageTypeEnum];

/**
 *
 * @export
 * @interface Event
 */
export interface Event {
  /**
   *
   * @type {number}
   * @memberof Event
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Event
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof Event
   */
  start_datetime?: string;
  /**
   *
   * @type {string}
   * @memberof Event
   */
  end_datetime?: string;
  /**
   *
   * @type {string}
   * @memberof Event
   */
  location?: string;
  /**
   *
   * @type {string}
   * @memberof Event
   */
  street?: string;
  /**
   *
   * @type {string}
   * @memberof Event
   */
  watch_url?: string;
  /**
   *
   * @type {string}
   * @memberof Event
   */
  maps_url?: string;
  /**
   *
   * @type {string}
   * @memberof Event
   */
  type?: EventTypeEnum;
  /**
   *
   * @type {string}
   * @memberof Event
   */
  session_type?: string;
  /**
   *
   * @type {string}
   * @memberof Event
   */
  custom_time_text?: string;
  /**
   *
   * @type {string}
   * @memberof Event
   */
  icon_url?: string;
  /**
   *
   * @type {string}
   * @memberof Event
   */
  timezone?: string;
}

export const EventTypeEnum = {
  HolyMatrimony: "HOLY_MATRIMONY",
  WeddingReception: "WEDDING_RECEPTION",
  Sangjit: "SANGJIT",
  Teapai: "TEAPAI",
  Birthday: "BIRTHDAY",
} as const;

export type EventTypeEnum = (typeof EventTypeEnum)[keyof typeof EventTypeEnum];

/**
 *
 * @export
 * @interface Font
 */
export interface Font {
  /**
   *
   * @type {number}
   * @memberof Font
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Font
   */
  family?: string;
  /**
   *
   * @type {string}
   * @memberof Font
   */
  css_url?: string;
  /**
   *
   * @type {string}
   * @memberof Font
   */
  font_url?: string;
  /**
   *
   * @type {string}
   * @memberof Font
   */
  style?: string;
  /**
   *
   * @type {string}
   * @memberof Font
   */
  weight?: string;
  /**
   *
   * @type {string}
   * @memberof Font
   */
  format?: string;
  /**
   *
   * @type {string}
   * @memberof Font
   */
  unicode_range?: string;
  /**
   *
   * @type {string}
   * @memberof Font
   */
  type?: FontTypeEnum;
}

export const FontTypeEnum = {
  Header: "HEADER",
  Title: "TITLE",
  Quote: "QUOTE",
  General: "GENERAL",
} as const;

export type FontTypeEnum = (typeof FontTypeEnum)[keyof typeof FontTypeEnum];

/**
 *
 * @export
 * @interface Gallery
 */
export interface Gallery {
  /**
   *
   * @type {number}
   * @memberof Gallery
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Gallery
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof Gallery
   */
  picture_url?: string;
  /**
   *
   * @type {string}
   * @memberof Gallery
   */
  css?: string;
  /**
   *
   * @type {string}
   * @memberof Gallery
   */
  section?: GallerySectionEnum;
  /**
   *
   * @type {string}
   * @memberof Gallery
   */
  overlay_opacity?: string;
}

export const GallerySectionEnum = {
  _1: "SECTION_1",
  _2: "SECTION_2",
  ShareBanner: "SECTION_SHARE_BANNER",
  RsvpBanner: "SECTION_RSVP_BANNER",
  BridegroomBanner: "SECTION_BRIDEGROOM_BANNER",
  FooterBanner: "SECTION_FOOTER_BANNER",
  Carousel: "SECTION_CAROUSEL",
} as const;

export type GallerySectionEnum =
  (typeof GallerySectionEnum)[keyof typeof GallerySectionEnum];

/**
 *
 * @export
 * @interface Groom
 */
export interface Groom {
  /**
   *
   * @type {string}
   * @memberof Groom
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Groom
   */
  full_name?: string;
  /**
   *
   * @type {string}
   * @memberof Groom
   */
  prefix_name?: string;
  /**
   *
   * @type {string}
   * @memberof Groom
   */
  suffix_name?: string;
  /**
   *
   * @type {string}
   * @memberof Groom
   */
  photo_url?: string;
  /**
   *
   * @type {string}
   * @memberof Groom
   */
  child_order?: string;
  /**
   *
   * @type {string}
   * @memberof Groom
   */
  son_order?: string;
  /**
   *
   * @type {string}
   * @memberof Groom
   */
  father_name?: string;
  /**
   *
   * @type {string}
   * @memberof Groom
   */
  mother_name?: string;
  /**
   *
   * @type {string}
   * @memberof Groom
   */
  facebook?: string;
  /**
   *
   * @type {string}
   * @memberof Groom
   */
  instagram?: string;
  /**
   *
   * @type {string}
   * @memberof Groom
   */
  twitter?: string;
  /**
   *
   * @type {string}
   * @memberof Groom
   */
  tiktok?: string;
  /**
   *
   * @type {string}
   * @memberof Groom
   */
  image_css?: string;
}
/**
 *
 * @export
 * @interface GuestLogin
 */
export interface GuestLogin {
  /**
   *
   * @type {string}
   * @memberof GuestLogin
   */
  slug: string;
  /**
   *
   * @type {string}
   * @memberof GuestLogin
   */
  password: string;
}
/**
 *
 * @export
 * @interface HeroBackground
 */
export interface HeroBackground {
  /**
   *
   * @type {number}
   * @memberof HeroBackground
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof HeroBackground
   */
  order?: number;
  /**
   *
   * @type {string}
   * @memberof HeroBackground
   */
  picture_url?: string;
  /**
   *
   * @type {string}
   * @memberof HeroBackground
   */
  css?: string;
  /**
   *
   * @type {string}
   * @memberof HeroBackground
   */
  overlay_opacity?: string;
}
/**
 *
 * @export
 * @interface Inquiry
 */
export interface Inquiry {
  /**
   *
   * @type {number}
   * @memberof Inquiry
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Inquiry
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Inquiry
   */
  message: string;
}
/**
 *
 * @export
 * @interface LinksDownload
 */
export interface LinksDownload {
  /**
   *
   * @type {string}
   * @memberof LinksDownload
   */
  file_download?: string;
}
/**
 *
 * @export
 * @interface Lock
 */
export interface Lock {
  /**
   *
   * @type {string}
   * @memberof Lock
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof Lock
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof Lock
   */
  image_url?: string;
  /**
   *
   * @type {string}
   * @memberof Lock
   */
  custom_css?: string;
  /**
   *
   * @type {string}
   * @memberof Lock
   */
  template?: LockTemplateEnum;
  /**
   *
   * @type {string}
   * @memberof Lock
   */
  bride_name?: string;
  /**
   *
   * @type {string}
   * @memberof Lock
   */
  groom_name?: string;
  /**
   *
   * @type {string}
   * @memberof Lock
   */
  primary_color?: string;
  /**
   *
   * @type {string}
   * @memberof Lock
   */
  base_color?: string;
}

export const LockTemplateEnum = {
  Template1: "TEMPLATE_1",
  Template2: "TEMPLATE_2",
  Custom: "CUSTOM",
} as const;

export type LockTemplateEnum =
  (typeof LockTemplateEnum)[keyof typeof LockTemplateEnum];

/**
 *
 * @export
 * @interface Login
 */
export interface Login {
  /**
   *
   * @type {string}
   * @memberof Login
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof Login
   */
  password: string;
}
/**
 *
 * @export
 * @interface Meta
 */
export interface Meta {
  /**
   *
   * @type {string}
   * @memberof Meta
   */
  bride_name?: string;
  /**
   *
   * @type {string}
   * @memberof Meta
   */
  groom_name?: string;
  /**
   *
   * @type {string}
   * @memberof Meta
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof Meta
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof Meta
   */
  image?: string;
  /**
   *
   * @type {string}
   * @memberof Meta
   */
  theme_color?: string;
  /**
   *
   * @type {boolean}
   * @memberof Meta
   */
  is_locked?: boolean;
}
/**
 *
 * @export
 * @interface Metadata
 */
export interface Metadata {
  /**
   *
   * @type {number}
   * @memberof Metadata
   */
  total?: number;
  /**
   *
   * @type {number}
   * @memberof Metadata
   */
  last_page?: number;
  /**
   *
   * @type {number}
   * @memberof Metadata
   */
  current_page?: number;
  /**
   *
   * @type {number}
   * @memberof Metadata
   */
  previous_page?: number;
  /**
   *
   * @type {number}
   * @memberof Metadata
   */
  next_page?: number;
  /**
   *
   * @type {number}
   * @memberof Metadata
   */
  previous?: number;
  /**
   *
   * @type {number}
   * @memberof Metadata
   */
  next?: number;
}
/**
 *
 * @export
 * @interface Option
 */
export interface Option {
  /**
   *
   * @type {string}
   * @memberof Option
   */
  slug?: string;
  /**
   *
   * @type {number}
   * @memberof Option
   */
  client_id?: number;
  /**
   *
   * @type {string}
   * @memberof Option
   */
  template: OptionTemplateEnum;
  /**
   *
   * @type {boolean}
   * @memberof Option
   */
  is_custom_template?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Option
   */
  is_custom_domain?: boolean;
  /**
   *
   * @type {string}
   * @memberof Option
   */
  language?: OptionLanguageEnum;
  /**
   *
   * @type {string}
   * @memberof Option
   */
  font_url?: string;
  /**
   *
   * @type {string}
   * @memberof Option
   */
  song_url?: string;
  /**
   *
   * @type {string}
   * @memberof Option
   */
  opening_modal_style?: OptionOpeningModalStyleEnum;
  /**
   *
   * @type {string}
   * @memberof Option
   */
  hero_style?: OptionHeroStyleEnum;
  /**
   *
   * @type {string}
   * @memberof Option
   */
  countdown_style?: OptionCountdownStyleEnum;
  /**
   *
   * @type {string}
   * @memberof Option
   */
  quote_style?: OptionQuoteStyleEnum;
  /**
   *
   * @type {string}
   * @memberof Option
   */
  bride_groom_style?: OptionBrideGroomStyleEnum;
  /**
   *
   * @type {string}
   * @memberof Option
   */
  wedding_event_style?: OptionWeddingEventStyleEnum;
  /**
   *
   * @type {string}
   * @memberof Option
   */
  our_story_style?: OptionOurStoryStyleEnum;
  /**
   *
   * @type {string}
   * @memberof Option
   */
  gallery_style?: OptionGalleryStyleEnum;
  /**
   *
   * @type {string}
   * @memberof Option
   */
  wishes_style?: OptionWishesStyleEnum;
  /**
   *
   * @type {string}
   * @memberof Option
   */
  gift_style?: OptionGiftStyleEnum;
  /**
   *
   * @type {string}
   * @memberof Option
   */
  rsvp_style?: OptionRsvpStyleEnum;
  /**
   *
   * @type {string}
   * @memberof Option
   */
  share_banner_style?: OptionShareBannerStyleEnum;
  /**
   *
   * @type {string}
   * @memberof Option
   */
  health_protocol_style?: OptionHealthProtocolStyleEnum;
  /**
   *
   * @type {string}
   * @memberof Option
   */
  footer_style?: OptionFooterStyleEnum;
  /**
   *
   * @type {boolean}
   * @memberof Option
   */
  slider?: boolean;
  /**
   *
   * @type {string}
   * @memberof Option
   */
  primary_color?: string;
  /**
   *
   * @type {string}
   * @memberof Option
   */
  secondary_color?: string;
  /**
   *
   * @type {string}
   * @memberof Option
   */
  third_color?: string;
  /**
   *
   * @type {string}
   * @memberof Option
   */
  title_color?: string;
  /**
   *
   * @type {string}
   * @memberof Option
   */
  base_color?: string;
  /**
   *
   * @type {string}
   * @memberof Option
   */
  web_type: OptionWebTypeEnum;
  /**
   *
   * @type {Array<Font>}
   * @memberof Option
   */
  fonts?: Array<Font>;
  /**
   * return data as HTML string
   * @type {string}
   * @memberof Option
   */
  announcement?: string;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof Option
   */
  custom_text?: { [key: string]: string };
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof Option
   */
  custom_css?: { [key: string]: string };
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof Option
   */
  rsvp_config?: { [key: string]: string };
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof Option
   */
  custom_config?: { [key: string]: string };
  /**
   *
   * @type {number}
   * @memberof Option
   */
  default_guest_total?: number;
  /**
   *
   * @type {boolean}
   * @memberof Option
   */
  animation?: boolean;
  /**
   *
   * @type {string}
   * @memberof Option
   */
  custom_image_url?: string;
  /**
   *
   * @type {string}
   * @memberof Option
   */
  meta_title?: string;
  /**
   *
   * @type {string}
   * @memberof Option
   */
  meta_description?: string;
  /**
   *
   * @type {string}
   * @memberof Option
   */
  meta_image?: string;
}

export const OptionTemplateEnum = {
  Template1: "TEMPLATE_1",
  Template2: "TEMPLATE_2",
  Template21: "TEMPLATE_2_1",
  Template22: "TEMPLATE_2_2",
  Template3: "TEMPLATE_3",
  Template4: "TEMPLATE_4",
  Template5: "TEMPLATE_5",
  Template6: "TEMPLATE_6",
  Template7: "TEMPLATE_7",
  Custom: "CUSTOM",
} as const;

export type OptionTemplateEnum =
  (typeof OptionTemplateEnum)[keyof typeof OptionTemplateEnum];
export const OptionLanguageEnum = {
  En: "en",
  Id: "id",
} as const;

export type OptionLanguageEnum =
  (typeof OptionLanguageEnum)[keyof typeof OptionLanguageEnum];
export const OptionOpeningModalStyleEnum = {
  Hidden: "HIDDEN",
  QueryReverse: "QUERY_REVERSE",
  Style2: "STYLE2",
} as const;

export type OptionOpeningModalStyleEnum =
  (typeof OptionOpeningModalStyleEnum)[keyof typeof OptionOpeningModalStyleEnum];
export const OptionHeroStyleEnum = {
  Hidden: "HIDDEN",
  WithVideo: "WITH_VIDEO",
  NameNewLines: "NAME_NEW_LINES",
} as const;

export type OptionHeroStyleEnum =
  (typeof OptionHeroStyleEnum)[keyof typeof OptionHeroStyleEnum];
export const OptionCountdownStyleEnum = {
  Hidden: "HIDDEN",
} as const;

export type OptionCountdownStyleEnum =
  (typeof OptionCountdownStyleEnum)[keyof typeof OptionCountdownStyleEnum];
export const OptionQuoteStyleEnum = {
  Hidden: "HIDDEN",
  NoFlower: "NO_FLOWER",
} as const;

export type OptionQuoteStyleEnum =
  (typeof OptionQuoteStyleEnum)[keyof typeof OptionQuoteStyleEnum];
export const OptionBrideGroomStyleEnum = {
  Hidden: "HIDDEN",
  Style2: "STYLE2",
  Style3: "STYLE3",
  Style4: "STYLE4",
  OnePhoto: "ONE_PHOTO",
} as const;

export type OptionBrideGroomStyleEnum =
  (typeof OptionBrideGroomStyleEnum)[keyof typeof OptionBrideGroomStyleEnum];
export const OptionWeddingEventStyleEnum = {
  Hidden: "HIDDEN",
} as const;

export type OptionWeddingEventStyleEnum =
  (typeof OptionWeddingEventStyleEnum)[keyof typeof OptionWeddingEventStyleEnum];
export const OptionOurStoryStyleEnum = {
  Paragraph: "PARAGRAPH",
  Hidden: "HIDDEN",
} as const;

export type OptionOurStoryStyleEnum =
  (typeof OptionOurStoryStyleEnum)[keyof typeof OptionOurStoryStyleEnum];
export const OptionGalleryStyleEnum = {
  Hidden: "HIDDEN",
} as const;

export type OptionGalleryStyleEnum =
  (typeof OptionGalleryStyleEnum)[keyof typeof OptionGalleryStyleEnum];
export const OptionWishesStyleEnum = {
  Hidden: "HIDDEN",
} as const;

export type OptionWishesStyleEnum =
  (typeof OptionWishesStyleEnum)[keyof typeof OptionWishesStyleEnum];
export const OptionGiftStyleEnum = {
  Hidden: "HIDDEN",
  NoModal: "NO_MODAL",
  NoIcon: "NO_ICON",
} as const;

export type OptionGiftStyleEnum =
  (typeof OptionGiftStyleEnum)[keyof typeof OptionGiftStyleEnum];
export const OptionRsvpStyleEnum = {
  WishOnly: "WISH_ONLY",
  Hidden: "HIDDEN",
} as const;

export type OptionRsvpStyleEnum =
  (typeof OptionRsvpStyleEnum)[keyof typeof OptionRsvpStyleEnum];
export const OptionShareBannerStyleEnum = {
  Hidden: "HIDDEN",
} as const;

export type OptionShareBannerStyleEnum =
  (typeof OptionShareBannerStyleEnum)[keyof typeof OptionShareBannerStyleEnum];
export const OptionHealthProtocolStyleEnum = {
  Hidden: "HIDDEN",
  Show: "SHOW",
  Modal1: "MODAL1",
} as const;

export type OptionHealthProtocolStyleEnum =
  (typeof OptionHealthProtocolStyleEnum)[keyof typeof OptionHealthProtocolStyleEnum];
export const OptionFooterStyleEnum = {
  Hidden: "HIDDEN",
  Style1: "STYLE1",
} as const;

export type OptionFooterStyleEnum =
  (typeof OptionFooterStyleEnum)[keyof typeof OptionFooterStyleEnum];
export const OptionWebTypeEnum = {
  Primary: "PRIMARY",
  Secondary: "SECONDARY",
  Birthday: "BIRTHDAY",
} as const;

export type OptionWebTypeEnum =
  (typeof OptionWebTypeEnum)[keyof typeof OptionWebTypeEnum];

/**
 *
 * @export
 * @interface OptionList
 */
export interface OptionList {
  /**
   *
   * @type {number}
   * @memberof OptionList
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof OptionList
   */
  web_url?: string;
}
/**
 *
 * @export
 * @interface Package
 */
export interface Package {
  /**
   *
   * @type {number}
   * @memberof Package
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Package
   */
  name?: string;
  /**
   *
   * @type {boolean}
   * @memberof Package
   */
  custom_font?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Package
   */
  custom_song?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Package
   */
  countdown?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Package
   */
  quote?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Package
   */
  bride_groom?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Package
   */
  wedding_event?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Package
   */
  our_story?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Package
   */
  gallery?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Package
   */
  wishes?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Package
   */
  gift?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Package
   */
  rsvp?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Package
   */
  cms?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Package
   */
  exclusive_guest?: boolean;
}
/**
 *
 * @export
 * @interface Payment
 */
export interface Payment {
  /**
   *
   * @type {number}
   * @memberof Payment
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Payment
   */
  bank?: string;
  /**
   *
   * @type {Bank}
   * @memberof Payment
   */
  default_bank?: Bank;
  /**
   *
   * @type {string}
   * @memberof Payment
   */
  account_number?: string;
  /**
   *
   * @type {string}
   * @memberof Payment
   */
  account_owner?: string;
  /**
   *
   * @type {string}
   * @memberof Payment
   */
  qr_code_image_url?: string;
  /**
   *
   * @type {string}
   * @memberof Payment
   */
  payment_url?: string;
  /**
   *
   * @type {string}
   * @memberof Payment
   */
  custom_button_text?: string;
}
/**
 *
 * @export
 * @interface PaymentConfirmation
 */
export interface PaymentConfirmation {
  /**
   *
   * @type {number}
   * @memberof PaymentConfirmation
   */
  id?: number;
  /**
   *
   * @type {Payment}
   * @memberof PaymentConfirmation
   */
  payment?: Payment;
  /**
   *
   * @type {Client}
   * @memberof PaymentConfirmation
   */
  client?: Client;
  /**
   *
   * @type {object}
   * @memberof PaymentConfirmation
   */
  name?: object;
  /**
   *
   * @type {string}
   * @memberof PaymentConfirmation
   */
  account_number?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentConfirmation
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentConfirmation
   */
  image_confirmation_url?: string;
}
/**
 *
 * @export
 * @interface PaymentDashboard
 */
export interface PaymentDashboard {
  /**
   *
   * @type {number}
   * @memberof PaymentDashboard
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof PaymentDashboard
   */
  bank_id?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentDashboard
   */
  bank?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentDashboard
   */
  account_number?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentDashboard
   */
  account_owner?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentDashboard
   */
  qr_code_image_url?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentDashboard
   */
  payment_url?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentDashboard
   */
  custom_button_text?: string;
}
/**
 *
 * @export
 * @interface ResponseDetail
 */
export interface ResponseDetail {
  /**
   *
   * @type {string}
   * @memberof ResponseDetail
   */
  message?: string;
}
/**
 *
 * @export
 * @interface SlugsRequest
 */
export interface SlugsRequest {
  /**
   *
   * @type {string}
   * @memberof SlugsRequest
   */
  filter?: SlugsRequestFilterEnum;
}

export const SlugsRequestFilterEnum = {
  Lock: "LOCK",
} as const;

export type SlugsRequestFilterEnum =
  (typeof SlugsRequestFilterEnum)[keyof typeof SlugsRequestFilterEnum];

/**
 *
 * @export
 * @interface Story
 */
export interface Story {
  /**
   *
   * @type {number}
   * @memberof Story
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof Story
   */
  order?: number;
  /**
   *
   * @type {string}
   * @memberof Story
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof Story
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof Story
   */
  datetime?: string;
  /**
   *
   * @type {string}
   * @memberof Story
   */
  location?: string;
  /**
   *
   * @type {string}
   * @memberof Story
   */
  image_url?: string;
}
/**
 *
 * @export
 * @interface Token
 */
export interface Token {
  /**
   *
   * @type {string}
   * @memberof Token
   */
  key: string;
}
/**
 *
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {number}
   * @memberof User
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof User
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  role?: UserRoleEnum;
  /**
   *
   * @type {string}
   * @memberof User
   */
  avatar?: string;
}

export const UserRoleEnum = {
  Client: "CLIENT",
  Admin: "ADMIN",
  SuperAdmin: "SUPER_ADMIN",
} as const;

export type UserRoleEnum = (typeof UserRoleEnum)[keyof typeof UserRoleEnum];

/**
 *
 * @export
 * @interface Wish
 */
export interface Wish {
  /**
   *
   * @type {number}
   * @memberof Wish
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Wish
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Wish
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof Wish
   */
  created_at?: string;
}
/**
 *
 * @export
 * @interface WishListClient200Response
 */
export interface WishListClient200Response {
  /**
   *
   * @type {number}
   * @memberof WishListClient200Response
   */
  total: number;
  /**
   *
   * @type {string}
   * @memberof WishListClient200Response
   */
  last_page?: string;
  /**
   *
   * @type {string}
   * @memberof WishListClient200Response
   */
  current_page?: string;
  /**
   *
   * @type {string}
   * @memberof WishListClient200Response
   */
  next_page?: string;
  /**
   *
   * @type {string}
   * @memberof WishListClient200Response
   */
  previous_page?: string;
  /**
   *
   * @type {string}
   * @memberof WishListClient200Response
   */
  next?: string;
  /**
   *
   * @type {string}
   * @memberof WishListClient200Response
   */
  previous?: string;
  /**
   *
   * @type {Array<Wish>}
   * @memberof WishListClient200Response
   */
  data: Array<Wish>;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Login
     * @param {GuestLogin} guestLogin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authGuestLogin: async (
      guestLogin: GuestLogin,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'guestLogin' is not null or undefined
      assertParamExists("authGuestLogin", "guestLogin", guestLogin);
      const localVarPath = `/guest/auth/login`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        guestLogin,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Login
     * @param {Login} login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authLogin: async (
      login: Login,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'login' is not null or undefined
      assertParamExists("authLogin", "login", login);
      const localVarPath = `/admin/auth/login`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        login,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authLogout: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/auth/logout`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get auth user data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authUserShow: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/auth/user`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Login
     * @param {GuestLogin} guestLogin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authGuestLogin(
      guestLogin: GuestLogin,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Token>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authGuestLogin(
        guestLogin,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Login
     * @param {Login} login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authLogin(
      login: Login,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Token>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authLogin(
        login,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authLogout(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDetail>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authLogout(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get auth user data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authUserShow(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authUserShow(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AuthApiFp(configuration);
  return {
    /**
     *
     * @summary Login
     * @param {GuestLogin} guestLogin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authGuestLogin(guestLogin: GuestLogin, options?: any): AxiosPromise<Token> {
      return localVarFp
        .authGuestLogin(guestLogin, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Login
     * @param {Login} login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authLogin(login: Login, options?: any): AxiosPromise<Token> {
      return localVarFp
        .authLogin(login, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authLogout(options?: any): AxiosPromise<ResponseDetail> {
      return localVarFp
        .authLogout(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get auth user data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authUserShow(options?: any): AxiosPromise<User> {
      return localVarFp
        .authUserShow(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for authGuestLogin operation in AuthApi.
 * @export
 * @interface AuthApiAuthGuestLoginRequest
 */
export interface AuthApiAuthGuestLoginRequest {
  /**
   *
   * @type {GuestLogin}
   * @memberof AuthApiAuthGuestLogin
   */
  readonly guestLogin: GuestLogin;
}

/**
 * Request parameters for authLogin operation in AuthApi.
 * @export
 * @interface AuthApiAuthLoginRequest
 */
export interface AuthApiAuthLoginRequest {
  /**
   *
   * @type {Login}
   * @memberof AuthApiAuthLogin
   */
  readonly login: Login;
}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
  /**
   *
   * @summary Login
   * @param {AuthApiAuthGuestLoginRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authGuestLogin(
    requestParameters: AuthApiAuthGuestLoginRequest,
    options?: AxiosRequestConfig
  ) {
    return AuthApiFp(this.configuration)
      .authGuestLogin(requestParameters.guestLogin, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Login
   * @param {AuthApiAuthLoginRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authLogin(
    requestParameters: AuthApiAuthLoginRequest,
    options?: AxiosRequestConfig
  ) {
    return AuthApiFp(this.configuration)
      .authLogin(requestParameters.login, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Logout
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authLogout(options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .authLogout(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get auth user data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authUserShow(options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .authUserShow(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * BanksApi - axios parameter creator
 * @export
 */
export const BanksApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * Display Bank by Id.
     * @summary Display the specified bank resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    banksDetail: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("banksDetail", "id", id);
      const localVarPath = `/admin/banks/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Display Bank List.
     * @summary Display banks.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    banksList: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/banks`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BanksApi - functional programming interface
 * @export
 */
export const BanksApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = BanksApiAxiosParamCreator(configuration);
  return {
    /**
     * Display Bank by Id.
     * @summary Display the specified bank resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async banksDetail(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Bank>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.banksDetail(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Display Bank List.
     * @summary Display banks.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async banksList(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Bank>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.banksList(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * BanksApi - factory interface
 * @export
 */
export const BanksApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = BanksApiFp(configuration);
  return {
    /**
     * Display Bank by Id.
     * @summary Display the specified bank resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    banksDetail(id: string, options?: any): AxiosPromise<Bank> {
      return localVarFp
        .banksDetail(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Display Bank List.
     * @summary Display banks.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    banksList(options?: any): AxiosPromise<Array<Bank>> {
      return localVarFp
        .banksList(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for banksDetail operation in BanksApi.
 * @export
 * @interface BanksApiBanksDetailRequest
 */
export interface BanksApiBanksDetailRequest {
  /**
   *
   * @type {string}
   * @memberof BanksApiBanksDetail
   */
  readonly id: string;
}

/**
 * BanksApi - object-oriented interface
 * @export
 * @class BanksApi
 * @extends {BaseAPI}
 */
export class BanksApi extends BaseAPI {
  /**
   * Display Bank by Id.
   * @summary Display the specified bank resource.
   * @param {BanksApiBanksDetailRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BanksApi
   */
  public banksDetail(
    requestParameters: BanksApiBanksDetailRequest,
    options?: AxiosRequestConfig
  ) {
    return BanksApiFp(this.configuration)
      .banksDetail(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Display Bank List.
   * @summary Display banks.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BanksApi
   */
  public banksList(options?: AxiosRequestConfig) {
    return BanksApiFp(this.configuration)
      .banksList(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * BooksApi - axios parameter creator
 * @export
 */
export const BooksApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * Display a listing of the additional booking table fields.
     * @summary Display a listing of the additional booking table fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bookingAdditionalFields: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/books/additional-fields`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Store a newly created resource in storage.
     * @summary Store a newly booking created resource in storage.
     * @param {BookingCreateUpdate} bookingCreateUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    booksCreate: async (
      bookingCreateUpdate: BookingCreateUpdate,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'bookingCreateUpdate' is not null or undefined
      assertParamExists(
        "booksCreate",
        "bookingCreateUpdate",
        bookingCreateUpdate
      );
      const localVarPath = `/admin/books`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bookingCreateUpdate,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Remove the specified resource from storage.
     * @summary Remove the specified booking from storage.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    booksDelete: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("booksDelete", "id", id);
      const localVarPath = `/admin/books/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Display a listing of the resource.
     * @summary Display a listing of the resource.
     * @param {number} [limit]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    booksList: async (
      limit?: number,
      page?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/books`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Display a listing of the resource.
     * @summary Display a listing of the book resource.
     * @param {number} [limit]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    booksListV2: async (
      limit?: number,
      page?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/v2/books`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Display the specified resource.
     * @summary Display the specified booking resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    booksRead: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("booksRead", "id", id);
      const localVarPath = `/admin/books/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update the specified resource in storage.
     * @summary Update the specified booking resource in storage.
     * @param {string} id
     * @param {BookingCreateUpdate} bookingCreateUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    booksUpdate: async (
      id: string,
      bookingCreateUpdate: BookingCreateUpdate,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("booksUpdate", "id", id);
      // verify required parameter 'bookingCreateUpdate' is not null or undefined
      assertParamExists(
        "booksUpdate",
        "bookingCreateUpdate",
        bookingCreateUpdate
      );
      const localVarPath = `/admin/books/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bookingCreateUpdate,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Display a listing of the resource.
     * @summary Export books of client.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportBooks: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/books/exports`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Store a newly created resource in storage.
     * @summary Store a newly booking created resource in storage.
     * @param {string} clientId
     * @param {BookingCreateUpdate} bookingCreateUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rsvpCreateClient: async (
      clientId: string,
      bookingCreateUpdate: BookingCreateUpdate,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'clientId' is not null or undefined
      assertParamExists("rsvpCreateClient", "clientId", clientId);
      // verify required parameter 'bookingCreateUpdate' is not null or undefined
      assertParamExists(
        "rsvpCreateClient",
        "bookingCreateUpdate",
        bookingCreateUpdate
      );
      const localVarPath = `/books/rsvp/{client_id}`.replace(
        `{${"client_id"}}`,
        encodeURIComponent(String(clientId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bookingCreateUpdate,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BooksApi - functional programming interface
 * @export
 */
export const BooksApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = BooksApiAxiosParamCreator(configuration);
  return {
    /**
     * Display a listing of the additional booking table fields.
     * @summary Display a listing of the additional booking table fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bookingAdditionalFields(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<{ [key: string]: string }>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.bookingAdditionalFields(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Store a newly created resource in storage.
     * @summary Store a newly booking created resource in storage.
     * @param {BookingCreateUpdate} bookingCreateUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async booksCreate(
      bookingCreateUpdate: BookingCreateUpdate,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Booking>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.booksCreate(
        bookingCreateUpdate,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Remove the specified resource from storage.
     * @summary Remove the specified booking from storage.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async booksDelete(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDetail>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.booksDelete(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Display a listing of the resource.
     * @summary Display a listing of the resource.
     * @param {number} [limit]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async booksList(
      limit?: number,
      page?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<BooksList200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.booksList(
        limit,
        page,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Display a listing of the resource.
     * @summary Display a listing of the book resource.
     * @param {number} [limit]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async booksListV2(
      limit?: number,
      page?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<BooksListV2200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.booksListV2(
        limit,
        page,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Display the specified resource.
     * @summary Display the specified booking resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async booksRead(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Booking>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.booksRead(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Update the specified resource in storage.
     * @summary Update the specified booking resource in storage.
     * @param {string} id
     * @param {BookingCreateUpdate} bookingCreateUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async booksUpdate(
      id: string,
      bookingCreateUpdate: BookingCreateUpdate,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Booking>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.booksUpdate(
        id,
        bookingCreateUpdate,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Display a listing of the resource.
     * @summary Export books of client.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportBooks(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.exportBooks(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Store a newly created resource in storage.
     * @summary Store a newly booking created resource in storage.
     * @param {string} clientId
     * @param {BookingCreateUpdate} bookingCreateUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async rsvpCreateClient(
      clientId: string,
      bookingCreateUpdate: BookingCreateUpdate,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Wish>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.rsvpCreateClient(
          clientId,
          bookingCreateUpdate,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * BooksApi - factory interface
 * @export
 */
export const BooksApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = BooksApiFp(configuration);
  return {
    /**
     * Display a listing of the additional booking table fields.
     * @summary Display a listing of the additional booking table fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bookingAdditionalFields(
      options?: any
    ): AxiosPromise<Array<{ [key: string]: string }>> {
      return localVarFp
        .bookingAdditionalFields(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Store a newly created resource in storage.
     * @summary Store a newly booking created resource in storage.
     * @param {BookingCreateUpdate} bookingCreateUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    booksCreate(
      bookingCreateUpdate: BookingCreateUpdate,
      options?: any
    ): AxiosPromise<Booking> {
      return localVarFp
        .booksCreate(bookingCreateUpdate, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Remove the specified resource from storage.
     * @summary Remove the specified booking from storage.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    booksDelete(id: string, options?: any): AxiosPromise<ResponseDetail> {
      return localVarFp
        .booksDelete(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Display a listing of the resource.
     * @summary Display a listing of the resource.
     * @param {number} [limit]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    booksList(
      limit?: number,
      page?: number,
      options?: any
    ): AxiosPromise<BooksList200Response> {
      return localVarFp
        .booksList(limit, page, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Display a listing of the resource.
     * @summary Display a listing of the book resource.
     * @param {number} [limit]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    booksListV2(
      limit?: number,
      page?: number,
      options?: any
    ): AxiosPromise<BooksListV2200Response> {
      return localVarFp
        .booksListV2(limit, page, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Display the specified resource.
     * @summary Display the specified booking resource.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    booksRead(id: string, options?: any): AxiosPromise<Booking> {
      return localVarFp
        .booksRead(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Update the specified resource in storage.
     * @summary Update the specified booking resource in storage.
     * @param {string} id
     * @param {BookingCreateUpdate} bookingCreateUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    booksUpdate(
      id: string,
      bookingCreateUpdate: BookingCreateUpdate,
      options?: any
    ): AxiosPromise<Booking> {
      return localVarFp
        .booksUpdate(id, bookingCreateUpdate, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Display a listing of the resource.
     * @summary Export books of client.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportBooks(options?: any): AxiosPromise<void> {
      return localVarFp
        .exportBooks(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Store a newly created resource in storage.
     * @summary Store a newly booking created resource in storage.
     * @param {string} clientId
     * @param {BookingCreateUpdate} bookingCreateUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rsvpCreateClient(
      clientId: string,
      bookingCreateUpdate: BookingCreateUpdate,
      options?: any
    ): AxiosPromise<Wish> {
      return localVarFp
        .rsvpCreateClient(clientId, bookingCreateUpdate, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for booksCreate operation in BooksApi.
 * @export
 * @interface BooksApiBooksCreateRequest
 */
export interface BooksApiBooksCreateRequest {
  /**
   *
   * @type {BookingCreateUpdate}
   * @memberof BooksApiBooksCreate
   */
  readonly bookingCreateUpdate: BookingCreateUpdate;
}

/**
 * Request parameters for booksDelete operation in BooksApi.
 * @export
 * @interface BooksApiBooksDeleteRequest
 */
export interface BooksApiBooksDeleteRequest {
  /**
   *
   * @type {string}
   * @memberof BooksApiBooksDelete
   */
  readonly id: string;
}

/**
 * Request parameters for booksList operation in BooksApi.
 * @export
 * @interface BooksApiBooksListRequest
 */
export interface BooksApiBooksListRequest {
  /**
   *
   * @type {number}
   * @memberof BooksApiBooksList
   */
  readonly limit?: number;

  /**
   *
   * @type {number}
   * @memberof BooksApiBooksList
   */
  readonly page?: number;
}

/**
 * Request parameters for booksListV2 operation in BooksApi.
 * @export
 * @interface BooksApiBooksListV2Request
 */
export interface BooksApiBooksListV2Request {
  /**
   *
   * @type {number}
   * @memberof BooksApiBooksListV2
   */
  readonly limit?: number;

  /**
   *
   * @type {number}
   * @memberof BooksApiBooksListV2
   */
  readonly page?: number;
}

/**
 * Request parameters for booksRead operation in BooksApi.
 * @export
 * @interface BooksApiBooksReadRequest
 */
export interface BooksApiBooksReadRequest {
  /**
   *
   * @type {string}
   * @memberof BooksApiBooksRead
   */
  readonly id: string;
}

/**
 * Request parameters for booksUpdate operation in BooksApi.
 * @export
 * @interface BooksApiBooksUpdateRequest
 */
export interface BooksApiBooksUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof BooksApiBooksUpdate
   */
  readonly id: string;

  /**
   *
   * @type {BookingCreateUpdate}
   * @memberof BooksApiBooksUpdate
   */
  readonly bookingCreateUpdate: BookingCreateUpdate;
}

/**
 * Request parameters for rsvpCreateClient operation in BooksApi.
 * @export
 * @interface BooksApiRsvpCreateClientRequest
 */
export interface BooksApiRsvpCreateClientRequest {
  /**
   *
   * @type {string}
   * @memberof BooksApiRsvpCreateClient
   */
  readonly clientId: string;

  /**
   *
   * @type {BookingCreateUpdate}
   * @memberof BooksApiRsvpCreateClient
   */
  readonly bookingCreateUpdate: BookingCreateUpdate;
}

/**
 * BooksApi - object-oriented interface
 * @export
 * @class BooksApi
 * @extends {BaseAPI}
 */
export class BooksApi extends BaseAPI {
  /**
   * Display a listing of the additional booking table fields.
   * @summary Display a listing of the additional booking table fields.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BooksApi
   */
  public bookingAdditionalFields(options?: AxiosRequestConfig) {
    return BooksApiFp(this.configuration)
      .bookingAdditionalFields(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Store a newly created resource in storage.
   * @summary Store a newly booking created resource in storage.
   * @param {BooksApiBooksCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BooksApi
   */
  public booksCreate(
    requestParameters: BooksApiBooksCreateRequest,
    options?: AxiosRequestConfig
  ) {
    return BooksApiFp(this.configuration)
      .booksCreate(requestParameters.bookingCreateUpdate, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Remove the specified resource from storage.
   * @summary Remove the specified booking from storage.
   * @param {BooksApiBooksDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BooksApi
   */
  public booksDelete(
    requestParameters: BooksApiBooksDeleteRequest,
    options?: AxiosRequestConfig
  ) {
    return BooksApiFp(this.configuration)
      .booksDelete(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Display a listing of the resource.
   * @summary Display a listing of the resource.
   * @param {BooksApiBooksListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BooksApi
   */
  public booksList(
    requestParameters: BooksApiBooksListRequest = {},
    options?: AxiosRequestConfig
  ) {
    return BooksApiFp(this.configuration)
      .booksList(requestParameters.limit, requestParameters.page, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Display a listing of the resource.
   * @summary Display a listing of the book resource.
   * @param {BooksApiBooksListV2Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BooksApi
   */
  public booksListV2(
    requestParameters: BooksApiBooksListV2Request = {},
    options?: AxiosRequestConfig
  ) {
    return BooksApiFp(this.configuration)
      .booksListV2(requestParameters.limit, requestParameters.page, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Display the specified resource.
   * @summary Display the specified booking resource.
   * @param {BooksApiBooksReadRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BooksApi
   */
  public booksRead(
    requestParameters: BooksApiBooksReadRequest,
    options?: AxiosRequestConfig
  ) {
    return BooksApiFp(this.configuration)
      .booksRead(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update the specified resource in storage.
   * @summary Update the specified booking resource in storage.
   * @param {BooksApiBooksUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BooksApi
   */
  public booksUpdate(
    requestParameters: BooksApiBooksUpdateRequest,
    options?: AxiosRequestConfig
  ) {
    return BooksApiFp(this.configuration)
      .booksUpdate(
        requestParameters.id,
        requestParameters.bookingCreateUpdate,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Display a listing of the resource.
   * @summary Export books of client.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BooksApi
   */
  public exportBooks(options?: AxiosRequestConfig) {
    return BooksApiFp(this.configuration)
      .exportBooks(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Store a newly created resource in storage.
   * @summary Store a newly booking created resource in storage.
   * @param {BooksApiRsvpCreateClientRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BooksApi
   */
  public rsvpCreateClient(
    requestParameters: BooksApiRsvpCreateClientRequest,
    options?: AxiosRequestConfig
  ) {
    return BooksApiFp(this.configuration)
      .rsvpCreateClient(
        requestParameters.clientId,
        requestParameters.bookingCreateUpdate,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ClientApi - axios parameter creator
 * @export
 */
export const ClientApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Display the specified client data.
     * @param {string} slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientReadBySlug: async (
      slug: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'slug' is not null or undefined
      assertParamExists("clientReadBySlug", "slug", slug);
      const localVarPath = `/clients/{slug}`.replace(
        `{${"slug"}}`,
        encodeURIComponent(String(slug))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Store a newly created resource in storage.
     * @summary Store a newly created client in storage.
     * @param {Client} client
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsCreate: async (
      client: Client,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'client' is not null or undefined
      assertParamExists("clientsCreate", "client", client);
      const localVarPath = `/admin/clients`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        client,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Display the specified resource.
     * @summary Display the specified client.
     * @param {string} optionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsDashboardDetail: async (
      optionId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'optionId' is not null or undefined
      assertParamExists("clientsDashboardDetail", "optionId", optionId);
      const localVarPath = `/admin/clients/v2/{option_id}`.replace(
        `{${"option_id"}}`,
        encodeURIComponent(String(optionId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Display the specified resource.
     * @summary Display the specified client.
     * @param {string} optionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsDetail: async (
      optionId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'optionId' is not null or undefined
      assertParamExists("clientsDetail", "optionId", optionId);
      const localVarPath = `/admin/clients/{option_id}`.replace(
        `{${"option_id"}}`,
        encodeURIComponent(String(optionId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Display the specified resource.
     * @summary Display the specified options based on client id.
     * @param {string} clientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsGetOptions: async (
      clientId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'clientId' is not null or undefined
      assertParamExists("clientsGetOptions", "clientId", clientId);
      const localVarPath = `/admin/clients/{client_id}/options`.replace(
        `{${"client_id"}}`,
        encodeURIComponent(String(clientId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Display a listing of the resource.
     * @summary Display a listing of the clients.
     * @param {number} [limit]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsList: async (
      limit?: number,
      page?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/clients`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update the specified client resource in storage.
     * @summary Update the specified client resource in storage.
     * @param {string} optionId
     * @param {ClientDashboard} clientDashboard
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsUpdate: async (
      optionId: string,
      clientDashboard: ClientDashboard,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'optionId' is not null or undefined
      assertParamExists("clientsUpdate", "optionId", optionId);
      // verify required parameter 'clientDashboard' is not null or undefined
      assertParamExists("clientsUpdate", "clientDashboard", clientDashboard);
      const localVarPath = `/admin/clients/{option_id}`.replace(
        `{${"option_id"}}`,
        encodeURIComponent(String(optionId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        clientDashboard,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Display the specified content data.
     * @param {string} clientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contentsReadByClientId: async (
      clientId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'clientId' is not null or undefined
      assertParamExists("contentsReadByClientId", "clientId", clientId);
      const localVarPath = `/clients/{client_id}/contents`.replace(
        `{${"client_id"}}`,
        encodeURIComponent(String(clientId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Display the specified lock data by given slug.
     * @param {string} slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLockScreenConfig: async (
      slug: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'slug' is not null or undefined
      assertParamExists("getLockScreenConfig", "slug", slug);
      const localVarPath = `/clients/{slug}/lock`.replace(
        `{${"slug"}}`,
        encodeURIComponent(String(slug))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Display the specified meta data by given slug.
     * @param {string} slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    metaReadBySlug: async (
      slug: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'slug' is not null or undefined
      assertParamExists("metaReadBySlug", "slug", slug);
      const localVarPath = `/clients/{slug}/meta`.replace(
        `{${"slug"}}`,
        encodeURIComponent(String(slug))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Store a newly created resource in storage.
     * @summary Store a newly created option in storage.
     * @param {string} clientId
     * @param {ClientOption} clientOption
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    optionsCreate: async (
      clientId: string,
      clientOption: ClientOption,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'clientId' is not null or undefined
      assertParamExists("optionsCreate", "clientId", clientId);
      // verify required parameter 'clientOption' is not null or undefined
      assertParamExists("optionsCreate", "clientOption", clientOption);
      const localVarPath = `/admin/clients/{client_id}/options`.replace(
        `{${"client_id"}}`,
        encodeURIComponent(String(clientId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        clientOption,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Display the specified options data.
     * @param {string} slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    optionsReadBySlug: async (
      slug: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'slug' is not null or undefined
      assertParamExists("optionsReadBySlug", "slug", slug);
      const localVarPath = `/clients/{slug}/options`.replace(
        `{${"slug"}}`,
        encodeURIComponent(String(slug))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update the specified client resource in storage.
     * @summary Update the specified option resource in storage.
     * @param {string} optionId
     * @param {ClientOption} clientOption
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    optionsUpdate: async (
      optionId: string,
      clientOption: ClientOption,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'optionId' is not null or undefined
      assertParamExists("optionsUpdate", "optionId", optionId);
      // verify required parameter 'clientOption' is not null or undefined
      assertParamExists("optionsUpdate", "clientOption", clientOption);
      const localVarPath = `/admin/clients/options/{option_id}`.replace(
        `{${"option_id"}}`,
        encodeURIComponent(String(optionId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        clientOption,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Display all of the slug client data.
     * @param {SlugsRequest} [slugsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    slugsList: async (
      slugsRequest?: SlugsRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/clients/get/slugs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        slugsRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ClientApi - functional programming interface
 * @export
 */
export const ClientApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ClientApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Display the specified client data.
     * @param {string} slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientReadBySlug(
      slug: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Client>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.clientReadBySlug(slug, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Store a newly created resource in storage.
     * @summary Store a newly created client in storage.
     * @param {Client} client
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientsCreate(
      client: Client,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Client>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientsCreate(
        client,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Display the specified resource.
     * @summary Display the specified client.
     * @param {string} optionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientsDashboardDetail(
      optionId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Client>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.clientsDashboardDetail(
          optionId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Display the specified resource.
     * @summary Display the specified client.
     * @param {string} optionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientsDetail(
      optionId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Client>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientsDetail(
        optionId,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Display the specified resource.
     * @summary Display the specified options based on client id.
     * @param {string} clientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientsGetOptions(
      clientId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDetail>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.clientsGetOptions(clientId, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Display a listing of the resource.
     * @summary Display a listing of the clients.
     * @param {number} [limit]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientsList(
      limit?: number,
      page?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<ClientsList200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientsList(
        limit,
        page,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Update the specified client resource in storage.
     * @summary Update the specified client resource in storage.
     * @param {string} optionId
     * @param {ClientDashboard} clientDashboard
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientsUpdate(
      optionId: string,
      clientDashboard: ClientDashboard,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<ClientDashboard>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientsUpdate(
        optionId,
        clientDashboard,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Display the specified content data.
     * @param {string} clientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contentsReadByClientId(
      clientId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Content>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.contentsReadByClientId(
          clientId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Display the specified lock data by given slug.
     * @param {string} slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLockScreenConfig(
      slug: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lock>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getLockScreenConfig(slug, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Display the specified meta data by given slug.
     * @param {string} slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async metaReadBySlug(
      slug: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Meta>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.metaReadBySlug(
        slug,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Store a newly created resource in storage.
     * @summary Store a newly created option in storage.
     * @param {string} clientId
     * @param {ClientOption} clientOption
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async optionsCreate(
      clientId: string,
      clientOption: ClientOption,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Client>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.optionsCreate(
        clientId,
        clientOption,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Display the specified options data.
     * @param {string} slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async optionsReadBySlug(
      slug: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Option>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.optionsReadBySlug(slug, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Update the specified client resource in storage.
     * @summary Update the specified option resource in storage.
     * @param {string} optionId
     * @param {ClientOption} clientOption
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async optionsUpdate(
      optionId: string,
      clientOption: ClientOption,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Client>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.optionsUpdate(
        optionId,
        clientOption,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Display all of the slug client data.
     * @param {SlugsRequest} [slugsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async slugsList(
      slugsRequest?: SlugsRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.slugsList(
        slugsRequest,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * ClientApi - factory interface
 * @export
 */
export const ClientApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ClientApiFp(configuration);
  return {
    /**
     *
     * @summary Display the specified client data.
     * @param {string} slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientReadBySlug(slug: string, options?: any): AxiosPromise<Client> {
      return localVarFp
        .clientReadBySlug(slug, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Store a newly created resource in storage.
     * @summary Store a newly created client in storage.
     * @param {Client} client
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsCreate(client: Client, options?: any): AxiosPromise<Client> {
      return localVarFp
        .clientsCreate(client, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Display the specified resource.
     * @summary Display the specified client.
     * @param {string} optionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsDashboardDetail(
      optionId: string,
      options?: any
    ): AxiosPromise<Client> {
      return localVarFp
        .clientsDashboardDetail(optionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Display the specified resource.
     * @summary Display the specified client.
     * @param {string} optionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsDetail(optionId: string, options?: any): AxiosPromise<Client> {
      return localVarFp
        .clientsDetail(optionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Display the specified resource.
     * @summary Display the specified options based on client id.
     * @param {string} clientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsGetOptions(
      clientId: string,
      options?: any
    ): AxiosPromise<ClientDetail> {
      return localVarFp
        .clientsGetOptions(clientId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Display a listing of the resource.
     * @summary Display a listing of the clients.
     * @param {number} [limit]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsList(
      limit?: number,
      page?: number,
      options?: any
    ): AxiosPromise<ClientsList200Response> {
      return localVarFp
        .clientsList(limit, page, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Update the specified client resource in storage.
     * @summary Update the specified client resource in storage.
     * @param {string} optionId
     * @param {ClientDashboard} clientDashboard
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsUpdate(
      optionId: string,
      clientDashboard: ClientDashboard,
      options?: any
    ): AxiosPromise<ClientDashboard> {
      return localVarFp
        .clientsUpdate(optionId, clientDashboard, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Display the specified content data.
     * @param {string} clientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contentsReadByClientId(
      clientId: string,
      options?: any
    ): AxiosPromise<Content> {
      return localVarFp
        .contentsReadByClientId(clientId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Display the specified lock data by given slug.
     * @param {string} slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLockScreenConfig(slug: string, options?: any): AxiosPromise<Lock> {
      return localVarFp
        .getLockScreenConfig(slug, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Display the specified meta data by given slug.
     * @param {string} slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    metaReadBySlug(slug: string, options?: any): AxiosPromise<Meta> {
      return localVarFp
        .metaReadBySlug(slug, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Store a newly created resource in storage.
     * @summary Store a newly created option in storage.
     * @param {string} clientId
     * @param {ClientOption} clientOption
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    optionsCreate(
      clientId: string,
      clientOption: ClientOption,
      options?: any
    ): AxiosPromise<Client> {
      return localVarFp
        .optionsCreate(clientId, clientOption, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Display the specified options data.
     * @param {string} slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    optionsReadBySlug(slug: string, options?: any): AxiosPromise<Option> {
      return localVarFp
        .optionsReadBySlug(slug, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Update the specified client resource in storage.
     * @summary Update the specified option resource in storage.
     * @param {string} optionId
     * @param {ClientOption} clientOption
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    optionsUpdate(
      optionId: string,
      clientOption: ClientOption,
      options?: any
    ): AxiosPromise<Client> {
      return localVarFp
        .optionsUpdate(optionId, clientOption, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Display all of the slug client data.
     * @param {SlugsRequest} [slugsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    slugsList(
      slugsRequest?: SlugsRequest,
      options?: any
    ): AxiosPromise<Array<string>> {
      return localVarFp
        .slugsList(slugsRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for clientReadBySlug operation in ClientApi.
 * @export
 * @interface ClientApiClientReadBySlugRequest
 */
export interface ClientApiClientReadBySlugRequest {
  /**
   *
   * @type {string}
   * @memberof ClientApiClientReadBySlug
   */
  readonly slug: string;
}

/**
 * Request parameters for clientsCreate operation in ClientApi.
 * @export
 * @interface ClientApiClientsCreateRequest
 */
export interface ClientApiClientsCreateRequest {
  /**
   *
   * @type {Client}
   * @memberof ClientApiClientsCreate
   */
  readonly client: Client;
}

/**
 * Request parameters for clientsDashboardDetail operation in ClientApi.
 * @export
 * @interface ClientApiClientsDashboardDetailRequest
 */
export interface ClientApiClientsDashboardDetailRequest {
  /**
   *
   * @type {string}
   * @memberof ClientApiClientsDashboardDetail
   */
  readonly optionId: string;
}

/**
 * Request parameters for clientsDetail operation in ClientApi.
 * @export
 * @interface ClientApiClientsDetailRequest
 */
export interface ClientApiClientsDetailRequest {
  /**
   *
   * @type {string}
   * @memberof ClientApiClientsDetail
   */
  readonly optionId: string;
}

/**
 * Request parameters for clientsGetOptions operation in ClientApi.
 * @export
 * @interface ClientApiClientsGetOptionsRequest
 */
export interface ClientApiClientsGetOptionsRequest {
  /**
   *
   * @type {string}
   * @memberof ClientApiClientsGetOptions
   */
  readonly clientId: string;
}

/**
 * Request parameters for clientsList operation in ClientApi.
 * @export
 * @interface ClientApiClientsListRequest
 */
export interface ClientApiClientsListRequest {
  /**
   *
   * @type {number}
   * @memberof ClientApiClientsList
   */
  readonly limit?: number;

  /**
   *
   * @type {number}
   * @memberof ClientApiClientsList
   */
  readonly page?: number;
}

/**
 * Request parameters for clientsUpdate operation in ClientApi.
 * @export
 * @interface ClientApiClientsUpdateRequest
 */
export interface ClientApiClientsUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof ClientApiClientsUpdate
   */
  readonly optionId: string;

  /**
   *
   * @type {ClientDashboard}
   * @memberof ClientApiClientsUpdate
   */
  readonly clientDashboard: ClientDashboard;
}

/**
 * Request parameters for contentsReadByClientId operation in ClientApi.
 * @export
 * @interface ClientApiContentsReadByClientIdRequest
 */
export interface ClientApiContentsReadByClientIdRequest {
  /**
   *
   * @type {string}
   * @memberof ClientApiContentsReadByClientId
   */
  readonly clientId: string;
}

/**
 * Request parameters for getLockScreenConfig operation in ClientApi.
 * @export
 * @interface ClientApiGetLockScreenConfigRequest
 */
export interface ClientApiGetLockScreenConfigRequest {
  /**
   *
   * @type {string}
   * @memberof ClientApiGetLockScreenConfig
   */
  readonly slug: string;
}

/**
 * Request parameters for metaReadBySlug operation in ClientApi.
 * @export
 * @interface ClientApiMetaReadBySlugRequest
 */
export interface ClientApiMetaReadBySlugRequest {
  /**
   *
   * @type {string}
   * @memberof ClientApiMetaReadBySlug
   */
  readonly slug: string;
}

/**
 * Request parameters for optionsCreate operation in ClientApi.
 * @export
 * @interface ClientApiOptionsCreateRequest
 */
export interface ClientApiOptionsCreateRequest {
  /**
   *
   * @type {string}
   * @memberof ClientApiOptionsCreate
   */
  readonly clientId: string;

  /**
   *
   * @type {ClientOption}
   * @memberof ClientApiOptionsCreate
   */
  readonly clientOption: ClientOption;
}

/**
 * Request parameters for optionsReadBySlug operation in ClientApi.
 * @export
 * @interface ClientApiOptionsReadBySlugRequest
 */
export interface ClientApiOptionsReadBySlugRequest {
  /**
   *
   * @type {string}
   * @memberof ClientApiOptionsReadBySlug
   */
  readonly slug: string;
}

/**
 * Request parameters for optionsUpdate operation in ClientApi.
 * @export
 * @interface ClientApiOptionsUpdateRequest
 */
export interface ClientApiOptionsUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof ClientApiOptionsUpdate
   */
  readonly optionId: string;

  /**
   *
   * @type {ClientOption}
   * @memberof ClientApiOptionsUpdate
   */
  readonly clientOption: ClientOption;
}

/**
 * Request parameters for slugsList operation in ClientApi.
 * @export
 * @interface ClientApiSlugsListRequest
 */
export interface ClientApiSlugsListRequest {
  /**
   *
   * @type {SlugsRequest}
   * @memberof ClientApiSlugsList
   */
  readonly slugsRequest?: SlugsRequest;
}

/**
 * ClientApi - object-oriented interface
 * @export
 * @class ClientApi
 * @extends {BaseAPI}
 */
export class ClientApi extends BaseAPI {
  /**
   *
   * @summary Display the specified client data.
   * @param {ClientApiClientReadBySlugRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientApi
   */
  public clientReadBySlug(
    requestParameters: ClientApiClientReadBySlugRequest,
    options?: AxiosRequestConfig
  ) {
    return ClientApiFp(this.configuration)
      .clientReadBySlug(requestParameters.slug, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Store a newly created resource in storage.
   * @summary Store a newly created client in storage.
   * @param {ClientApiClientsCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientApi
   */
  public clientsCreate(
    requestParameters: ClientApiClientsCreateRequest,
    options?: AxiosRequestConfig
  ) {
    return ClientApiFp(this.configuration)
      .clientsCreate(requestParameters.client, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Display the specified resource.
   * @summary Display the specified client.
   * @param {ClientApiClientsDashboardDetailRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientApi
   */
  public clientsDashboardDetail(
    requestParameters: ClientApiClientsDashboardDetailRequest,
    options?: AxiosRequestConfig
  ) {
    return ClientApiFp(this.configuration)
      .clientsDashboardDetail(requestParameters.optionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Display the specified resource.
   * @summary Display the specified client.
   * @param {ClientApiClientsDetailRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientApi
   */
  public clientsDetail(
    requestParameters: ClientApiClientsDetailRequest,
    options?: AxiosRequestConfig
  ) {
    return ClientApiFp(this.configuration)
      .clientsDetail(requestParameters.optionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Display the specified resource.
   * @summary Display the specified options based on client id.
   * @param {ClientApiClientsGetOptionsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientApi
   */
  public clientsGetOptions(
    requestParameters: ClientApiClientsGetOptionsRequest,
    options?: AxiosRequestConfig
  ) {
    return ClientApiFp(this.configuration)
      .clientsGetOptions(requestParameters.clientId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Display a listing of the resource.
   * @summary Display a listing of the clients.
   * @param {ClientApiClientsListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientApi
   */
  public clientsList(
    requestParameters: ClientApiClientsListRequest = {},
    options?: AxiosRequestConfig
  ) {
    return ClientApiFp(this.configuration)
      .clientsList(requestParameters.limit, requestParameters.page, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update the specified client resource in storage.
   * @summary Update the specified client resource in storage.
   * @param {ClientApiClientsUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientApi
   */
  public clientsUpdate(
    requestParameters: ClientApiClientsUpdateRequest,
    options?: AxiosRequestConfig
  ) {
    return ClientApiFp(this.configuration)
      .clientsUpdate(
        requestParameters.optionId,
        requestParameters.clientDashboard,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Display the specified content data.
   * @param {ClientApiContentsReadByClientIdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientApi
   */
  public contentsReadByClientId(
    requestParameters: ClientApiContentsReadByClientIdRequest,
    options?: AxiosRequestConfig
  ) {
    return ClientApiFp(this.configuration)
      .contentsReadByClientId(requestParameters.clientId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Display the specified lock data by given slug.
   * @param {ClientApiGetLockScreenConfigRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientApi
   */
  public getLockScreenConfig(
    requestParameters: ClientApiGetLockScreenConfigRequest,
    options?: AxiosRequestConfig
  ) {
    return ClientApiFp(this.configuration)
      .getLockScreenConfig(requestParameters.slug, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Display the specified meta data by given slug.
   * @param {ClientApiMetaReadBySlugRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientApi
   */
  public metaReadBySlug(
    requestParameters: ClientApiMetaReadBySlugRequest,
    options?: AxiosRequestConfig
  ) {
    return ClientApiFp(this.configuration)
      .metaReadBySlug(requestParameters.slug, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Store a newly created resource in storage.
   * @summary Store a newly created option in storage.
   * @param {ClientApiOptionsCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientApi
   */
  public optionsCreate(
    requestParameters: ClientApiOptionsCreateRequest,
    options?: AxiosRequestConfig
  ) {
    return ClientApiFp(this.configuration)
      .optionsCreate(
        requestParameters.clientId,
        requestParameters.clientOption,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Display the specified options data.
   * @param {ClientApiOptionsReadBySlugRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientApi
   */
  public optionsReadBySlug(
    requestParameters: ClientApiOptionsReadBySlugRequest,
    options?: AxiosRequestConfig
  ) {
    return ClientApiFp(this.configuration)
      .optionsReadBySlug(requestParameters.slug, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update the specified client resource in storage.
   * @summary Update the specified option resource in storage.
   * @param {ClientApiOptionsUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientApi
   */
  public optionsUpdate(
    requestParameters: ClientApiOptionsUpdateRequest,
    options?: AxiosRequestConfig
  ) {
    return ClientApiFp(this.configuration)
      .optionsUpdate(
        requestParameters.optionId,
        requestParameters.clientOption,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Display all of the slug client data.
   * @param {ClientApiSlugsListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClientApi
   */
  public slugsList(
    requestParameters: ClientApiSlugsListRequest = {},
    options?: AxiosRequestConfig
  ) {
    return ClientApiFp(this.configuration)
      .slugsList(requestParameters.slugsRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * FilesApi - axios parameter creator
 * @export
 */
export const FilesApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * Store a newly created resource in storage.
     * @summary Store a newly file in storage
     * @param {string} optionId
     * @param {File} [fileUpload]
     * @param {string} [sectionType]
     * @param {string} [subSection]
     * @param {number} [order]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filesCreate: async (
      optionId: string,
      fileUpload?: File,
      sectionType?: string,
      subSection?: string,
      order?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'optionId' is not null or undefined
      assertParamExists("filesCreate", "optionId", optionId);
      const localVarPath = `/admin/clients/images/upload/{option_id}`.replace(
        `{${"option_id"}}`,
        encodeURIComponent(String(optionId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (fileUpload !== undefined) {
        localVarFormParams.append("file_upload", fileUpload as any);
      }

      if (sectionType !== undefined) {
        localVarFormParams.append("section_type", sectionType as any);
      }

      if (subSection !== undefined) {
        localVarFormParams.append("sub_section", subSection as any);
      }

      if (order !== undefined) {
        localVarFormParams.append("order", order as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update the specified resource in storage.
     * @summary Update the specified file in storage
     * @param {string} optionId
     * @param {File} [fileUpload]
     * @param {string} [sectionType]
     * @param {string} [subSection]
     * @param {number} [order]
     * @param {string} [oldFileUrl]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filesUpdate: async (
      optionId: string,
      fileUpload?: File,
      sectionType?: string,
      subSection?: string,
      order?: number,
      oldFileUrl?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'optionId' is not null or undefined
      assertParamExists("filesUpdate", "optionId", optionId);
      const localVarPath = `/admin/clients/images/update/{option_id}`.replace(
        `{${"option_id"}}`,
        encodeURIComponent(String(optionId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (fileUpload !== undefined) {
        localVarFormParams.append("file_upload", fileUpload as any);
      }

      if (sectionType !== undefined) {
        localVarFormParams.append("section_type", sectionType as any);
      }

      if (subSection !== undefined) {
        localVarFormParams.append("sub_section", subSection as any);
      }

      if (order !== undefined) {
        localVarFormParams.append("order", order as any);
      }

      if (oldFileUrl !== undefined) {
        localVarFormParams.append("old_file_url", oldFileUrl as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Store a newly created resource in storage.
     * @summary Store a newly created music file in storage
     * @param {string} optionId
     * @param {File} [musicUpload]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    musicsCreate: async (
      optionId: string,
      musicUpload?: File,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'optionId' is not null or undefined
      assertParamExists("musicsCreate", "optionId", optionId);
      const localVarPath = `/admin/clients/musics/upload/{option_id}`.replace(
        `{${"option_id"}}`,
        encodeURIComponent(String(optionId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (musicUpload !== undefined) {
        localVarFormParams.append("music_upload", musicUpload as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FilesApi - functional programming interface
 * @export
 */
export const FilesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FilesApiAxiosParamCreator(configuration);
  return {
    /**
     * Store a newly created resource in storage.
     * @summary Store a newly file in storage
     * @param {string} optionId
     * @param {File} [fileUpload]
     * @param {string} [sectionType]
     * @param {string} [subSection]
     * @param {number} [order]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filesCreate(
      optionId: string,
      fileUpload?: File,
      sectionType?: string,
      subSection?: string,
      order?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDetail>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filesCreate(
        optionId,
        fileUpload,
        sectionType,
        subSection,
        order,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Update the specified resource in storage.
     * @summary Update the specified file in storage
     * @param {string} optionId
     * @param {File} [fileUpload]
     * @param {string} [sectionType]
     * @param {string} [subSection]
     * @param {number} [order]
     * @param {string} [oldFileUrl]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filesUpdate(
      optionId: string,
      fileUpload?: File,
      sectionType?: string,
      subSection?: string,
      order?: number,
      oldFileUrl?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDetail>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filesUpdate(
        optionId,
        fileUpload,
        sectionType,
        subSection,
        order,
        oldFileUrl,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Store a newly created resource in storage.
     * @summary Store a newly created music file in storage
     * @param {string} optionId
     * @param {File} [musicUpload]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async musicsCreate(
      optionId: string,
      musicUpload?: File,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDetail>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.musicsCreate(
        optionId,
        musicUpload,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * FilesApi - factory interface
 * @export
 */
export const FilesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = FilesApiFp(configuration);
  return {
    /**
     * Store a newly created resource in storage.
     * @summary Store a newly file in storage
     * @param {string} optionId
     * @param {File} [fileUpload]
     * @param {string} [sectionType]
     * @param {string} [subSection]
     * @param {number} [order]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filesCreate(
      optionId: string,
      fileUpload?: File,
      sectionType?: string,
      subSection?: string,
      order?: number,
      options?: any
    ): AxiosPromise<ResponseDetail> {
      return localVarFp
        .filesCreate(
          optionId,
          fileUpload,
          sectionType,
          subSection,
          order,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Update the specified resource in storage.
     * @summary Update the specified file in storage
     * @param {string} optionId
     * @param {File} [fileUpload]
     * @param {string} [sectionType]
     * @param {string} [subSection]
     * @param {number} [order]
     * @param {string} [oldFileUrl]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filesUpdate(
      optionId: string,
      fileUpload?: File,
      sectionType?: string,
      subSection?: string,
      order?: number,
      oldFileUrl?: string,
      options?: any
    ): AxiosPromise<ResponseDetail> {
      return localVarFp
        .filesUpdate(
          optionId,
          fileUpload,
          sectionType,
          subSection,
          order,
          oldFileUrl,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Store a newly created resource in storage.
     * @summary Store a newly created music file in storage
     * @param {string} optionId
     * @param {File} [musicUpload]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    musicsCreate(
      optionId: string,
      musicUpload?: File,
      options?: any
    ): AxiosPromise<ResponseDetail> {
      return localVarFp
        .musicsCreate(optionId, musicUpload, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for filesCreate operation in FilesApi.
 * @export
 * @interface FilesApiFilesCreateRequest
 */
export interface FilesApiFilesCreateRequest {
  /**
   *
   * @type {string}
   * @memberof FilesApiFilesCreate
   */
  readonly optionId: string;

  /**
   *
   * @type {File}
   * @memberof FilesApiFilesCreate
   */
  readonly fileUpload?: File;

  /**
   *
   * @type {string}
   * @memberof FilesApiFilesCreate
   */
  readonly sectionType?: string;

  /**
   *
   * @type {string}
   * @memberof FilesApiFilesCreate
   */
  readonly subSection?: string;

  /**
   *
   * @type {number}
   * @memberof FilesApiFilesCreate
   */
  readonly order?: number;
}

/**
 * Request parameters for filesUpdate operation in FilesApi.
 * @export
 * @interface FilesApiFilesUpdateRequest
 */
export interface FilesApiFilesUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof FilesApiFilesUpdate
   */
  readonly optionId: string;

  /**
   *
   * @type {File}
   * @memberof FilesApiFilesUpdate
   */
  readonly fileUpload?: File;

  /**
   *
   * @type {string}
   * @memberof FilesApiFilesUpdate
   */
  readonly sectionType?: string;

  /**
   *
   * @type {string}
   * @memberof FilesApiFilesUpdate
   */
  readonly subSection?: string;

  /**
   *
   * @type {number}
   * @memberof FilesApiFilesUpdate
   */
  readonly order?: number;

  /**
   *
   * @type {string}
   * @memberof FilesApiFilesUpdate
   */
  readonly oldFileUrl?: string;
}

/**
 * Request parameters for musicsCreate operation in FilesApi.
 * @export
 * @interface FilesApiMusicsCreateRequest
 */
export interface FilesApiMusicsCreateRequest {
  /**
   *
   * @type {string}
   * @memberof FilesApiMusicsCreate
   */
  readonly optionId: string;

  /**
   *
   * @type {File}
   * @memberof FilesApiMusicsCreate
   */
  readonly musicUpload?: File;
}

/**
 * FilesApi - object-oriented interface
 * @export
 * @class FilesApi
 * @extends {BaseAPI}
 */
export class FilesApi extends BaseAPI {
  /**
   * Store a newly created resource in storage.
   * @summary Store a newly file in storage
   * @param {FilesApiFilesCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApi
   */
  public filesCreate(
    requestParameters: FilesApiFilesCreateRequest,
    options?: AxiosRequestConfig
  ) {
    return FilesApiFp(this.configuration)
      .filesCreate(
        requestParameters.optionId,
        requestParameters.fileUpload,
        requestParameters.sectionType,
        requestParameters.subSection,
        requestParameters.order,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update the specified resource in storage.
   * @summary Update the specified file in storage
   * @param {FilesApiFilesUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApi
   */
  public filesUpdate(
    requestParameters: FilesApiFilesUpdateRequest,
    options?: AxiosRequestConfig
  ) {
    return FilesApiFp(this.configuration)
      .filesUpdate(
        requestParameters.optionId,
        requestParameters.fileUpload,
        requestParameters.sectionType,
        requestParameters.subSection,
        requestParameters.order,
        requestParameters.oldFileUrl,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Store a newly created resource in storage.
   * @summary Store a newly created music file in storage
   * @param {FilesApiMusicsCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApi
   */
  public musicsCreate(
    requestParameters: FilesApiMusicsCreateRequest,
    options?: AxiosRequestConfig
  ) {
    return FilesApiFp(this.configuration)
      .musicsCreate(
        requestParameters.optionId,
        requestParameters.musicUpload,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * LandingApi - axios parameter creator
 * @export
 */
export const LandingApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * Store a newly created resource in storage.
     * @summary Store a newly inquiry resource in storage.
     * @param {Inquiry} inquiry
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    landingInquiryCreate: async (
      inquiry: Inquiry,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'inquiry' is not null or undefined
      assertParamExists("landingInquiryCreate", "inquiry", inquiry);
      const localVarPath = `/landing/inquiry`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        inquiry,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LandingApi - functional programming interface
 * @export
 */
export const LandingApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = LandingApiAxiosParamCreator(configuration);
  return {
    /**
     * Store a newly created resource in storage.
     * @summary Store a newly inquiry resource in storage.
     * @param {Inquiry} inquiry
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async landingInquiryCreate(
      inquiry: Inquiry,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.landingInquiryCreate(inquiry, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * LandingApi - factory interface
 * @export
 */
export const LandingApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = LandingApiFp(configuration);
  return {
    /**
     * Store a newly created resource in storage.
     * @summary Store a newly inquiry resource in storage.
     * @param {Inquiry} inquiry
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    landingInquiryCreate(inquiry: Inquiry, options?: any): AxiosPromise<void> {
      return localVarFp
        .landingInquiryCreate(inquiry, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for landingInquiryCreate operation in LandingApi.
 * @export
 * @interface LandingApiLandingInquiryCreateRequest
 */
export interface LandingApiLandingInquiryCreateRequest {
  /**
   *
   * @type {Inquiry}
   * @memberof LandingApiLandingInquiryCreate
   */
  readonly inquiry: Inquiry;
}

/**
 * LandingApi - object-oriented interface
 * @export
 * @class LandingApi
 * @extends {BaseAPI}
 */
export class LandingApi extends BaseAPI {
  /**
   * Store a newly created resource in storage.
   * @summary Store a newly inquiry resource in storage.
   * @param {LandingApiLandingInquiryCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LandingApi
   */
  public landingInquiryCreate(
    requestParameters: LandingApiLandingInquiryCreateRequest,
    options?: AxiosRequestConfig
  ) {
    return LandingApiFp(this.configuration)
      .landingInquiryCreate(requestParameters.inquiry, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * LinksApi - axios parameter creator
 * @export
 */
export const LinksApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Download generated links
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    linksDownloadResult: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/links/download-result`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Download template for generating links
     * @summary Download template for generating links
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    linksDownloadTemplate: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/links/download-template`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Generate and store a newly created file in storage.
     * @summary Generate and store a newly created file in storage
     * @param {File} [fileUpload]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    linksGenerateFile: async (
      fileUpload?: File,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/links/generate-file`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (fileUpload !== undefined) {
        localVarFormParams.append("file_upload", fileUpload as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LinksApi - functional programming interface
 * @export
 */
export const LinksApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = LinksApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Download generated links
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async linksDownloadResult(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.linksDownloadResult(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Download template for generating links
     * @summary Download template for generating links
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async linksDownloadTemplate(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.linksDownloadTemplate(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Generate and store a newly created file in storage.
     * @summary Generate and store a newly created file in storage
     * @param {File} [fileUpload]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async linksGenerateFile(
      fileUpload?: File,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDetail>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.linksGenerateFile(fileUpload, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * LinksApi - factory interface
 * @export
 */
export const LinksApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = LinksApiFp(configuration);
  return {
    /**
     *
     * @summary Download generated links
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    linksDownloadResult(options?: any): AxiosPromise<void> {
      return localVarFp
        .linksDownloadResult(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Download template for generating links
     * @summary Download template for generating links
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    linksDownloadTemplate(options?: any): AxiosPromise<void> {
      return localVarFp
        .linksDownloadTemplate(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Generate and store a newly created file in storage.
     * @summary Generate and store a newly created file in storage
     * @param {File} [fileUpload]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    linksGenerateFile(
      fileUpload?: File,
      options?: any
    ): AxiosPromise<ResponseDetail> {
      return localVarFp
        .linksGenerateFile(fileUpload, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for linksGenerateFile operation in LinksApi.
 * @export
 * @interface LinksApiLinksGenerateFileRequest
 */
export interface LinksApiLinksGenerateFileRequest {
  /**
   *
   * @type {File}
   * @memberof LinksApiLinksGenerateFile
   */
  readonly fileUpload?: File;
}

/**
 * LinksApi - object-oriented interface
 * @export
 * @class LinksApi
 * @extends {BaseAPI}
 */
export class LinksApi extends BaseAPI {
  /**
   *
   * @summary Download generated links
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LinksApi
   */
  public linksDownloadResult(options?: AxiosRequestConfig) {
    return LinksApiFp(this.configuration)
      .linksDownloadResult(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Download template for generating links
   * @summary Download template for generating links
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LinksApi
   */
  public linksDownloadTemplate(options?: AxiosRequestConfig) {
    return LinksApiFp(this.configuration)
      .linksDownloadTemplate(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Generate and store a newly created file in storage.
   * @summary Generate and store a newly created file in storage
   * @param {LinksApiLinksGenerateFileRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LinksApi
   */
  public linksGenerateFile(
    requestParameters: LinksApiLinksGenerateFileRequest = {},
    options?: AxiosRequestConfig
  ) {
    return LinksApiFp(this.configuration)
      .linksGenerateFile(requestParameters.fileUpload, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * Store a newly created resource in storage.
     * @summary Regenerate user password
     * @param {string} [username]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userRegeneratePassword: async (
      username?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/regenerate-password/{username}`.replace(
        `{${"username"}}`,
        encodeURIComponent(String(username))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration);
  return {
    /**
     * Store a newly created resource in storage.
     * @summary Regenerate user password
     * @param {string} [username]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userRegeneratePassword(
      username?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.userRegeneratePassword(
          username,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = UserApiFp(configuration);
  return {
    /**
     * Store a newly created resource in storage.
     * @summary Regenerate user password
     * @param {string} [username]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userRegeneratePassword(
      username?: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .userRegeneratePassword(username, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for userRegeneratePassword operation in UserApi.
 * @export
 * @interface UserApiUserRegeneratePasswordRequest
 */
export interface UserApiUserRegeneratePasswordRequest {
  /**
   *
   * @type {string}
   * @memberof UserApiUserRegeneratePassword
   */
  readonly username?: string;
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
  /**
   * Store a newly created resource in storage.
   * @summary Regenerate user password
   * @param {UserApiUserRegeneratePasswordRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userRegeneratePassword(
    requestParameters: UserApiUserRegeneratePasswordRequest = {},
    options?: AxiosRequestConfig
  ) {
    return UserApiFp(this.configuration)
      .userRegeneratePassword(requestParameters.username, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * WishesApi - axios parameter creator
 * @export
 */
export const WishesApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * Display a listing of the wishes.
     * @summary Export client wishes.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportWishesClientId: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/wishes/exports`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Display a listing of the additional wish table fields.
     * @summary Display a listing of the additional wish table fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wishAdditionalFields: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/wishes/additional-fields`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Display a listing of the wishes.
     * @summary Display a listing of the wishes.
     * @param {number} [limit]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wishList: async (
      limit?: number,
      page?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/wishes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Display a listing of the wishes.
     * @summary Display a listing of the wishes.
     * @param {string} clientId
     * @param {number} [limit]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wishListClient: async (
      clientId: string,
      limit?: number,
      page?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'clientId' is not null or undefined
      assertParamExists("wishListClient", "clientId", clientId);
      const localVarPath = `/wishes/{client_id}`.replace(
        `{${"client_id"}}`,
        encodeURIComponent(String(clientId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Remove the specified resource from storage.
     * @summary Remove the specified wish from storage.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wishesDelete: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("wishesDelete", "id", id);
      const localVarPath = `/admin/wishes/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * WishesApi - functional programming interface
 * @export
 */
export const WishesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = WishesApiAxiosParamCreator(configuration);
  return {
    /**
     * Display a listing of the wishes.
     * @summary Export client wishes.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportWishesClientId(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exportWishesClientId(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Display a listing of the additional wish table fields.
     * @summary Display a listing of the additional wish table fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async wishAdditionalFields(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<{ [key: string]: string }>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.wishAdditionalFields(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Display a listing of the wishes.
     * @summary Display a listing of the wishes.
     * @param {number} [limit]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async wishList(
      limit?: number,
      page?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<WishListClient200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.wishList(
        limit,
        page,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Display a listing of the wishes.
     * @summary Display a listing of the wishes.
     * @param {string} clientId
     * @param {number} [limit]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async wishListClient(
      clientId: string,
      limit?: number,
      page?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<WishListClient200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.wishListClient(
        clientId,
        limit,
        page,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Remove the specified resource from storage.
     * @summary Remove the specified wish from storage.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async wishesDelete(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseDetail>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.wishesDelete(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * WishesApi - factory interface
 * @export
 */
export const WishesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = WishesApiFp(configuration);
  return {
    /**
     * Display a listing of the wishes.
     * @summary Export client wishes.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportWishesClientId(options?: any): AxiosPromise<void> {
      return localVarFp
        .exportWishesClientId(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Display a listing of the additional wish table fields.
     * @summary Display a listing of the additional wish table fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wishAdditionalFields(
      options?: any
    ): AxiosPromise<Array<{ [key: string]: string }>> {
      return localVarFp
        .wishAdditionalFields(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Display a listing of the wishes.
     * @summary Display a listing of the wishes.
     * @param {number} [limit]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wishList(
      limit?: number,
      page?: number,
      options?: any
    ): AxiosPromise<WishListClient200Response> {
      return localVarFp
        .wishList(limit, page, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Display a listing of the wishes.
     * @summary Display a listing of the wishes.
     * @param {string} clientId
     * @param {number} [limit]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wishListClient(
      clientId: string,
      limit?: number,
      page?: number,
      options?: any
    ): AxiosPromise<WishListClient200Response> {
      return localVarFp
        .wishListClient(clientId, limit, page, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Remove the specified resource from storage.
     * @summary Remove the specified wish from storage.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wishesDelete(id: string, options?: any): AxiosPromise<ResponseDetail> {
      return localVarFp
        .wishesDelete(id, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for wishList operation in WishesApi.
 * @export
 * @interface WishesApiWishListRequest
 */
export interface WishesApiWishListRequest {
  /**
   *
   * @type {number}
   * @memberof WishesApiWishList
   */
  readonly limit?: number;

  /**
   *
   * @type {number}
   * @memberof WishesApiWishList
   */
  readonly page?: number;
}

/**
 * Request parameters for wishListClient operation in WishesApi.
 * @export
 * @interface WishesApiWishListClientRequest
 */
export interface WishesApiWishListClientRequest {
  /**
   *
   * @type {string}
   * @memberof WishesApiWishListClient
   */
  readonly clientId: string;

  /**
   *
   * @type {number}
   * @memberof WishesApiWishListClient
   */
  readonly limit?: number;

  /**
   *
   * @type {number}
   * @memberof WishesApiWishListClient
   */
  readonly page?: number;
}

/**
 * Request parameters for wishesDelete operation in WishesApi.
 * @export
 * @interface WishesApiWishesDeleteRequest
 */
export interface WishesApiWishesDeleteRequest {
  /**
   *
   * @type {string}
   * @memberof WishesApiWishesDelete
   */
  readonly id: string;
}

/**
 * WishesApi - object-oriented interface
 * @export
 * @class WishesApi
 * @extends {BaseAPI}
 */
export class WishesApi extends BaseAPI {
  /**
   * Display a listing of the wishes.
   * @summary Export client wishes.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WishesApi
   */
  public exportWishesClientId(options?: AxiosRequestConfig) {
    return WishesApiFp(this.configuration)
      .exportWishesClientId(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Display a listing of the additional wish table fields.
   * @summary Display a listing of the additional wish table fields.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WishesApi
   */
  public wishAdditionalFields(options?: AxiosRequestConfig) {
    return WishesApiFp(this.configuration)
      .wishAdditionalFields(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Display a listing of the wishes.
   * @summary Display a listing of the wishes.
   * @param {WishesApiWishListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WishesApi
   */
  public wishList(
    requestParameters: WishesApiWishListRequest = {},
    options?: AxiosRequestConfig
  ) {
    return WishesApiFp(this.configuration)
      .wishList(requestParameters.limit, requestParameters.page, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Display a listing of the wishes.
   * @summary Display a listing of the wishes.
   * @param {WishesApiWishListClientRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WishesApi
   */
  public wishListClient(
    requestParameters: WishesApiWishListClientRequest,
    options?: AxiosRequestConfig
  ) {
    return WishesApiFp(this.configuration)
      .wishListClient(
        requestParameters.clientId,
        requestParameters.limit,
        requestParameters.page,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Remove the specified resource from storage.
   * @summary Remove the specified wish from storage.
   * @param {WishesApiWishesDeleteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WishesApi
   */
  public wishesDelete(
    requestParameters: WishesApiWishesDeleteRequest,
    options?: AxiosRequestConfig
  ) {
    return WishesApiFp(this.configuration)
      .wishesDelete(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
