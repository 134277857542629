import { useState } from "react";
import { Alert } from "react-bootstrap";

type Props = {
  children: React.ReactNode;
};

const AlertComponents = (props: Props) => {
  const { children } = props;
  const [show, setShow] = useState(true);

  if (show) {
    return (
      <Alert variant="danger" onClose={() => setShow(false)} dismissible>
        {children}
      </Alert>
    );
  }
  return null;
};

export const showAlert = (errors) => {
  const alert = [];
  for (const error in errors) {
    errors[error].map((value, index) => {
      alert.push(<li key={`${errors[error]}-${index}`}>{value}</li>);
    });
  }

  return <AlertComponents>{alert}</AlertComponents>;
};
