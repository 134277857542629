import type { Login as LoginForm, User } from "@services/swagger";
import { ApiInstance } from "@services/api";
import { store } from "@state/store";
import { AuthReducerType } from "@state/reducers/authReducer";

export const signIn = async (data: LoginForm) => {
  const authLogin = await ApiInstance().authApi().authLogin(data);
  localStorage.setItem("token", authLogin.data.key);
};

export const setAuthUser = async (user?: User) => {
  if (!user || !user.name) {
    const authUser = (await ApiInstance().authApi().authUserShow()).data;

    // store user state
    store.dispatch({
      type: AuthReducerType.ASSIGN_USER,
      data: authUser,
    });
  }
};
