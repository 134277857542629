export const customTextData = [
  { name: "opening_modal_title", label: "opening modal title" },
  { name: "opening_modal_description", label: "opening modal description" },
  { name: "hero_title", label: "hero title" },
  { name: "bride_groom_title", label: "bride groom title" },
  { name: "our_story_title", label: "our story title" },
  { name: "share_banner_description", label: "share banner description" },
  { name: "hashtag_title", label: "hashtag title" },
  { name: "wedding_event_title", label: "wedding event title" },
  { name: "health_protocol_title", label: "health protocol title" },
  { name: "rsvp_title", label: "rsvp title" },
  { name: "rsvp_description", label: "rsvp description" },
  { name: "rsvp_submit_note", label: "rsvp submit note" },
  { name: "rsvp_announcement", label: "rsvp announcement" },
  { name: "wish_title", label: "wish title" },
  { name: "wish_description", label: "wish description" },
  { name: "gift_title", label: "gift title" },
  { name: "gift_description", label: "gift description" },
  { name: "gift_modal_title", label: "gift modal title" },
  { name: "gift_modal_subtitle", label: "gift modal subtitle" },
  { name: "gift_modal_description", label: "gift modal description" },
  { name: "wishes_list_title", label: "wishes list title" },
  { name: "footer_banner_title", label: "footer banner title" },
  { name: "footer_banner_description", label: "footer banner description" },
];

Object.freeze(customTextData);
