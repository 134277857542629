import type { TableOptions } from "react-table";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

export const useTableCase = ({
  data,
  columns,
  initialState,
}: TableOptions<any>) => {
  return useTable(
    {
      columns,
      data: data,
      initialState,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
};
