import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDefaultDateTime } from "@shared/utils/DateUtils";
import { Accordion, Button, Col, Form, Row } from "react-bootstrap";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import type { Client, Story } from "@services/swagger";

const Stories = () => {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<Client>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "content.stories",
    keyName: "idStory",
  });

  return (
    <>
      <section className="mb-2">
        <Button
          variant="primary"
          onClick={() => {
            append({});
          }}
        >
          Add Stories
        </Button>
      </section>
      <Accordion alwaysOpen>
        {(fields as unknown as (Story & { idStory: string })[]).map(
          (item, index) => {
            const error =
              errors.content?.stories && errors.content?.stories[`${index}`];
            return (
              <Accordion.Item eventKey={index.toString()} key={item.idStory}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Accordion.Header
                    style={{ flex: "1" }}
                    className={error ? "bg-danger" : ""}
                  >{`Story ${index + 1}`}</Accordion.Header>
                  <Button
                    variant="danger"
                    onClick={() => remove(index)}
                    style={{ margin: "10px" }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </div>
                <Accordion.Body>
                  <Row>
                    <Col md={6} className="mb-3">
                      <Form.Group>
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                          type="input"
                          {...register(`content.stories.${index}.title`)}
                          defaultValue={item.title}
                          isInvalid={!!error?.title?.message}
                        />
                        {!!error?.title?.message && (
                          <Form.Control.Feedback type="invalid">
                            {error?.title?.message}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group>
                        <Form.Label>Order</Form.Label>
                        <Form.Control
                          type="number"
                          {...register(`content.stories.${index}.order`)}
                          defaultValue={item.order}
                          isInvalid={!!error?.order?.message}
                        />
                        {!!error?.order?.message && (
                          <Form.Control.Feedback type="invalid">
                            {error?.order?.message}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="mb-3">
                      <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          type="input"
                          {...register(`content.stories.${index}.description`)}
                          defaultValue={item.description}
                          isInvalid={!!error?.description?.message}
                        />
                        {!!error?.description?.message && (
                          <Form.Control.Feedback type="invalid">
                            {error?.description?.message}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group>
                        <Form.Label>Date Time</Form.Label>
                        <Controller
                          control={control}
                          name={`content.stories.${index}.datetime`}
                          render={({ field }) => (
                            <Form.Group>
                              <Form.Control
                                defaultValue={formatDefaultDateTime(
                                  field.value
                                )}
                                onChange={(e) => field.onChange(e.target.value)}
                                type="datetime-local"
                                isInvalid={!!error?.datetime?.message}
                              />
                              {!!error?.datetime?.message && (
                                <Form.Control.Feedback type="invalid">
                                  {error?.datetime?.message}
                                </Form.Control.Feedback>
                              )}
                            </Form.Group>
                          )}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="mb-3">
                      <Form.Group>
                        <Form.Label>Location</Form.Label>
                        <Form.Control
                          type="input"
                          {...register(`content.stories.${index}.location`)}
                          defaultValue={item.location}
                          isInvalid={!!error?.location?.message}
                        />
                        {!!error?.location?.message && (
                          <Form.Control.Feedback type="invalid">
                            {error?.location?.message}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group>
                        <Form.Label>Image URL</Form.Label>
                        <Form.Control
                          type="input"
                          {...register(`content.stories.${index}.image_url`)}
                          defaultValue={item.image_url}
                          isInvalid={!!error?.image_url?.message}
                        />
                        {!!error?.image_url?.message && (
                          <Form.Control.Feedback type="invalid">
                            {error?.image_url?.message}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            );
          }
        )}
      </Accordion>
    </>
  );
};

export default Stories;
