import type { ResponseDetail } from "@services/swagger";
import { ApiInstance } from ".";
import type { SongData } from "@/hooks/MusicUploader";

export const uploadSongService = async (
  data: SongData
): Promise<ResponseDetail> => {
  const fetchedData = await ApiInstance()
    .filesApi()
    .musicsCreate(data.option_id, data.music_upload);

  return fetchedData.data;
};
