import Footer from "@shared/components/Footer";
import Header from "@shared/components/Header";
import Sidebar from "@shared/components/Sidebar";
import type { ReactNode } from "react";
import type React from "react";
import { useEffect } from "react";
import { withRouter } from "react-router-dom";

type AdminLayoutProps = {
  children?: ReactNode;
};

const AdminLayout: React.FC<AdminLayoutProps & any> = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Sidebar />
        <div className="main-content">{props.children}</div>
        <Footer />
      </div>
    </>
  );
};

export default withRouter(AdminLayout);
