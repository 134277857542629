import Datatable from "@components/Datatable";
import PageContent from "@components/AdminLayout/PageContent";
import LoadingIndicator from "@shared/components/LoadingIndicator";
import { Card, Col, Row } from "react-bootstrap";

import useViewModel from "./ViewModel";

const Wishes = () => {
  const {
    data: { columns, isLoading, tableInstance, totalData },
    method: { handleDeleteButton, setTableState },
  } = useViewModel();

  return (
    <PageContent>
      <Row>
        <Col className="col-12">
          {isLoading ? (
            <div className="loading-indicator-container">
              <LoadingIndicator />
            </div>
          ) : (
            <Card>
              <Card.Body>
                <Datatable
                  tableInstance={tableInstance}
                  columns={columns}
                  fileName="wishes_data"
                  csvExport={true}
                  handleDeleteAction={handleDeleteButton}
                  setTableState={setTableState}
                  totalData={totalData}
                />
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
    </PageContent>
  );
};

export default Wishes;
