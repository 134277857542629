// MetisMenu
import MetisMenu from "metismenujs";
import React, { useCallback, useEffect, useRef, useState } from "react";
import type { RouteComponentProps } from "react-router-dom";
import { Link, withRouter } from "react-router-dom";
// //Import Scrollbar
import SimpleBar from "simplebar-react";
import { connect } from "react-redux";
import type { User } from "@services/swagger";
import { UserRoleEnum } from "@services/swagger";
// Side Menu Items
import {
  adminSidebarItems,
  clientSidebarItems,
  superAdminSidebarItems,
} from "./components/SidebarItems";

export interface SidebarItem {
  title: string;
  path: string;
  icon: JSX.Element;
}

interface Props {
  location: RouteComponentProps["location"];
  authUser: User;
}

const SidebarContent = (props: Props) => {
  const { location, authUser } = props;
  const simpleBarRef = useRef<SimpleBar>();
  const [sideMenuElement, setSideMenuElement] = useState<HTMLUListElement>();

  const getSidebarItems = () => {
    switch (authUser.role) {
      case UserRoleEnum.SuperAdmin:
        return superAdminSidebarItems;
      case UserRoleEnum.Admin:
        return adminSidebarItems;
      case UserRoleEnum.Client:
        return clientSidebarItems;
      default:
        return [];
    }
  };
  const sidebarItems = getSidebarItems();

  // Use ComponentDidMount and ComponentDidUpdate method simultaneously
  useEffect(() => {
    const activateParentDropdown = (item) => {
      item.classList.add("active");
      const parent = item.parentElement;
      const parent2El = parent.childNodes[1];
      if (parent2El && parent2El.id !== "side-menu") {
        parent2El.classList.add("mm-show");
      }

      if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.add("mm-show"); // ul tag

          const parent3 = parent2.parentElement; // li tag

          if (parent3) {
            parent3.classList.add("mm-active"); // li
            parent3.childNodes[0].classList.add("mm-active"); //a
            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.add("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.add("mm-show"); // li
                parent5.childNodes[0].classList.add("mm-active"); // a tag
              }
            }
          }
        }
        scrollElement(item);
        return false;
      }
      scrollElement(item);
      return false;
    };

    const initMenu = () => {
      if (!sideMenuElement) return;

      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const pathName = location.pathname;
      const ul = sideMenuElement;
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName.startsWith(items[i].pathname)) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [location.pathname, sideMenuElement]);

  const handleSideMenuElement = useCallback((node: HTMLUListElement) => {
    if (node !== null) {
      setSideMenuElement(node);
    }
  }, []);

  useEffect(() => {
    simpleBarRef.current.recalculate();
  }, []);

  const scrollElement = (item) => {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        simpleBarRef.current.getScrollElement().scrollTop =
          currentPosition - 300;
      }
    }
  };

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={simpleBarRef}>
        <div id="sidebar-menu">
          <ul
            className="metismenu list-unstyled"
            id="side-menu"
            ref={handleSideMenuElement}
          >
            {sidebarItems.map((item, index) => (
              <li key={index}>
                <Link to={item.path}>
                  {item.icon}
                  <span>{item.title}</span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.authReducer,
  };
};

export default withRouter(connect(mapStateToProps)(SidebarContent));
