import { useState } from "react";
import { Form } from "react-bootstrap";
import type { Control, FieldValues, UseFormRegister } from "react-hook-form";
import { Controller } from "react-hook-form";

type Props = {
  value: boolean;
  control: Control<FieldValues, unknown>;
  register: UseFormRegister<FieldValues>;
  name: string;
  errorMessage: string;
};

const ColorPickerComponent = (props: Props) => {
  const { value, control, register, name, errorMessage } = props;
  const [isDefaultColor, setIsDefaultColor] = useState<boolean>(value);

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          return (
            <Form.Switch
              type="switch"
              id="custom-switch"
              isInvalid={!!errorMessage}
              defaultChecked={isDefaultColor}
              feedback={errorMessage}
              feedbackType={"invalid"}
              onChange={(e) => {
                setIsDefaultColor(e.target.checked);
                if (!e.target.checked) {
                  return field.onChange(null);
                }
                return field.onChange("#000000");
              }}
            />
          );
        }}
      />
      {isDefaultColor && (
        <Form.Control
          type="color"
          {...register(name)}
          isInvalid={!!errorMessage}
        />
      )}
      {!!errorMessage && (
        <Form.Control.Feedback type="invalid">
          {errorMessage}
        </Form.Control.Feedback>
      )}
    </>
  );
};

export default ColorPickerComponent;
