import LoadingIndicator from "@shared/components/LoadingIndicator";
import type { ChangeEvent } from "react";
import type React from "react";
import { useEffect, useRef, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { FileUploader } from "./style";

interface Prop {
  promise: {
    resolve: (value: string) => void;
  };
  handleClose: (errorMessage?: string) => void;
  handleSubmitFile: (
    e?: ChangeEvent<HTMLInputElement>,
    file?: File
  ) => Promise<void>;
  isLoading: boolean;
  fileType?: "IMAGE" | "SONG" | "VIDEO";
}

const FileUploadModalComponent = ({
  promise,
  handleClose,
  handleSubmitFile,
  isLoading,
  fileType,
}: Prop) => {
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const [permittedFile, setPermittedFile] = useState("");
  const [headerTitle, setHeaderTitle] = useState("");

  useEffect(() => {
    switch (fileType) {
      case "IMAGE":
        setPermittedFile("image/*");
        setHeaderTitle("Upload Image");
        break;

      case "SONG":
        setPermittedFile("audio/*");
        setHeaderTitle("Upload Song");
        break;

      case "VIDEO":
        setPermittedFile("video/*");
        setHeaderTitle("Upload Video");
        break;
    }
  }, [fileType]);

  const handleDrag = (e: React.DragEvent<HTMLDivElement | HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleSubmitFile(undefined, e.dataTransfer.files[0]);
    }
  };

  const handleUploadButtonClick = () => {
    inputRef.current.click();
  };

  // TODO: uncomment when its needed
  // useEffect(() => {
  // }, [dragActive]);

  return (
    <Modal show={!!promise} onHide={handleClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{headerTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <FileUploader id="form-file-upload" onDragEnter={handleDrag}>
              <Form.Control
                type="file"
                accept={permittedFile}
                id="input-file-upload"
                ref={inputRef}
                onChange={handleSubmitFile}
              />
              <Form.Label
                id="label-file-upload"
                htmlFor="input-file-upload"
                className={dragActive ? "drag-active" : ""}
              >
                <div>
                  <p>Drag and drop your file here or</p>
                  <button
                    className="upload-button"
                    type="button"
                    onClick={handleUploadButtonClick}
                  >
                    Upload a file
                  </button>
                </div>
              </Form.Label>
              {dragActive && (
                <div
                  id="drag-file-element"
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                />
              )}
            </FileUploader>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default FileUploadModalComponent;
