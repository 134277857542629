import React from "react";
import {
  Row,
  Col,
  Card,
  Container,
  Form,
  Alert,
  Button,
  Spinner,
} from "react-bootstrap";
import { Link } from "react-router-dom";

// import images
import profileImg from "@assets/images/profile-img.png";
import { ReactComponent as LogoKoendang } from "@assets/images/logo-koendang-small.svg";

// styles
import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import useViewModel from "./ViewModel";
import type { Props } from "./Types";

const Login = (props: Props) => {
  const {
    data: { error, handleSubmit, isLoading, register },
    method: { logInClicked },
  } = useViewModel(props);
  return (
    <React.Fragment>
      <title>Login | Koendang Admin</title>
      <div className="account-pages">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-custom-primary">
                  <Row>
                    <Col xs={7}>
                      <div className="text-custom-secondary p-4">
                        <h5 className="text-custom-secondary">
                          Welcome Back !
                        </h5>
                        <p>Sign in to continue.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <Card.Body className="pt-0">
                  <div className="auth-logo-light auth-koendang-logo">
                    <div className="avatar-md profile-user-wid mb-3">
                      <span className="avatar-title rounded-circle bg-light">
                        <LogoKoendang />
                      </span>
                    </div>
                  </div>
                  <div className="p-2 mb-3">
                    <Form
                      className="form-horizontal"
                      onSubmit={handleSubmit(logInClicked)}
                    >
                      {error ? <Alert variant="danger">{error}</Alert> : null}

                      <Form.Group className="mb-3" controlId="formEmail">
                        <Form.Label>Email address / Username</Form.Label>
                        <Form.Control
                          name="email"
                          autoComplete="email"
                          className="form-control"
                          placeholder="Enter email or username"
                          type="text"
                          required
                          {...register("email")}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          autoComplete="current-password"
                          name="password"
                          type="password"
                          placeholder="Enter Password"
                          required
                          {...register("password")}
                        />
                      </Form.Group>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        {isLoading ? (
                          <Button
                            className="btn btn-block btn-custom-primary"
                            disabled
                          >
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            <span className="visually-hidden">Loading...</span>
                          </Button>
                        ) : (
                          <Button
                            className="btn btn-block btn-custom-primary"
                            type="submit"
                          >
                            Log In
                          </Button>
                        )}
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="#" className="text-muted">
                          <span className="pe-2">
                            <FontAwesomeIcon icon={faLock} />
                          </span>
                          Forgot your password?
                        </Link>
                      </div>
                    </Form>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login;
