import { useGetBanksData } from "@/hooks/useGetBank";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useImageUploader from "@hooks/ImageUploader";
import type { Bank, ClientDashboard, Payment } from "@services/swagger";
import {
  Asterisk,
  ImageWrapper,
  PaymentImageWrapper,
} from "@shared/styles/FormStyles";
import { useCallback, useEffect, useState } from "react";
import { Accordion, Button, Col, Form, Row } from "react-bootstrap";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useParams } from "react-router";

const Payments = () => {
  const {
    control,
    register,
    setValue,
    formState: { errors },
  } = useFormContext<ClientDashboard>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "content.payments",
    keyName: "idPayments",
  });

  const params = useParams<{ id: string }>();

  // Image Payment
  const [photoUrl, setPhotoUrl] = useState<string[]>(
    fields.map((item) => item.qr_code_image_url || "")
  );

  const [selectedPaymentPhoto, setSelectedPaymentPhoto] = useState("");
  const [selectedPaymentIndex, setSelectedPaymentIndex] = useState(-1);

  const handleClickUpdatePhoto = (
    paymentPhotoUrl: string,
    paymentIndex: number
  ) => {
    setSelectedPaymentPhoto(paymentPhotoUrl);
    setSelectedPaymentIndex(paymentIndex);
  };

  const closeUploadImageModal = (errorMessage?: string) => {
    if (errorMessage) {
      //handle error
    }

    setSelectedPaymentIndex(-1);
    setSelectedPaymentPhoto("");
  };

  const [ImageUploadModal, uploadImage] = useImageUploader(
    {
      section_type: "IMG_PAYMENT",
      old_file_url: selectedPaymentPhoto,
      order: selectedPaymentIndex + 1,
    },
    closeUploadImageModal
  );

  const handleUpdatePhoto = async () => {
    try {
      const uploadResponse = await uploadImage();
      setValue(
        `content.payments.${selectedPaymentIndex}.qr_code_image_url`,
        uploadResponse
      );
      if (photoUrl.length === selectedPaymentIndex) {
        setPhotoUrl([...photoUrl, uploadResponse]);
      } else {
        setPhotoUrl(
          photoUrl.map((photo, index) => {
            if (index === selectedPaymentIndex) {
              return uploadResponse;
            }
            return photo;
          })
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (selectedPaymentIndex >= 0) {
      handleUpdatePhoto();
    }
  }, [selectedPaymentIndex, selectedPaymentPhoto]);

  // Bank Data
  const { data } = useGetBanksData();

  useEffect(() => {
    fields.forEach((val, i) => {
      setValue(
        `content.payments.${i}.bank_id`,
        (val as Payment)?.default_bank?.id?.toString() ?? ""
      );
    });
  }, []);

  const findPaymentImage = useCallback(
    (fieldValue: string) =>
      data?.data?.find((val) => val.id.toString() === fieldValue)
        ?.bank_logo_url,
    [data?.data]
  );

  return (
    <>
      <section className="mb-2">
        <Button
          variant="primary"
          onClick={() => {
            append({});
          }}
        >
          Add Payment
        </Button>
      </section>
      <Accordion alwaysOpen>
        {fields.map((item, index) => {
          const error =
            errors.content?.payments && errors.content?.payments[`${index}`];
          return (
            <Accordion.Item eventKey={index.toString()} key={item.idPayments}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Accordion.Header
                  style={{ flex: "1" }}
                  className={error ? "bg-danger" : ""}
                >{`Payment ${index + 1}`}</Accordion.Header>
                <Button
                  variant="danger"
                  onClick={() => remove(index)}
                  style={{ margin: "10px" }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </div>
              <Accordion.Body>
                <Row>
                  <Col md={6}>
                    <Col md={12} className="mb-3">
                      <Form.Group>
                        <Form.Label>
                          Custom Bank Name<Asterisk>*</Asterisk>
                        </Form.Label>
                        <Form.Control
                          type="input"
                          {...register(`content.payments.${index}.bank`)}
                          defaultValue={item.bank}
                          isInvalid={!!error?.bank?.message}
                        />
                        {!!error?.bank?.message && (
                          <Form.Control.Feedback type="invalid">
                            {error?.bank?.message}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={12} className="mb-3">
                      <Form.Group>
                        <Form.Label>Account Number</Form.Label>
                        <Form.Control
                          type="input"
                          {...register(
                            `content.payments.${index}.account_number`
                          )}
                          defaultValue={item.account_number}
                          isInvalid={!!error?.account_number?.message}
                        />
                        {!!error?.account_number?.message && (
                          <Form.Control.Feedback type="invalid">
                            {error?.account_number?.message}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>

                    <Col md={12} className="mb-3">
                      <Form.Group>
                        <Form.Label>Account Owner</Form.Label>
                        <Form.Control
                          type="input"
                          {...register(
                            `content.payments.${index}.account_owner`
                          )}
                          defaultValue={item.account_owner}
                          isInvalid={!!error?.account_owner?.message}
                        />
                        {!!error?.account_owner?.message && (
                          <Form.Control.Feedback type="invalid">
                            {error?.account_owner?.message}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={12} className="mb-3">
                      <Form.Group>
                        <Form.Label>Custom Button Text</Form.Label>
                        <Form.Control
                          type="input"
                          {...register(
                            `content.payments.${index}.custom_button_text`
                          )}
                          defaultValue={item.custom_button_text}
                          isInvalid={!!error?.custom_button_text?.message}
                        />
                        {!!error?.custom_button_text?.message && (
                          <Form.Control.Feedback type="invalid">
                            {error?.custom_button_text?.message}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={12} className="mb-3">
                      <Form.Group>
                        <Form.Label>Payment URL</Form.Label>
                        <Form.Control
                          type="input"
                          {...register(`content.payments.${index}.payment_url`)}
                          defaultValue={item.payment_url}
                          isInvalid={!!error?.payment_url?.message}
                        />
                        {!!error?.payment_url?.message && (
                          <Form.Control.Feedback type="invalid">
                            {error?.payment_url?.message}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                  </Col>

                  <Col md={6} className="mb-3">
                    <Row>
                      {photoUrl[index] && (
                        <ImageWrapper className="mb-3 mb-lg-0">
                          <img
                            src={photoUrl[index]}
                            alt={`Payment [${index}] photo`}
                          />
                        </ImageWrapper>
                      )}
                      <Form.Label>QRCode Image URL</Form.Label>
                      <Form.Group as={Col} md={9}>
                        <Form.Control
                          type="input"
                          {...register(
                            `content.payments.${index}.qr_code_image_url`
                          )}
                          defaultValue={item.qr_code_image_url}
                          isInvalid={!!error?.qr_code_image_url?.message}
                          disabled
                        />
                        {!!error?.qr_code_image_url?.message && (
                          <Form.Control.Feedback type="invalid">
                            {error?.qr_code_image_url?.message}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                      <Col md={3} className="mt-3 text-end mt-lg-0">
                        <Button
                          type="button"
                          onClick={() =>
                            handleClickUpdatePhoto(photoUrl[index], index)
                          }
                          disabled={!params.id}
                        >
                          Update photo
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Form.Label>Default Bank</Form.Label>
                      <Form.Group as={Col} md={12}>
                        <Controller
                          control={control}
                          name={`content.payments.${index}.bank_id`}
                          render={({ field }) => {
                            const bankLogo = findPaymentImage(field.value);
                            return (
                              <>
                                <Form.Select
                                  onChange={(e) =>
                                    field.onChange(e.target.value)
                                  }
                                  value={field.value}
                                  placeholder="Select Bank"
                                >
                                  {/* For a blank value */}
                                  <option value={undefined}>
                                    {"Select Bank"}
                                  </option>
                                  {data?.data?.map((val: Bank, i: number) => (
                                    <option
                                      key={`${val.id}-${i}`}
                                      value={val.id}
                                    >
                                      {val.bank_name}
                                    </option>
                                  ))}
                                </Form.Select>

                                {bankLogo && (
                                  <PaymentImageWrapper className="mb-3 mb-lg-0 mt-3">
                                    <img
                                      src={bankLogo}
                                      alt={`Default Bank Logo - ${bankLogo}`}
                                    />
                                  </PaymentImageWrapper>
                                )}
                              </>
                            );
                          }}
                        />

                        {!!error?.bank?.message && (
                          <Form.Control.Feedback type="invalid">
                            {error?.bank.message}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Row>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
      <ImageUploadModal />
    </>
  );
};

export default Payments;
