import type { CustomColumnExtended } from "@/components/Datatable/Types";
import { ApiInstance } from "@services/api";

export type BookAdditonalFields = Array<CustomColumnExtended>;

export const getBookAdditonalFields =
  async (): Promise<BookAdditonalFields> => {
    const response = await ApiInstance().booksApi().bookingAdditionalFields();
    return response.data as unknown as BookAdditonalFields;
  };
