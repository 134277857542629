import { useCallback, useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";

interface PaginationsProps<T = number> {
  totalRows: T;
  rowsPerPage: T;
  pageChangeHandler(args: T): void;
}

export const Paginations = ({
  totalRows,
  rowsPerPage,
  pageChangeHandler,
}: PaginationsProps) => {
  const noOfPages = Math.ceil(totalRows / rowsPerPage);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    pageChangeHandler(currentPage);
  }, [currentPage, pageChangeHandler]);

  const handlePageChange = useCallback((pageNo: number) => {
    setCurrentPage(pageNo);
  }, []);

  const renderNumberPagination = () => {
    if (noOfPages < 6) {
      return [...Array(noOfPages)].map((_, index) => (
        <Pagination.Item
          onClick={() => handlePageChange(index + 1)}
          key={`page-idx-${index}`}
          className={index + 1 === currentPage ? "active" : ""}
        >
          {index + 1}
        </Pagination.Item>
      ));
    }
    if (currentPage < 4) {
      return (
        <>
          {Array.from(Array(4).keys()).map((x, index) => (
            <Pagination.Item
              onClick={() => handlePageChange(index + 1)}
              key={`page-idx-${index}`}
              className={index + 1 === currentPage ? "active" : ""}
            >
              {index + 1}
            </Pagination.Item>
          ))}
          <Pagination.Item disabled>{`...`}</Pagination.Item>
          <Pagination.Item
            onClick={() => handlePageChange(noOfPages)}
          >{`${noOfPages}`}</Pagination.Item>
        </>
      );
    }
    if (currentPage >= 4 && currentPage <= noOfPages - 3) {
      return (
        <>
          <Pagination.Item
            onClick={() => handlePageChange(1)}
          >{`1`}</Pagination.Item>
          <Pagination.Item disabled>{`...`}</Pagination.Item>

          <Pagination.Prev
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            {currentPage - 1}
          </Pagination.Prev>

          <Pagination.Item
            onClick={() => handlePageChange(currentPage)}
            className="active"
          >
            {currentPage}
          </Pagination.Item>

          <Pagination.Next
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === 1}
          >
            {currentPage + 1}
          </Pagination.Next>

          <Pagination.Item disabled>{`...`}</Pagination.Item>

          <Pagination.Item
            onClick={() => handlePageChange(noOfPages)}
          >{`${noOfPages}`}</Pagination.Item>
        </>
      );
    }
    if (currentPage > noOfPages - 3) {
      return (
        <>
          <Pagination.Item
            onClick={() => handlePageChange(1)}
          >{`1`}</Pagination.Item>
          <Pagination.Item disabled>{`...`}</Pagination.Item>

          {Array.from(Array(4).keys()).map((x, index) => (
            <Pagination.Item
              onClick={() => handlePageChange(noOfPages - 3 + index)}
              key={`page-idx-${index}`}
              className={currentPage === noOfPages - 3 + index ? "active" : ""}
            >
              {noOfPages - 3 + index}
            </Pagination.Item>
          ))}
        </>
      );
    }
  };

  if (noOfPages < 1) return null;

  return (
    <Pagination className="react-bootstrap-table-page-btns-ul">
      {/* Prev */}
      <Pagination.Prev
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        {"<"}
      </Pagination.Prev>
      {/* Numebers */}
      {renderNumberPagination()}
      {/* Next */}
      <Pagination.Next
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === noOfPages}
      >
        {">"}
      </Pagination.Next>
    </Pagination>
  );
};
