import { RoutePath } from "@constants/RoutesNavigator";
import type { SidebarItem } from "..";

export const superAdminSidebarItems: SidebarItem[] = [
  {
    title: "Wishes",
    icon: <i className="bx bx-comment-dots"></i>,
    path: RoutePath.WISHES,
  },
  {
    title: "RSVP",
    icon: <i className="bx bxs-book-content"></i>,
    path: RoutePath.RSVP,
  },
  {
    title: "Clients",
    icon: <i className="bx bxs-user-rectangle"></i>,
    path: RoutePath.CLIENTS,
  },
];

export const adminSidebarItems: SidebarItem[] = [
  {
    title: "Wishes",
    icon: <i className="bx bx-comment-dots"></i>,
    path: RoutePath.WISHES,
  },
  {
    title: "RSVP",
    icon: <i className="bx bxs-book-content"></i>,
    path: RoutePath.RSVP,
  },
  {
    title: "Clients",
    icon: <i className="bx bxs-user-rectangle"></i>,
    path: RoutePath.CLIENTS,
  },
];

export const clientSidebarItems: SidebarItem[] = [
  {
    title: "Wishes",
    icon: <i className="bx bx-comment-dots"></i>,
    path: RoutePath.WISHES,
  },
  {
    title: "RSVP",
    icon: <i className="bx bxs-book-content"></i>,
    path: RoutePath.RSVP,
  },
  {
    title: "Guest Links",
    icon: <i className="bx bx-link"></i>,
    path: RoutePath.LINKS,
  },
];
