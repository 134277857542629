import { FULL_DATE_FORMAT_ID } from "@/shared/constants";
import { format, parseISO } from "date-fns";

const tableDateFormatter = (cell: string) =>
  cell ? format(parseISO(cell), FULL_DATE_FORMAT_ID) : "";
const tableArrayFormatter = (cell: string[]) => (cell ? cell.join(", ") : "");
const tableArrayFormatterNewLine = (cell: string[]) =>
  cell ? (
    <div>
      {cell.map((item, idx) => (
        <div key={idx}>
          {item}, <br />
        </div>
      ))}
    </div>
  ) : (
    ""
  );

export { tableDateFormatter, tableArrayFormatter, tableArrayFormatterNewLine };
