import { Button, Modal } from "react-bootstrap";

type Modal = {
  isShow: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  deleteMutate: () => void;
};

const DeleteModal = ({ isShow, setShow, deleteMutate }: Modal) => {
  return (
    <Modal show={isShow} className="d-flex align-items-center">
      <Modal.Header closeButton onClick={() => setShow(false)}>
        <Modal.Title>Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-muted font-size-16">
          Are you sure, you want to delete this data?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() => {
            deleteMutate();
            setShow(false);
          }}
        >
          Confirm
        </Button>
        <Button variant="light" onClick={() => setShow(false)}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
