import useImageUploader from "@hooks/ImageUploader";
import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import type { Client } from "@services/swagger";
import {
  OptionBrideGroomStyleEnum,
  OptionCountdownStyleEnum,
  OptionFooterStyleEnum,
  OptionGalleryStyleEnum,
  OptionGiftStyleEnum,
  OptionHealthProtocolStyleEnum,
  OptionHeroStyleEnum,
  OptionLanguageEnum,
  OptionOpeningModalStyleEnum,
  OptionOurStoryStyleEnum,
  OptionQuoteStyleEnum,
  OptionRsvpStyleEnum,
  OptionShareBannerStyleEnum,
  OptionTemplateEnum,
  OptionWebTypeEnum,
  OptionWeddingEventStyleEnum,
  OptionWishesStyleEnum,
} from "@services/swagger";
import ColorPickerComponent from "../customFields/ColorPickerComponent";
import { optionFields } from "@shared/utils/FormUtils/formUtils";
import CustomText from "../ObjectForms/customText";
import { Asterisk, ImageWrapper } from "@shared/styles/FormStyles";
import useSongUploader from "@/hooks/MusicUploader";

const OptionForm = () => {
  const {
    control,
    register,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext<Client>();

  const [photoUrl, setPhotoUrl] = useState<string>(
    getValues("option.meta_image")
  );

  const closeUploadImageModal = (errorMessage?: string) => {
    if (errorMessage) {
      //handle error
    }
  };

  const [ImageUploadModal, uploadImage] = useImageUploader(
    {
      section_type: "IMG_META",
      old_file_url: photoUrl,
    },
    closeUploadImageModal
  );

  const handleUpdatePhoto = async () => {
    try {
      const uploadResponse = await uploadImage();
      setValue("option.meta_image", uploadResponse);
      setPhotoUrl(uploadResponse);
    } catch (error) {
      console.error(error);
    }
  };

  const closeUploadSongModal = (errorMessage?: string) => {
    if (errorMessage) {
      //handle error
    }
  };

  const [SongUploadModal, songUpload] = useSongUploader(
    {},
    closeUploadSongModal
  );

  const handleSongUpload = async () => {
    try {
      const songUploadResponse = await songUpload();
      setValue("option.song_url", songUploadResponse);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Row>
        <Col md={6} className="mb-3">
          <Form.Group>
            <Form.Label>
              Slug <Asterisk>*</Asterisk>
            </Form.Label>
            <Form.Control
              type="input"
              {...register("option.slug")}
              isInvalid={!!errors.option?.slug?.message}
            />
            {!!errors.option?.slug?.message && (
              <Form.Control.Feedback type="invalid">
                {errors.option?.slug?.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
        <Col md={6} className="mb-3">
          <Form.Group>
            <Form.Label>Template</Form.Label>
            <Controller
              control={control}
              name={"option.template"}
              render={({ field }) => {
                return (
                  <Form.Group>
                    <Form.Select
                      defaultValue={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                    >
                      {Object.values(OptionTemplateEnum).map((item, index) => {
                        const formattedItem = item
                          .replace(/_/g, " ")
                          .toLowerCase();
                        return (
                          <option value={item} key={index}>
                            {formattedItem}
                          </option>
                        );
                      })}
                    </Form.Select>
                    {!!errors.option?.template?.message && (
                      <Form.Control.Feedback type="invalid">
                        {errors.option?.template?.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                );
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-3">
          <Form.Group>
            <Form.Check
              type="checkbox"
              label="custom template"
              {...register("option.is_custom_template")}
              isInvalid={!!errors.option?.is_custom_template?.message}
              feedback={errors.option?.is_custom_template?.message}
              feedbackType={"invalid"}
            />
          </Form.Group>
        </Col>
        <Col md={6} className="mb-3">
          <Form.Group>
            <Form.Check
              type="checkbox"
              label="custom domain"
              {...register("option.is_custom_domain")}
              isInvalid={!!errors.option?.is_custom_domain?.message}
              feedback={errors.option?.is_custom_domain?.message}
              feedbackType={"invalid"}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-3">
          <Form.Group>
            <Form.Check
              type="checkbox"
              label="animation"
              {...register("option.animation")}
              isInvalid={!!errors.option?.animation?.message}
              feedback={errors.option?.animation?.message}
              feedbackType={"invalid"}
            />
          </Form.Group>
        </Col>
        <Col md={6} className="mb-3">
          <Form.Group>
            <Form.Check
              type="checkbox"
              label="slider"
              {...register("option.slider")}
              isInvalid={!!errors.option?.slider?.message}
              feedback={errors.option?.slider?.message}
              feedbackType={"invalid"}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-3">
          <Row>
            {photoUrl && (
              <ImageWrapper className="mb-3 mb-lg-0">
                <img src={photoUrl} alt="meta image" />
              </ImageWrapper>
            )}
          </Row>
        </Col>
        <Col md={6} className="mb-3">
          <Form.Group className="mb-3">
            <Form.Label>Meta Image URL</Form.Label>
            <Row>
              <Col md={9}>
                <Form.Control
                  type="input"
                  {...register("option.meta_image")}
                  isInvalid={!!errors.option?.meta_image?.message}
                  disabled
                />
                {!!errors.option?.meta_image?.message && (
                  <Form.Control.Feedback type="invalid">
                    {errors.option?.meta_image?.message}
                  </Form.Control.Feedback>
                )}
              </Col>
              <Col className="mt-3 text-end mt-lg-0">
                <Button
                  type="button"
                  onClick={handleUpdatePhoto}
                  disabled={!getValues("option.slug")}
                >
                  Update photo
                </Button>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Meta Title</Form.Label>
            <Form.Control
              type="input"
              {...register("option.meta_title")}
              isInvalid={!!errors.option?.meta_title?.message}
              placeholder="Meta title goes here"
            />
            {!!errors.option?.meta_title?.message && (
              <Form.Control.Feedback type="invalid">
                {errors.option?.meta_title?.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Meta Description</Form.Label>
            <Form.Control
              type="input"
              {...register("option.meta_description")}
              isInvalid={!!errors.option?.meta_description?.message}
              placeholder="Meta description goes here"
            />
            {!!errors.option?.meta_description?.message && (
              <Form.Control.Feedback type="invalid">
                {errors.option?.meta_description?.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-3">
          <Form.Group>
            <Form.Label>Language</Form.Label>

            <Controller
              control={control}
              name={"option.language"}
              render={({ field }) => {
                return (
                  <Form.Group>
                    <Form.Select
                      defaultValue={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      isInvalid={!!errors.option?.language?.message}
                    >
                      {optionFields(OptionLanguageEnum)}
                    </Form.Select>
                    {!!errors.option?.language?.message && (
                      <Form.Control.Feedback type="invalid">
                        {errors.option?.language?.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                );
              }}
            />
          </Form.Group>
        </Col>
        <Col md={6} className="mb-3">
          <Form.Group>
            <Form.Label>Default Guess Total</Form.Label>
            <Form.Control
              type="number"
              defaultValue={0}
              {...register("option.default_guest_total")}
              isInvalid={!!errors.option?.default_guest_total?.message}
            />
            {!!errors.option?.default_guest_total?.message && (
              <Form.Control.Feedback type="invalid">
                {errors.option?.default_guest_total?.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-3">
          <Form.Group>
            <Form.Label>Font URL</Form.Label>
            <Form.Control
              type="input"
              {...register("option.font_url")}
              isInvalid={!!errors.option?.font_url?.message}
            />
            {!!errors.option?.font_url?.message && (
              <Form.Control.Feedback type="invalid">
                {errors.option?.font_url?.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
        <Col md={6} className="mb-3">
          <Form.Group>
            <Form.Label>Song URL</Form.Label>
            <Row>
              <Col md={9}>
                <Form.Control
                  type="input"
                  disabled
                  {...register("option.song_url")}
                  isInvalid={!!errors.option?.song_url?.message}
                />
                {!!errors.option?.song_url?.message && (
                  <Form.Control.Feedback type="invalid">
                    {errors.option?.song_url?.message}
                  </Form.Control.Feedback>
                )}
              </Col>
              <Col className="mt-3 text-end mt-lg-0">
                <Button
                  type="button"
                  onClick={handleSongUpload}
                  disabled={!getValues("option.slug")}
                >
                  Upload Song
                </Button>
              </Col>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-3">
          <Form.Group>
            <Form.Label>Opening Modal Style</Form.Label>
            <Controller
              control={control}
              name={"option.opening_modal_style"}
              render={({ field }) => {
                return (
                  <Form.Group>
                    <Form.Select
                      defaultValue={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      isInvalid={!!errors.option?.opening_modal_style?.message}
                    >
                      {optionFields(OptionOpeningModalStyleEnum)}
                    </Form.Select>
                    {!!errors.option?.opening_modal_style?.message && (
                      <Form.Control.Feedback type="invalid">
                        {errors.option?.opening_modal_style?.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                );
              }}
            />
          </Form.Group>
        </Col>
        <Col md={6} className="mb-3">
          <Form.Group>
            <Form.Label>Footer Style</Form.Label>
            <Controller
              control={control}
              name={"option.footer_style"}
              render={({ field }) => {
                return (
                  <Form.Group>
                    <Form.Select
                      defaultValue={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      isInvalid={!!errors.option?.footer_style?.message}
                    >
                      {optionFields(OptionFooterStyleEnum)}
                    </Form.Select>
                    {!!errors.option?.footer_style?.message && (
                      <Form.Control.Feedback type="invalid">
                        {errors.option?.footer_style?.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                );
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-3">
          <Form.Group>
            <Form.Label>Hero Style</Form.Label>
            <Controller
              control={control}
              name={"option.hero_style"}
              render={({ field }) => {
                return (
                  <Form.Group>
                    <Form.Select
                      defaultValue={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      isInvalid={!!errors.option?.hero_style?.message}
                    >
                      {optionFields(OptionHeroStyleEnum)}
                    </Form.Select>
                    {!!errors.option?.hero_style?.message && (
                      <Form.Control.Feedback type="invalid">
                        {errors.option?.hero_style?.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                );
              }}
            />
          </Form.Group>
        </Col>
        <Col md={6} className="mb-3">
          <Form.Group>
            <Form.Label>Countdown Style</Form.Label>
            <Controller
              control={control}
              name={"option.countdown_style"}
              render={({ field }) => {
                return (
                  <Form.Group>
                    <Form.Select
                      defaultValue={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      isInvalid={!!errors.option?.countdown_style?.message}
                    >
                      {optionFields(OptionCountdownStyleEnum)}
                    </Form.Select>
                    {!!errors.option?.countdown_style?.message && (
                      <Form.Control.Feedback type="invalid">
                        {errors.option?.countdown_style?.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                );
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-3">
          <Form.Group>
            <Form.Label>Quote Style</Form.Label>
            <Controller
              control={control}
              name={"option.quote_style"}
              render={({ field }) => {
                return (
                  <Form.Group>
                    <Form.Select
                      defaultValue={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      isInvalid={!!errors.option?.quote_style?.message}
                    >
                      {optionFields(OptionQuoteStyleEnum)}
                    </Form.Select>
                    {!!errors.option?.quote_style?.message && (
                      <Form.Control.Feedback type="invalid">
                        {errors.option?.quote_style?.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                );
              }}
            />
          </Form.Group>
        </Col>
        <Col md={6} className="mb-3">
          <Form.Group>
            <Form.Label>Bride Groom Style</Form.Label>
            <Controller
              control={control}
              name={"option.bride_groom_style"}
              render={({ field }) => {
                return (
                  <Form.Group>
                    <Form.Select
                      defaultValue={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      isInvalid={!!errors.option?.bride_groom_style?.message}
                    >
                      {optionFields(OptionBrideGroomStyleEnum)}
                    </Form.Select>
                    {!!errors.option?.bride_groom_style?.message && (
                      <Form.Control.Feedback type="invalid">
                        {errors.option?.bride_groom_style?.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                );
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-3">
          <Form.Group>
            <Form.Label>Wedding Event Style</Form.Label>
            <Controller
              control={control}
              name={"option.wedding_event_style"}
              render={({ field }) => {
                return (
                  <Form.Group>
                    <Form.Select
                      defaultValue={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      isInvalid={!!errors.option?.wedding_event_style?.message}
                    >
                      {optionFields(OptionWeddingEventStyleEnum)}
                    </Form.Select>
                    {!!errors.option?.wedding_event_style?.message && (
                      <Form.Control.Feedback type="invalid">
                        {errors.option?.wedding_event_style?.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                );
              }}
            />
          </Form.Group>
        </Col>
        <Col md={6} className="mb-3">
          <Form.Group>
            <Form.Label>Our Story Style</Form.Label>
            <Controller
              control={control}
              name={"option.our_story_style"}
              render={({ field }) => {
                return (
                  <Form.Group>
                    <Form.Select
                      defaultValue={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      isInvalid={!!errors.option?.our_story_style?.message}
                    >
                      {optionFields(OptionOurStoryStyleEnum)}
                    </Form.Select>
                    {!!errors.option?.our_story_style?.message && (
                      <Form.Control.Feedback type="invalid">
                        {errors.option?.our_story_style?.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                );
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-3">
          <Form.Group>
            <Form.Label>Gallery Style</Form.Label>
            <Controller
              control={control}
              name={"option.gallery_style"}
              render={({ field }) => {
                return (
                  <Form.Group>
                    <Form.Select
                      defaultValue={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      isInvalid={!!errors.option?.gallery_style?.message}
                    >
                      {optionFields(OptionGalleryStyleEnum)}
                    </Form.Select>
                    {!!errors.option?.gallery_style?.message && (
                      <Form.Control.Feedback type="invalid">
                        {errors.option?.gallery_style?.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                );
              }}
            />
          </Form.Group>
        </Col>
        <Col md={6} className="mb-3">
          <Form.Group>
            <Form.Label>Wishes Style</Form.Label>
            <Controller
              control={control}
              name={"option.wishes_style"}
              render={({ field }) => {
                return (
                  <Form.Group>
                    <Form.Select
                      defaultValue={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      isInvalid={!!errors.option?.wishes_style?.message}
                    >
                      {optionFields(OptionWishesStyleEnum)}
                    </Form.Select>
                    {!!errors.option?.wishes_style?.message && (
                      <Form.Control.Feedback type="invalid">
                        {errors.option?.wishes_style?.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                );
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-3">
          <Form.Group>
            <Form.Label>Gift Style</Form.Label>
            <Controller
              control={control}
              name={"option.gift_style"}
              render={({ field }) => {
                return (
                  <Form.Group>
                    <Form.Select
                      defaultValue={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      isInvalid={!!errors.option?.gift_style?.message}
                    >
                      {optionFields(OptionGiftStyleEnum)}
                    </Form.Select>
                    {!!errors.option?.gift_style?.message && (
                      <Form.Control.Feedback type="invalid">
                        {errors.option?.gift_style?.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                );
              }}
            />
          </Form.Group>
        </Col>
        <Col md={6} className="mb-3">
          <Form.Group>
            <Form.Label>RSVP Style</Form.Label>
            <Controller
              control={control}
              name={"option.rsvp_style"}
              render={({ field }) => {
                return (
                  <Form.Group>
                    <Form.Select
                      defaultValue={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      isInvalid={!!errors.option?.rsvp_style?.message}
                    >
                      {optionFields(OptionRsvpStyleEnum)}
                    </Form.Select>
                    {!!errors.option?.rsvp_style?.message && (
                      <Form.Control.Feedback type="invalid">
                        {errors.option?.rsvp_style?.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                );
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-3">
          <Form.Group>
            <Form.Label>Share Banner Style</Form.Label>
            <Controller
              control={control}
              name={"option.share_banner_style"}
              render={({ field }) => {
                return (
                  <Form.Group>
                    <Form.Select
                      defaultValue={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      isInvalid={!!errors.option?.share_banner_style?.message}
                    >
                      {optionFields(OptionShareBannerStyleEnum)}
                    </Form.Select>
                    {!!errors.option?.share_banner_style?.message && (
                      <Form.Control.Feedback type="invalid">
                        {errors.option?.share_banner_style?.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                );
              }}
            />
          </Form.Group>
        </Col>
        <Col md={6} className="mb-3">
          <Form.Group>
            <Form.Label>Health Protocol Style</Form.Label>
            <Controller
              control={control}
              name={"option.health_protocol_style"}
              render={({ field }) => {
                return (
                  <Form.Group>
                    <Form.Select
                      defaultValue={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      isInvalid={
                        !!errors.option?.health_protocol_style?.message
                      }
                    >
                      {optionFields(OptionHealthProtocolStyleEnum)}
                    </Form.Select>
                    {!!errors.option?.health_protocol_style?.message && (
                      <Form.Control.Feedback type="invalid">
                        {errors.option?.health_protocol_style?.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                );
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-3">
          <Form.Group>
            <Form.Label>Web Type</Form.Label>
            <Controller
              control={control}
              name={"option.web_type"}
              render={({ field }) => {
                return (
                  <Form.Group>
                    <Form.Select
                      defaultValue={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      isInvalid={!!errors.option?.web_type?.message}
                    >
                      {optionFields(OptionWebTypeEnum)}
                    </Form.Select>
                    {!!errors.option?.web_type?.message && (
                      <Form.Control.Feedback type="invalid">
                        {errors.option?.web_type?.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                );
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={6} md={4} className="mb-3">
          <Form.Group>
            <Form.Label>Primary Color</Form.Label>
            <ColorPickerComponent
              control={control}
              name={"option.primary_color"}
              register={register}
              value={!!getValues("option.primary_color")}
              errorMessage={errors.option?.primary_color?.message}
            />
          </Form.Group>
        </Col>
        <Col xs={6} md={4} className="mb-3">
          <Form.Group>
            <Form.Label>Secondary Color</Form.Label>
            <ColorPickerComponent
              control={control}
              name={"option.secondary_color"}
              register={register}
              value={!!getValues("option.secondary_color")}
              errorMessage={errors.option?.secondary_color?.message}
            />
          </Form.Group>
        </Col>
        <Col xs={6} md={4} className="mb-3">
          <Form.Group>
            <Form.Label>Third Color</Form.Label>
            <ColorPickerComponent
              control={control}
              name={"option.third_color"}
              register={register}
              value={!!getValues("option.third_color")}
              errorMessage={errors.option?.third_color?.message}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={6} md={4} className="mb-3">
          <Form.Group>
            <Form.Label>Base Color</Form.Label>
            <ColorPickerComponent
              control={control}
              name={"option.base_color"}
              register={register}
              value={!!getValues("option.base_color")}
              errorMessage={errors.option?.base_color?.message}
            />
          </Form.Group>
        </Col>
        <Col xs={6} md={4} className="mb-3">
          <Form.Group>
            <Form.Label>Title Color</Form.Label>
            <ColorPickerComponent
              control={control}
              name={"option.title_color"}
              register={register}
              value={!!getValues("option.title_color")}
              errorMessage={errors.option?.title_color?.message}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <Form.Label>Announcement</Form.Label>
          <Form.Control
            as="textarea"
            {...register("option.announcement")}
            style={{ height: "100px" }}
            isInvalid={!!errors.option?.announcement?.message}
          />
          {!!errors.option?.announcement?.message && (
            <Form.Control.Feedback type="invalid">
              {errors.option?.announcement?.message}
            </Form.Control.Feedback>
          )}
        </Col>
      </Row>
      <ImageUploadModal />
      <SongUploadModal />
      <Row>
        <Col>
          <CustomText />
        </Col>
      </Row>
    </>
  );
};

export default OptionForm;
