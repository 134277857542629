import type { BookAdditonalFields } from "@/services/api/getBookAdditonalFields";
import { getBookAdditonalFields } from "@/services/api/getBookAdditonalFields";
import { REACT_QUERY_CACHE_KEYS } from "@/shared/constants/CacheKeys";
import type { AxiosError } from "axios";
import type { UseQueryOptions } from "react-query";
import { useQuery } from "react-query";

export const useGetBookAdditonalFields = (
  options?: UseQueryOptions<
    BookAdditonalFields,
    AxiosError,
    BookAdditonalFields,
    Array<string>
  >
) => {
  return useQuery(
    [REACT_QUERY_CACHE_KEYS.getBookAdditonalFields],
    async () => {
      try {
        // Notes: This is a hacky way to total the number field
        // TODO: need to move this logic to the backend
        const response = await getBookAdditonalFields();
        const additionalColumns = response.map((item) => {
          if (item.type !== "number") return item;

          const newItem = { ...item };
          // set fallback value to 0 if the value is null and the type is number
          if (!newItem.formatter && item.accessor) {
            newItem.accessor = (row) => {
              return row[item.accessor as string] || 0;
            };
          }

          // set the total value to the footer
          if (newItem.showTotal) {
            newItem.Footer = (footerItem) => {
              const data = footerItem.data || [];
              const total = data.reduce(
                (acc: number, row: Record<string, number>) => {
                  return acc + (row[item.accessor as string] || 0);
                },
                0
              );
              return `Total: ${total}`;
            };
          }

          return newItem;
        });
        return additionalColumns;
      } catch (error) {
        console.error(error);
      }
      return [];
    },
    {
      ...options,
    }
  );
};
