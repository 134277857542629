import { format, isValid } from "date-fns";
import { formatInTimeZone, utcToZonedTime } from "date-fns-tz";

export const setZonedTime = (date: Date, timeZone = "Asia/Jakarta"): Date => {
  return utcToZonedTime(date, timeZone);
};

export const formatDefaultDateTime = (date: string | null) => {
  if (!date || !isValid(new Date(date))) return null;
  const newDate = new Date(date);
  const zonedDate = setZonedTime(newDate);
  return format(zonedDate, "yyyy-MM-dd'T'HH:mm");
};

export const toZonedIsoString = (date: string | null) => {
  if (!date) return null;
  const newDate = setZonedTime(new Date(date));
  return isValid(newDate)
    ? formatInTimeZone(newDate, "Asia/Jakarta", "yyyy-MM-dd HH:mm:ssXXX")
    : null;
};
