import { Container, Load, Line } from "./styles";

const LoadingIndicator = () => {
  return (
    <Container>
      <Load>
        <Line></Line>
        <Line></Line>
        <Line></Line>
      </Load>
    </Container>
  );
};

export default LoadingIndicator;
