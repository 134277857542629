import { yupResolver } from "@hookform/resolvers/yup";
import { isEmpty } from "lodash";
import {
  CustomDashedHeaders,
  CustomHeaders,
} from "@pages/Clients/ClientWebDetail/styles";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import type { Client } from "@services/swagger";
import ContentForm from "./components/ContentForm/contentForm";
import { showAlert } from "./components/customFields/AlertComponents";
import OptionForm from "./components/OptionForm/optionForm";
import { Asterisk } from "@shared/styles/FormStyles";
import { clientSchema } from "@constants/yupSchema";

type Props = {
  data: Client;
  onSubmit: (data) => void;
  isDisabled?: boolean;
  error?: Record<string, unknown>;
};

const ClientForms = (props: Props) => {
  const { data, onSubmit, isDisabled, error } = props;
  const forms = useForm<Client>({
    defaultValues: data,
    resolver: yupResolver(clientSchema),
  });

  const errorForm = forms.formState.errors;

  return (
    <Container fluid>
      <CustomHeaders style={{ paddingTop: "8px" }}>
        {data.name ? `Form - ${data.name}` : "Create Client"}
      </CustomHeaders>
      {!isEmpty(error) && showAlert(error)}
      <FormProvider {...forms}>
        <Form onSubmit={forms.handleSubmit(onSubmit)}>
          <section>
            <Row className="mb-3">
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label>
                    Name {data.name && <Asterisk>*</Asterisk>}
                  </Form.Label>
                  <Form.Control
                    type="input"
                    {...forms.register("name")}
                    isInvalid={!!errorForm?.name?.message}
                  />
                  {!!errorForm?.name?.message && (
                    <Form.Control.Feedback type="invalid">
                      {errorForm?.name?.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </section>
          <section>
            <CustomDashedHeaders>{`Content`}</CustomDashedHeaders>
            <ContentForm />
          </section>
          <section>
            <CustomDashedHeaders>{`Option`}</CustomDashedHeaders>
            <OptionForm />
          </section>
          <Button
            variant="primary"
            type="submit"
            className="mb-3"
            disabled={isDisabled}
          >
            <span className="pr-2">Submit</span>
            {isDisabled && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </Form>
      </FormProvider>
    </Container>
  );
};

export default ClientForms;
