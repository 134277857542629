import type { CustomColumnExtended } from "@/components/Datatable/Types";
import { useTableCase } from "@/hooks/UseTableCase";
import { useGetWishesData } from "@/hooks/useGetWishesData";
import { deleteWish } from "@/services/api/deleteWishService";
import type { WishListClient200Response } from "@/services/swagger";
import type { AxiosResponse } from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import type { TableInstance } from "react-table";
import { toast } from "react-toastify";

interface Return {
  data: {
    wishes: AxiosResponse<WishListClient200Response>;
    tableInstance: TableInstance<any>;
    columns: CustomColumnExtended[];
    isLoading: boolean;
    totalData: number;
  };
  method: {
    handleDeleteButton(wishesId: string): Promise<void>;
    setTableState: React.Dispatch<
      React.SetStateAction<{
        pageIndex: number;
        pageSize: number;
      }>
    >;
  };
}

export default function useViewModel(): Return {
  const [tableState, setTableState] = useState({
    pageIndex: 0,
    pageSize: 25,
  });

  const { data, isLoading, refetch } = useGetWishesData(
    tableState.pageSize,
    tableState.pageIndex,
    {
      keepPreviousData: true,
      staleTime: Infinity,
    }
  );

  const columns: CustomColumnExtended[] = useMemo(() => {
    return [
      {
        accessor: "name",
        Header: "Name",
      },
      {
        accessor: "message",
        Header: "Message",
      },
    ];
  }, []);

  const wishesData = useMemo(() => {
    return data ? data.data.data : [];
  }, [data]);

  const tableInstance = useTableCase({
    columns: columns,
    data: wishesData,
    initialState: {
      pageSize: tableState?.pageSize,
      pageIndex: 0,
    },
  });

  const {
    state: { pageIndex, pageSize },
  } = tableInstance;

  useEffect(() => {
    setTableState({ pageIndex, pageSize });
  }, [pageIndex, pageSize]);

  const handleDeleteButton = useCallback(async (wishesId: string) => {
    try {
      await deleteWish(wishesId);
      refetch();
    } catch (error) {
      console.error(error);
    } finally {
      toast.success(`The Wish has been deleted`);
    }
  }, []);

  return {
    data: {
      isLoading,
      tableInstance,
      columns,
      wishes: data,
      totalData: data?.data.total,
    },
    method: {
      handleDeleteButton,
      setTableState,
    },
  };
}
