import { QueryProvider } from "@providers/QueryProvider";
import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { publicRoutes, userRoutes } from "@shared/routes/allRoutes";
import AuthMiddleware from "@shared/routes/middleware/AuthMiddleware";
import AdminLayout from "@components/AdminLayout";
import NonAuthLayout from "@shared/components/NonAuthLayout";
import "./styles.scss";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <React.Fragment>
      <QueryProvider>
        <BrowserRouter>
          <ToastContainer autoClose={5000} />
          <Switch>
            {userRoutes.map((route, idx) => (
              <AuthMiddleware
                key={idx}
                path={route.path}
                Component={route.component}
                exact={route.exact}
                isAuthProtected={true}
                Layout={AdminLayout}
                roleMiddleware={route.roleMiddleware}
              />
            ))}

            {publicRoutes.map((route, idx) => {
              return (
                <AuthMiddleware
                  key={idx}
                  path={route.path}
                  Component={route.component}
                  exact={route.exact}
                  isAuthProtected={false}
                  Layout={NonAuthLayout}
                />
              );
            })}
          </Switch>
        </BrowserRouter>
      </QueryProvider>
    </React.Fragment>
  );
}

export default App;
