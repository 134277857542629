import axios from "axios";
import {
  AuthApiFactory,
  BooksApiFactory,
  ClientApiFactory,
  WishesApiFactory,
  Configuration,
  FilesApiFactory,
  LinksApiFactory,
  BanksApiFactory,
} from "../swagger";

export const basePath = process.env.REACT_APP_API_HOST;

export const axiosInstance = () => {
  const axiosApi = axios;
  axiosApi.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        localStorage.removeItem("authUser");
        localStorage.removeItem("token");
      }
      console.error(error);
      return Promise.reject(error);
    }
  );
  return axiosApi;
};

export const ApiInstance = () => {
  const configuration = new Configuration({
    accessToken: localStorage.getItem("token") || "",
  });

  return {
    clientApi() {
      return ClientApiFactory(configuration, basePath, axiosInstance());
    },
    authApi() {
      return AuthApiFactory(configuration, basePath, axiosInstance());
    },
    booksApi() {
      return BooksApiFactory(configuration, basePath, axiosInstance());
    },
    wishesApi() {
      return WishesApiFactory(configuration, basePath, axiosInstance());
    },
    filesApi() {
      return FilesApiFactory(configuration, basePath, axiosInstance());
    },
    linksApi() {
      return LinksApiFactory(configuration, basePath, axiosInstance());
    },
    banksApi() {
      return BanksApiFactory(configuration, basePath, axiosInstance());
    },
  };
};
