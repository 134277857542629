import { useCallback, useState } from "react";
import { Button, Col, Dropdown, Row, Table } from "react-bootstrap";
// datatable related plugins
import { Paginations } from "@/shared/components/Pagination";
import { PAGE_NUMBER_OPTIONS } from "@/shared/constants";
import DeleteModal from "@shared/components/DeleteModal";
import "@shared/styles/datatables.scss";
import { ColButtons } from "@shared/styles/styles";
import ExportButton from "./ExportButton";
import GlobalFilter from "./GlobalFilter";
import type { DatatableProps } from "./Types";
// Define a default UI for filtering

const Datatable = (props: DatatableProps) => {
  const {
    columns,
    fileName,
    csvExport,
    showFooter,
    children,
    tableInstance,
    totalData,
    fileType,
    handleRedirection,
    handleDeleteAction,
    setTableState,
    onExport,
  } = props;

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    footerGroups,
    page,
    setPageSize,
    state: { pageSize, globalFilter },
    preGlobalFilteredRows,
    setGlobalFilter,
  } = tableInstance;

  const [show, setIsShow] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");

  const hasActionButton = !!handleRedirection || !!handleDeleteAction;

  const handlePageChange = useCallback((page: number) => {
    if (!setTableState) return;
    setTableState((prev) => {
      return {
        ...prev,
        pageIndex: page,
      };
    });
  }, []);

  return (
    <>
      <Row className="mb-2 justify-content-center">
        <Col md="4">
          <div className="search-box me-2 mb-2 d-inline-block">
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          </div>
        </Col>
        <ColButtons>
          {csvExport && page && page.length > 0 && (
            <ExportButton
              columns={columns}
              fileName={fileName}
              fileType={fileType}
              tableInstance={tableInstance}
              onExport={onExport}
            />
          )}
          <div className="search-box">{children}</div>
        </ColButtons>
      </Row>
      {/* Table Content */}
      <Row>
        <Col xl="12">
          <div className="table-responsive">
            <Table
              striped
              hover
              {...getTableProps()}
              className="table-nowrap react-bootstrap-table"
            >
              <thead>
                {headerGroups.map((headerGroup, idx) => (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    key={`headerGroup-${idx}`}
                  >
                    {headerGroup.headers.map((column, i) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        key={`header-${i}`}
                      >
                        {column.render("Header")}
                        {/* Add a sort direction indicator */}
                        {column.canSort &&
                          (column.isSorted ? (
                            column.isSortedDesc ? (
                              <span className="react-bootstrap-table-sort-order">
                                <span className="caret"></span>
                              </span>
                            ) : (
                              <span className="react-bootstrap-table-sort-order dropup">
                                <span className="caret"></span>
                              </span>
                            )
                          ) : (
                            <span className="order unselect">
                              <span className="dropdown">
                                <span className="caret"></span>
                              </span>
                              <span className="dropup">
                                <span className="caret"></span>
                              </span>
                            </span>
                          ))}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody>
                {page.length > 0 ? (
                  page.map((row, idx) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()} key={`col-${idx}`}>
                        {row.cells.map((cell, i) => {
                          return (
                            <td {...cell.getCellProps()} key={`cell-${i}`}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                        {hasActionButton && (
                          <td>
                            {handleRedirection && (
                              <Button onClick={() => handleRedirection(row)}>
                                Detail
                              </Button>
                            )}
                            {handleDeleteAction && (
                              <Button
                                onClick={() => {
                                  setIsShow(true);
                                  setSelectedId((row.original as any)?.id);
                                }}
                                variant="danger"
                              >
                                Delete
                              </Button>
                            )}
                          </td>
                        )}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={columns.length}>No data</td>
                  </tr>
                )}
              </tbody>

              {page.length > 0 && showFooter && (
                <tfoot>
                  {footerGroups.map((group, idx) => (
                    <tr
                      {...group.getFooterGroupProps()}
                      key={`footer-group-${idx}`}
                    >
                      {group.headers.map((column, i) => (
                        <td {...column.getFooterProps()} key={`footer-${i}`}>
                          {column.render("Footer")}
                        </td>
                      ))}
                      {hasActionButton && <td />}
                    </tr>
                  ))}
                </tfoot>
              )}
            </Table>
          </div>
        </Col>
      </Row>
      {/* Pagination */}
      <Row className="align-items-md-center mt-30">
        <Col className="inner-custom-pagination d-flex align-items-center justify-content-between">
          <div className="d-inline">
            <Dropdown className="react-bs-table-sizePerPage-dropdown">
              <Dropdown.Toggle variant="secondary" id="pageDropDown">
                {pageSize}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {PAGE_NUMBER_OPTIONS.map((size) => {
                  return (
                    <Dropdown.Item
                      key={size}
                      onClick={() => {
                        setPageSize(size);
                      }}
                    >
                      {size}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div>
            <Paginations
              totalRows={totalData}
              rowsPerPage={pageSize}
              pageChangeHandler={handlePageChange}
            />
          </div>
        </Col>
      </Row>

      <DeleteModal
        setShow={setIsShow}
        isShow={show}
        deleteMutate={() => {
          handleDeleteAction(selectedId);
        }}
      />
    </>
  );
};

export default Datatable;
