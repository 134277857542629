import PageContent from "@components/AdminLayout/PageContent";
import Datatable from "@components/Datatable";
import LoadingIndicator from "@shared/components/LoadingIndicator";
import { Button, Card, Col, Row } from "react-bootstrap";
import useViewModel from "./ViewModel";

const ClientDetailPage = () => {
  const {
    data: { columns, isLoading, tableInstance, data, totalData },
    method: { handleClickAbleClient, handleCreateWebDetail },
  } = useViewModel();

  return (
    <PageContent>
      <Row>
        <Col className="col-12">
          {isLoading ? (
            <div className="loading-indicator-container">
              <LoadingIndicator />
            </div>
          ) : (
            <Card>
              <Card.Header>Client - {data.data.name}</Card.Header>
              <Card.Body>
                <Datatable
                  columns={columns}
                  fileName="webUrl_data"
                  handleRedirection={handleClickAbleClient}
                  tableInstance={tableInstance}
                  totalData={totalData}
                >
                  <Button
                    className="btn btn-koendang btn-primary-koendang"
                    onClick={handleCreateWebDetail}
                  >
                    Create Web Detail
                  </Button>
                </Datatable>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
    </PageContent>
  );
};

export default ClientDetailPage;
