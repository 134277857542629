export const RoutePath = Object.freeze({
  ROOT: "/",
  HOME: "/home",
  LOGIN: "/auth/login",
  DASHBOARD: "/dashboard",
  LINKS: "/links",
  WISHES: "/wishes",
  RSVP: "/rsvp",
  CLIENTS: "/clients",
  CLIENT_ADD: "/clients/add",
  CLIENT_DETAIL: (id: string) => `/clients/${id}`,
  CLIENT_WEB_DETAIL: (id: string) => `/clients/web/${id}`,
  CREATE_WEB_DETAIL: (id: string) => `/clients/web/create/${id}`,
});
