export const SCREEN_SIZES = Object.freeze({
  small: "576px",
  medium: "767px",
  large: "992px",
  extra_large: "1200px",
});

export const COLORS = Object.freeze({
  white80: "rgba(255, 255, 255, 0.8)",
  primary: "#435B5B",
  primary_hover: "#384c4c",
  secondary: "#FFCEA3",
  secondary_hover: "#e4b993",
  light: "#f8f8f8",
  light_softer: "#efefef",
  dark_gray: "#2d2d2d",
  dark_gray_hover: "rgba(45, 45, 45, 0.8)",
  gray: "#757575",
  silver: "#C5C5C5",
  light_gray: "#d9dcde",
  light_green: "#12c397",
  brown: "#a78556",
  light_brown: "#f4f0ed",
  disabled: "#C7CECE",
});
