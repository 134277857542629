import { Spinner } from "react-bootstrap";
import type { UseMutationResult } from "react-query";

export const renderUploadStatus = (newLinkMutation: UseMutationResult) => {
  return (
    <>
      {newLinkMutation.isLoading && (
        <div>
          <div className="d-flex gap-2">
            <div>Generating Links...</div>
            <Spinner animation="border" size="sm" color="#435b5b" />
          </div>
        </div>
      )}
      {newLinkMutation.isSuccess && (
        <div>
          <div className="d-flex gap-2 align-items-center">
            <div>Links Generated!</div>
            <i
              className="bx bx-check-circle bx-sm"
              style={{ color: "#435b5b" }}
            ></i>
          </div>
        </div>
      )}
      {newLinkMutation.isError && (
        <div>
          <div className="d-flex gap-2 align-items-center">
            <div>Failed to generate links, please try again</div>
            <i
              className="bx bx-x-circle bx-sm"
              style={{ color: "#982b2b" }}
            ></i>
          </div>
        </div>
      )}
    </>
  );
};
