import { RoutePath } from "@constants/RoutesNavigator";
import { connect } from "react-redux";
import type { RouteComponentProps } from "react-router-dom";
import { Redirect, withRouter } from "react-router-dom";
import type { User } from "@services/swagger";
import { UserRoleEnum } from "@services/swagger";

interface Props {
  authUser: User;
  location: RouteComponentProps["location"];
}

const Home = (props: Props) => {
  const { authUser } = props;
  const redirectBasedOnUserRole: () => JSX.Element = () => {
    switch (authUser.role) {
      case UserRoleEnum.SuperAdmin && UserRoleEnum.Admin:
        return (
          <Redirect
            to={{
              pathname: RoutePath.CLIENTS,
              state: { from: props.location },
            }}
          />
        );
      case UserRoleEnum.Client:
        return (
          <Redirect
            to={{
              pathname: RoutePath.RSVP,
              state: { from: props.location },
            }}
          />
        );
      default:
        return <></>;
    }
  };

  return redirectBasedOnUserRole();
};

const mapStateToProps = (state) => {
  return {
    authUser: state.authReducer,
  };
};

export default withRouter(connect(mapStateToProps)(Home));
