const REACT_QUERY_CACHE_KEYS = Object.freeze({
  getRsvp: "GET_RSVP",
  getWish: "GET_WISHES",
  getLinksTemplate: "GET_LINKS_TEMPLATE",
  getClient: "GET_CLIENTS",
  getClientDetail: "GET_CLIENTS_DETAIL",
  getBookAdditonalFields: "GET_BOOK_ADDITONAL_FIELDS",
  getBank: "GET_BANK",
});

export { REACT_QUERY_CACHE_KEYS };
