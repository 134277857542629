import FileUploadModalComponent from "@/shared/components/FileUpload";
import type { ChangeEvent } from "react";
import { useState } from "react";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { uploadSongService } from "@/services/api/musicUpload";

export interface SongData {
  option_id?: string;
  music_upload?: File;
}

const useSongUploader = (
  songData: SongData,
  onCloseCallback: (errorMessage?: string) => void
): [() => JSX.Element, () => Promise<string>] => {
  const params = useParams<{ id: string }>();

  const uploadSongMutation = useMutation((data: SongData) =>
    uploadSongService({ ...data, option_id: data.option_id ?? params.id })
  );

  const [promise, setPromise] = useState<{
    resolve: (value: string) => void;
  }>();

  const uploadSong = () =>
    new Promise<string>((resolve) => {
      setPromise({ resolve });
    });

  const handleClose = (errorMessage?: string) => {
    setPromise(undefined);

    if (onCloseCallback) {
      onCloseCallback(errorMessage);
    }
  };

  const [loading, setLoading] = useState(false);

  const handleSubmitMusic = async (
    e?: ChangeEvent<HTMLInputElement>,
    file?: File
  ) => {
    setLoading(true);
    let data: SongData = {
      ...songData,
    };

    if (e) {
      data = {
        ...data,
        music_upload: e.target.files[0],
      };
    } else if (file) {
      data = {
        ...data,
        music_upload: file,
      };
    }

    await handleUpload(data);
  };

  const handleUpload = async (data: SongData) => {
    try {
      const uploadMusicRes = await uploadSongMutation.mutateAsync(data);
      promise.resolve(uploadMusicRes as string);

      handleClose();
    } catch (error) {
      console.error(error);
      handleClose(error as string);
    } finally {
      setLoading(false);
    }
  };

  const SongUploadModal = () => (
    <FileUploadModalComponent
      promise={promise}
      handleClose={handleClose}
      handleSubmitFile={handleSubmitMusic}
      isLoading={loading}
      fileType="SONG"
    />
  );

  return [SongUploadModal, uploadSong];
};

export default useSongUploader;
