import type { AxiosError } from "axios";
import type { FC } from "react";
import { QueryCache, QueryClient, QueryClientProvider } from "react-query";

const queryCache = new QueryCache();
const queryClient = new QueryClient({
  queryCache: queryCache,
  defaultOptions: {
    queries: {
      staleTime: 50 * 1000, // 3 seconds
      refetchOnWindowFocus: false,
      retry: (failureCount, error: AxiosError) => {
        return (
          error?.response?.status !== 401 &&
          error?.response?.status !== 403 &&
          failureCount < 3
        );
      },
    },
  },
});

export const QueryProvider: FC<React.ReactNode> = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
