import PageContent from "@components/AdminLayout/PageContent";
import Datatable from "@components/Datatable";
import LoadingIndicator from "@shared/components/LoadingIndicator";
import { Card, Col, Row } from "react-bootstrap";
import useViewModel from "./ViewModel";
import { downloadLinksFile } from "@/shared/utils/linksUtils";
import { exportBooksService } from "@/services/api/exportBooksService";

const Rsvp = () => {
  const {
    data: { isLoading, tableInstance, tableColumns, totalData },
    method: { handleDeleteButton, setTableState },
  } = useViewModel();

  return (
    <PageContent>
      <Row>
        <Col className="col-12">
          {isLoading ? (
            <div className="loading-indicator-container">
              <LoadingIndicator />
            </div>
          ) : (
            <Card>
              <Card.Body>
                <Datatable
                  columns={tableColumns}
                  fileName="RSVP_data"
                  csvExport={true}
                  showFooter={true}
                  handleDeleteAction={handleDeleteButton}
                  tableInstance={tableInstance}
                  totalData={totalData}
                  setTableState={setTableState}
                  onExport={() => {
                    downloadLinksFile(exportBooksService());
                  }}
                />
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
    </PageContent>
  );
};

export default Rsvp;
