import type { AxiosResponse } from "axios";

export const downloadLinksFile = async (
  axiosResponse: Promise<AxiosResponse<void>>
) => {
  await axiosResponse
    .then((response) => {
      const filename = response.request
        .getResponseHeader("Content-Disposition")
        .split(";")[1]
        .split("=")[1];
      return [filename, response];
    })
    .then(([filename, response]) => {
      // Create blob link to download
      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute("download", filename);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
};
