import styled from "styled-components";
import { SCREEN_SIZES } from "@constants/StylingConstants";
import { Form } from "react-bootstrap";

export const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
`;

export const DownloadSection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;

  :first-of-type {
    @media screen and (min-width: ${SCREEN_SIZES.large}) {
      align-items: center;
    }
  }
`;

export const DownloadContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
`;

export const UploadSection = styled.div`
  display: flex;
  gap: 1rem;
`;

export const UploadContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const OrderCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  border: 3px solid #435b5b;
  border-radius: 50%;
  font-weight: bold;
`;

export const TextContent = styled.p`
  margin: 0;
`;

export const FileUploadContainer = styled(Form.Control)`
  max-width: 300px;
`;

export const DownloadLink = styled.a`
  color: #d3a57b;

  :hover {
    opacity: 0.8;
    color: #d3a57b;
  }
`;
