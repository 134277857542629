import PageContent from "@components/AdminLayout/PageContent";
import useViewModel from "./ViewModel";
import { renderContent } from "./RenderContent";

const ClientWebDetailPage = () => {
  const {
    data: { error, isDisabled, isLoading, webContent },
    method: { onSubmit },
  } = useViewModel();

  return (
    <PageContent>
      {renderContent({ error, isDisabled, isLoading, onSubmit, webContent })}
    </PageContent>
  );
};

export default ClientWebDetailPage;
