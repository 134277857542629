import styled, { keyframes } from "styled-components";
import { COLORS } from "@constants/StylingConstants";

export const Container = styled.div`
  height: 100%;
  width: 100%;
  transition: all 1s linear;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
`;

export const Line = styled.div`
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background-color: ${COLORS.primary};
  margin: 0 2px;
`;

export const loadingAnimation = keyframes`
    0{
      transform: translate(0, 0);
    }
    50% {
      transform: translate(0, 15px);
    }
    100% {
      transform: translate(0, 0);
    }
`;

export const Load = styled.div`
  ${Line}:nth-last-child(1) {
    animation: ${loadingAnimation} 0.6s 0.1s linear infinite;
  }
  ${Line}:nth-last-child(2) {
    animation: ${loadingAnimation} 0.6s 0.2s linear infinite;
  }
  ${Line}:nth-last-child(3) {
    animation: ${loadingAnimation} 0.6s 0.3s linear infinite;
  }
`;
