import FileUploadModalComponent from "@/shared/components/FileUpload";
import type { ChangeEvent } from "react";
import { useState } from "react";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import {
  uploadNewImageService,
  uploadUpdateImageService,
} from "@services/api/imageUpload";

export interface ImageData {
  optionId?: string;
  file_upload?: File;
  section_type?: string;
  order?: number;
  old_file_url?: string;
  sub_section?: string;
}

const useImageUploader = (
  imageData: ImageData,
  onCloseCallback: (errorMessage?: string) => void
): [() => JSX.Element, () => Promise<string>] => {
  const params = useParams<{ id: string }>();

  const newImageMutation = useMutation((data: ImageData) =>
    uploadNewImageService({ ...data, optionId: data.optionId ?? params?.id })
  );
  const updateImageMutation = useMutation((data: ImageData) =>
    uploadUpdateImageService({ ...data, optionId: data.optionId ?? params?.id })
  );

  const [promise, setPromise] = useState<{
    resolve: (value: string) => void;
  }>();

  const uploadImage = () =>
    new Promise<string>((resolve) => {
      setPromise({ resolve });
    });

  const handleClose = (errorMessage?: string) => {
    setPromise(undefined);

    if (onCloseCallback) {
      onCloseCallback(errorMessage);
    }
  };

  const [loading, setLoading] = useState(false);

  const handleSubmitPhoto = async (
    e?: ChangeEvent<HTMLInputElement>,
    file?: File
  ) => {
    setLoading(true);
    let data: ImageData = {
      ...imageData,
    };

    if (e) {
      data = {
        ...data,
        file_upload: e.target.files[0],
      };
    } else if (file) {
      data = {
        ...data,
        file_upload: file,
      };
    }

    await handleUpload(data);
  };

  const handleUpload = async (data: ImageData) => {
    try {
      if (data.old_file_url) {
        const uploadUpdateRes = await updateImageMutation.mutateAsync(data);

        promise.resolve(uploadUpdateRes as string);
      } else {
        const uploadCreateRes = await newImageMutation.mutateAsync(data);

        promise.resolve(uploadCreateRes as string);
      }

      handleClose();
    } catch (error) {
      console.error(error);
      handleClose(error as string);
    } finally {
      setLoading(false);
    }
  };

  const ImageUploadModal = () => (
    <FileUploadModalComponent
      promise={promise}
      handleClose={handleClose}
      handleSubmitFile={handleSubmitPhoto}
      isLoading={loading}
      fileType="IMAGE"
    />
  );

  return [ImageUploadModal, uploadImage];
};

export default useImageUploader;
