import { useState } from "react";
import { useAsyncDebounce } from "react-table";
import type { Props } from "./Types";

const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}: Props) => {
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);
  return (
    <div className="position-relative">
      <label className="search-label">
        <span className="sr-only">Search this table</span>
        <input
          value={value || ""}
          type="text"
          className="form-control"
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder="Search..."
        />
      </label>
      <i className="bx bx-search-alt search-icon" />
    </div>
  );
};

export default GlobalFilter;
