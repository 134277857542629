import React from "react";
import { Container } from "react-bootstrap";

interface Props {
  children: React.ReactNode;
}

const PageContent = (props: Props) => {
  const { children } = props;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          /> */}
          {children}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PageContent;
