import useCreateLink from "@/hooks/useCreateLink";
import type { ChangeEvent } from "react";
import type { UseMutationResult } from "react-query";

interface Return {
  data: {
    newLinkMutation: UseMutationResult;
  };
  method: {
    handleSubmitFile(e: ChangeEvent<HTMLInputElement>): Promise<void>;
    handleUpload(file: File): Promise<void>;
  };
}

export default function useViewModel(): Return {
  const newLinkMutation = useCreateLink();

  //TODO wrap with useCallback
  const handleSubmitFile = async (e: ChangeEvent<HTMLInputElement>) => {
    const file_upload: File = e.target.files[0];
    await handleUpload(file_upload);
  };

  //POST uploaded excel file to the API for generating new links
  //TODO wrap with useCallback
  const handleUpload = async (file: File) => {
    try {
      await newLinkMutation.mutateAsync(file);
    } catch (error) {
      console.error(error);
    }
  };

  return {
    data: {
      newLinkMutation,
    },
    method: {
      handleSubmitFile,
      handleUpload,
    },
  };
}
