import { ApiInstance } from ".";
import type { ResponseDetail } from "@services/swagger";

export const createLinksResult = async (
  fileUpload: File
): Promise<ResponseDetail> => {
  const fetchedData = await ApiInstance()
    .linksApi()
    .linksGenerateFile(fileUpload);
  return fetchedData.data;
};
