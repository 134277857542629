import styled from "styled-components";

export const CustomHeaders = styled.div`
  margin: 2px 0px;
  font-size: large;
  margin-bottom: 10px;
  font-weight: 600;
`;

export const CustomDashedHeaders = styled.div`
  margin: 2px 0px;
  font-size: large;
  margin-bottom: 10px;
  font-weight: 600;
  border-bottom: 1px solid #ced4da;
  padding-bottom: 4px;
  text-transform: capitalize;
`;

export const CustomSectionHeaders = styled.div`
  margin: 2px 0px;
  font-size: medium;
  margin-bottom: 10px;
  font-weight: 600;
`;
