import type {
  Bride,
  Client,
  ClientOption,
  ContentOpeningModalImageTypeEnum,
  Event,
  Font,
  FontTypeEnum,
  Gallery,
  Groom,
  HeroBackground,
  Option,
  OptionBrideGroomStyleEnum,
  OptionCountdownStyleEnum,
  OptionFooterStyleEnum,
  OptionGalleryStyleEnum,
  OptionGiftStyleEnum,
  OptionHealthProtocolStyleEnum,
  OptionHeroStyleEnum,
  OptionLanguageEnum,
  OptionOpeningModalStyleEnum,
  OptionOurStoryStyleEnum,
  OptionQuoteStyleEnum,
  OptionRsvpStyleEnum,
  OptionShareBannerStyleEnum,
  OptionWebTypeEnum,
  OptionWeddingEventStyleEnum,
  OptionWishesStyleEnum,
  Payment,
  Story,
} from "@services/swagger";
import { EventTypeEnum, OptionTemplateEnum } from "@services/swagger";
import * as yup from "yup";

const brideSchema = yup.object<Record<keyof Bride, yup.AnySchema>>({
  name: yup.string().required("Bride name is required"),
  full_name: yup.string().required("Bride full name is required"),
  prefix_name: yup.string().optional().nullable(),
  suffix_name: yup.string().optional().nullable(),
  photo_url: yup.string().optional().nullable(),
  child_order: yup.string().optional().nullable(),
  daughter_order: yup.string().optional().nullable(),
  father_name: yup.string().optional().nullable(),
  mother_name: yup.string().optional().nullable(),
  facebook: yup.string().optional().nullable(),
  instagram: yup.string().optional().nullable(),
  twitter: yup.string().optional().nullable(),
  image_css: yup.string().optional().nullable(),
  tiktok: yup.string().optional().nullable(),
});

const groomSchema = yup.object<Record<keyof Groom, yup.AnySchema>>({
  name: yup.string().required("Groom name is required"),
  full_name: yup.string().required("Groom full name is required"),
  prefix_name: yup.string().optional().nullable(),
  suffix_name: yup.string().optional().nullable(),
  photo_url: yup.string().optional().nullable(),
  child_order: yup.string().optional().nullable(),
  son_order: yup.string().optional().nullable(),
  father_name: yup.string().optional().nullable(),
  mother_name: yup.string().optional().nullable(),
  facebook: yup.string().optional().nullable(),
  instagram: yup.string().optional().nullable(),
  twitter: yup.string().optional().nullable(),
  image_css: yup.string().optional().nullable(),
  tiktok: yup.string().optional().nullable(),
});

const galleriesSchema = yup.array(
  yup.object<Record<keyof Gallery, yup.AnySchema>>({
    id: yup.number().optional(),
    title: yup.string().optional().nullable(),
    picture_url: yup
      .string()
      .required("Gallery picture URL is required")
      .nullable(),
    css: yup.string().optional().nullable(),
    section: yup.string().optional().nullable(),
    overlay_opacity: yup.string().optional().nullable(),
  })
);

const eventSchema = yup.array(
  yup.object<Record<keyof Event, yup.AnySchema>>({
    id: yup.number().optional(),
    title: yup.string().optional().nullable(),
    start_datetime: yup
      .date()
      .transform((curr, orig) => (orig === "" ? null : curr))
      .required("Event start date time is required")
      .nullable(),
    end_datetime: yup
      .date()
      .transform((curr, orig) => (orig === "" ? null : curr))
      .optional()
      .nullable(),
    location: yup.string().optional().nullable(),
    street: yup.string().optional().nullable(),
    watch_url: yup.string().optional().nullable(),
    maps_url: yup.string().optional().nullable(),
    type: yup
      .mixed<EventTypeEnum>()
      .default(EventTypeEnum.HolyMatrimony)
      .optional()
      .nullable(),
    session_type: yup.string().optional().nullable(),
    custom_time_text: yup.string().optional().nullable(),
    icon_url: yup.string().optional().nullable(),
    timezone: yup.string().optional().nullable(),
  })
);

const paymentSchema = yup.array(
  yup.object<Record<keyof Omit<Payment, "default_bank">, yup.AnySchema>>({
    id: yup.number().optional(),
    bank: yup.string().required("Bank is required").nullable(),
    account_number: yup.string().optional().nullable(),
    account_owner: yup.string().optional().nullable(),
    qr_code_image_url: yup.string().optional().nullable(),
    payment_url: yup.string().optional().nullable(),
    custom_button_text: yup.string().optional().nullable(),
  })
  // TODO: change with new Payment type
);

const storiesSchema = yup.array(
  yup.object<Record<keyof Story, yup.AnySchema>>({
    id: yup.number().optional(),
    order: yup
      .number()
      .optional()
      .nullable(true)
      .transform((_, val) => (Number(val) !== 0 ? Number(val) : null)),
    title: yup.string().optional().nullable(),
    description: yup.string().optional().nullable(),
    datetime: yup
      .date()
      .transform((curr, orig) => (orig === "" ? null : curr))
      .optional()
      .nullable(),
    location: yup.string().optional().nullable(),
    image_url: yup.string().optional().nullable(),
  })
);

const heroesBackgroundSchema = yup.array(
  yup.object<Record<keyof HeroBackground, yup.AnySchema>>({
    id: yup.number().optional(),
    order: yup
      .number()
      .optional()
      .nullable(true)
      .transform((_, val) => (Number(val) !== 0 ? Number(val) : null)),
    css: yup.string().optional().nullable(),
    picture_url: yup.string().required("Picture URL is required"),
    overlay_opacity: yup.string().optional().nullable(),
  })
);

const fontsSchema = yup.array(
  yup.object<Record<keyof Font, yup.AnySchema>>({
    id: yup.number().optional().nullable(),
    family: yup.string().optional().nullable(),
    css_url: yup.string().optional().nullable(),
    font_url: yup.string().optional().nullable(),
    style: yup.string().optional().nullable(),
    weight: yup.string().optional().nullable(),
    format: yup.string().optional().nullable(),
    unicode_range: yup.string().optional().nullable(),
    type: yup.mixed<FontTypeEnum>().optional().nullable(),
  })
);

const customTextSchema = yup.object().nullable();

const customCssSchema = yup
  .object({
    additionalProp1: yup.string().optional().nullable(),
    additionalProp2: yup.string().optional().nullable(),
    additionalProp3: yup.string().optional().nullable(),
  })
  .nullable();

const customConfigSchema = yup
  .object({
    additionalProp1: yup.string().optional().nullable(),
    additionalProp2: yup.string().optional().nullable(),
    additionalProp3: yup.string().optional().nullable(),
  })
  .nullable();

const optionSchema = yup.object<
  Record<keyof Omit<Option, "meta_title" | "meta_description">, yup.AnySchema>
>({
  slug: yup
    .string()
    .optional()
    .matches(/^[a-zA-Z0-9-]*$/, "Incorect Slug format.")
    .required("Slug is required"),
  client_id: yup.number().optional().nullable(),
  template: yup
    .mixed<OptionTemplateEnum>()
    .default(OptionTemplateEnum.Template1)
    .required(),
  is_custom_template: yup.bool().optional().nullable(),
  is_custom_domain: yup.bool().optional().nullable(),
  language: yup.mixed<OptionLanguageEnum>().optional().nullable(),
  font_url: yup.string().optional().nullable(),
  song_url: yup.string().optional().nullable(),
  opening_modal_style: yup
    .mixed<OptionOpeningModalStyleEnum>()
    .optional()
    .nullable(),
  hero_style: yup.mixed<OptionHeroStyleEnum>().optional().nullable(),
  countdown_style: yup.mixed<OptionCountdownStyleEnum>().optional().nullable(),
  quote_style: yup.mixed<OptionQuoteStyleEnum>().optional().nullable(),
  bride_groom_style: yup
    .mixed<OptionBrideGroomStyleEnum>()
    .optional()
    .nullable(),
  wedding_event_style: yup
    .mixed<OptionWeddingEventStyleEnum>()
    .optional()
    .nullable(),
  our_story_style: yup.mixed<OptionOurStoryStyleEnum>().optional().nullable(),
  gallery_style: yup.mixed<OptionGalleryStyleEnum>().optional().nullable(),
  wishes_style: yup.mixed<OptionWishesStyleEnum>().optional().nullable(),
  gift_style: yup.mixed<OptionGiftStyleEnum>().optional().nullable(),
  rsvp_style: yup.mixed<OptionRsvpStyleEnum>().optional().nullable(),
  share_banner_style: yup
    .mixed<OptionShareBannerStyleEnum>()
    .optional()
    .nullable(),
  health_protocol_style: yup
    .mixed<OptionHealthProtocolStyleEnum>()
    .optional()
    .nullable(),
  footer_style: yup.mixed<OptionFooterStyleEnum>().optional().nullable(),
  base_color: yup.string().optional().nullable(),
  slider: yup.bool().optional().nullable(),
  primary_color: yup.string().optional().nullable(),
  secondary_color: yup.string().optional().nullable(),
  third_color: yup.string().optional().nullable(),
  title_color: yup.string().optional().nullable(),
  web_type: yup.mixed<OptionWebTypeEnum>().optional().nullable(),
  announcement: yup.string().optional().nullable(),
  default_guest_total: yup
    .number()
    .optional()
    .default(0)
    .nullable(true)
    .transform((_, val) => (Number(val) !== 0 ? Number(val) : null)),
  animation: yup.bool().optional().nullable(),
  fonts: fontsSchema,
  custom_text: customTextSchema,
  custom_css: customCssSchema,
  custom_config: customConfigSchema,
  custom_image_url: yup.string().optional().nullable(),
  rsvp_config: yup.object().optional().nullable(),
  meta_image: yup.string().optional().nullable(true),
});

export const clientOptionSchema = yup.object<
  Record<keyof ClientOption, yup.AnySchema>
>({
  option: optionSchema,
});

export const clientSchema = yup.object<
  Partial<Record<keyof Client, yup.AnySchema>>
>({
  name: yup.string().optional().nullable(),
  content: yup.object().shape({
    bride: brideSchema,
    groom: groomSchema,
    galleries: galleriesSchema,
    events: eventSchema,
    payments: paymentSchema,
    stories: storiesSchema,
    hero_backgrounds: heroesBackgroundSchema,
    wedding_date: yup.date().required("Weedding date is required"),
    greeting: yup.string().optional().nullable(),
    quote: yup.string().optional().nullable(),
    quote_source: yup.string().optional().nullable(),
    hashtag_word: yup.string().optional().nullable(),
    reminder_url: yup.string().optional().nullable(),
    video_prewed_url: yup.string().optional().nullable(),
    opening_modal_image: yup.string().optional().nullable(),
    opening_modal_image_css: yup.string().optional().nullable(),
    opening_modal_image_type: yup
      .mixed<ContentOpeningModalImageTypeEnum>()
      .optional()
      .nullable(),
    galleries_css: yup.string().optional().nullable(),
    galleries_mobile_css: yup.string().optional().nullable(),
  }),
  option: optionSchema,
});
