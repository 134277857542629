import React from "react";
import { Link, withRouter } from "react-router-dom";
import { ReactComponent as LogoKoendang } from "@assets/images/logo-koendang.svg";
import { ReactComponent as LogoKoendangSmall } from "@assets/images/logo-koendang-small.svg";
import SidebarContent from "@shared/components/SidebarContent";

const Sidebar = () => {
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <LogoKoendangSmall className="logo-koendang-sm" />
            </span>
            <span className="logo-lg">
              <LogoKoendang className="logo-koendang-lg" />
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          <SidebarContent />
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Sidebar);
