import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as LogoKoendangSmall } from "@assets/images/logo-koendang-small.svg";
// Import menuDropdown
import ProfileMenu from "@shared/components/ProfileMenu";

const Header = () => {
  function sidebarToggle() {
    const body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <LogoKoendangSmall className="logo-koendang-sm logo-koendang-dark" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                sidebarToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>
          <div className="d-flex">
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
